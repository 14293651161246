import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from 'src/brand/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import {SharedModule} from './modules/shared/shared.module';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { ColorSketchModule } from 'ngx-color/sketch';
import localeDe from '@angular/common/locales/de';
import {registerLocaleData} from '@angular/common';
import {IonicStorageModule} from '@ionic/storage-angular';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';


registerLocaleData(localeDe);

export function momentAdapterFactory() {
    return adapterFactory(moment);
}

@NgModule({
    declarations: [AppComponent, ForbiddenComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ColorSketchModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireStorageModule,
        IonicStorageModule.forRoot(),
        SharedModule,
        BrowserAnimationsModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        // { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },
        // { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:8081'] : undefined },
        // { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:5001'] : undefined },
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
