import {Injectable} from '@angular/core';
import {FirestoreProxyService, Query} from './firestore-proxy.service';
import {UserService} from './user.service';
import {filter, Observable, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {Feed} from '../models/feed';
import {dbPaths, Globals} from './globals';
import {Message} from '../models/message';
import {UtilService} from './util.service';
import {ArbeitsgruppenService} from './arbeitsgruppen.service';
import * as moment from 'moment';
import {AlertController} from '@ionic/angular';
import {VisitService} from './visit.service';
import {FirestoreProxyCacheService} from "./firestore-proxy-cache.service";

@Injectable({
    providedIn: 'root'
})
export class FeedService {

    constructor(
        private firestore: FirestoreProxyService,
        private firestoreProxyCacheService: FirestoreProxyCacheService,
        private userService: UserService,
        private arbeitsgruppenService: ArbeitsgruppenService,
        private alertController: AlertController,
        private visitService: VisitService
    ) {
    }

    getFeed<T>(path: string, id: string): Observable<T> {
        const result = this.firestore.getDocumentConnectedObservable<T>(path, id)
            .pipe(
                switchMap(feed => {
                    let f: Feed;
                    f = feed as unknown as Feed;
                    if(!f){
                        return null;
                    }
                    return this.arbeitsgruppenService.getFilteredArbeitsgruppenAsObservable().pipe(
                        map(arbeitsgruppen => {
                            if (f.arbeitsgruppe != null && arbeitsgruppen != null) {
                                f.name = arbeitsgruppen.find(ag => ag.id === f.arbeitsgruppe)?.name;
                            }
                            return feed;
                        })
                    );
                })
            );

        this.visitService.setVisitForCurrentUser(path, id);
        return result;
    }

    getCurrentUserArbeitsgruppenFeed<T>(path: dbPaths, where?: Query[]): Observable<T[]> {
        return this.arbeitsgruppenService.currentUserArbeitsgruppen.pipe(
            filter(arbeitsgruppen => !!arbeitsgruppen && arbeitsgruppen.length > 0),
            switchMap(arbeitsgruppen => {
                // Filter arbeitsgruppen based on path
                let filteredArbeitsgruppen = [];
                
                switch (path) {
                    case dbPaths.chatsPath:
                        filteredArbeitsgruppen = arbeitsgruppen.filter(ag => ag.feeds.chats === true);
                        break;
                    case dbPaths.documentsPath:
                        filteredArbeitsgruppen = arbeitsgruppen.filter(ag => ag.feeds.documents === true);
                        break;
                    case dbPaths.surveysPath:
                        filteredArbeitsgruppen = arbeitsgruppen.filter(ag => ag.feeds.surveys === true);
                        break;
                    case dbPaths.newsPath:
                        filteredArbeitsgruppen = arbeitsgruppen.filter(ag => ag.feeds.news === true);
                        break;
                    default:
                        filteredArbeitsgruppen = arbeitsgruppen; // Achtung: Wenn das path zu ag.feed mapping unbekannt ist, werden alle arbeitsgruppen verwendet.
                }

                // Leere Ergebnisse frühzeitig abfangen
                if (filteredArbeitsgruppen.length === 0) {
                    return new Observable<T[]>(subscriber => subscriber.next([]));
                }
                
                // Get arbeitsgruppen IDs for querying
                const arbeitsgruppenIds = filteredArbeitsgruppen.map(ag => ag.id);

                // Create a new array for the where conditions to avoid modifying the original
                const whereConditions = [...(where || [])];
                whereConditions.push({fieldPath: 'isArbeitsgruppenFeed', filterOperator: '==', value: true});
                whereConditions.push({fieldPath: 'arbeitsgruppe', filterOperator: 'in', value: arbeitsgruppenIds});
                
                // Call getFeeds with the where clause
                return this.getFeeds<T>(path, whereConditions);
            })
        );
    }

    getFeeds<T>(path: string, where?: Query[]): Observable<T[]> {
        where = where || [];

        where.push({fieldPath: 'hidden', filterOperator: '==', value: false});

        // Falls wir mal leere Gruppen filtern wollen
        // if (!showEmpty) {
        //     // where.push(
        //     //     // {fieldPath: 'time', filterOperator: '>', value: UtilService.getEpoch() - 60 * 60 * 24 * 30} // not older than 30 days
        //     //     // {fieldPath: 'time', filterOperator: '>', value: UtilService.getEpoch() - 60 * 60 * 24 * 365} // not older than 365 days
        //     //     // {fieldPath: 'hasMessages', filterOperator: '==', value: true}, // immer alle Feeds anzeigen
        //     // );
        // }

        return this.firestoreProxyCacheService.query<T>(
            path,
            where,
            [
                // {fieldPath: 'hidden', directionStr: 'desc'},
                {fieldPath: 'deleted', directionStr: 'desc'},
                {fieldPath: 'arbeitsgruppe', directionStr: 'asc'},
                {fieldPath: 'time', directionStr: 'desc'}
                ]
        ).pipe(
            switchMap(feeds => {
                return this.arbeitsgruppenService.getFilteredArbeitsgruppenAsObservable().pipe(
                    map(arbeitsgruppen => {

                        feeds.forEach( async f => {
                            const feed: Feed = f as unknown as Feed;
                            if (feed.arbeitsgruppe != null && arbeitsgruppen != null) {
                                const matchedAg = arbeitsgruppen.find(ag => ag.id === feed.arbeitsgruppe);
                                if (matchedAg) {
                                    feed.name = matchedAg.name;
                                    feed.order = matchedAg.order;
                                    feed.arbeitsgruppeItem = matchedAg;
                                }
                            }
                            feed.viewLocalTimeStampHasUnreadMessages = this.visitService
                                .hasCurrentUserVisitSinceObservable(path, feed.docId, feed.changed)
                                .pipe(map(hasVisit => !hasVisit && feed.hasMessages));
                        });

                        feeds = feeds.sort((a, b) => {
                            if ((a as Feed).name < (b as Feed).name) {
                                return -1;
                            }
                            if ((a as Feed).name > (b as Feed).name) {
                                return 1;
                            }
                            return 0;
                        });

                        feeds = feeds.sort((a, b) => {
                            return ((a as Feed).order ?? 0) - ((b as Feed).order ?? 0);
                        });

                        return feeds;
                    })
                );
            })
        );
    }

    getMessages<T>(
        path: string,
        feedId: string,
        where: Query[],
        showDeleted: boolean,
        orderAsc: boolean,
        published: boolean): Observable<T[]> {
        where = where || [];

        if (!showDeleted) {
            where.push(
                {fieldPath: 'deleted', filterOperator: '!=', value: true}
            );
        }
        if (published !== undefined) {
            where.push(
                {fieldPath: 'published', filterOperator: '==', value: published}
            );
        }
        const fullPath = path + '/' + feedId + '/' + Globals.messagesCollectionName;
        return this.firestoreProxyCacheService.query<T>(
            fullPath,
            where,
            [
                {fieldPath: 'deleted', directionStr: 'asc'},
                {fieldPath: 'time', directionStr: orderAsc ? 'asc' : 'desc'}
            ]
        ).pipe(map(result => {
            result.forEach(async m => {
                const message: Message = m as unknown as Message;
                message.viewLocalTimeStampHasUnreadMessages = this.visitService
                    .hasCurrentUserVisitSinceObservable(fullPath, message.docId, message.changed)
                    .pipe(map(hasVisit => !hasVisit));
            });
            return result;
        }));
    }

    getMessagesStateChange<T>(
        path: string,
        feedId: string,
        where?: Query[],
        showDeleted = false,
        orderAsc = false,
        showHidden = false): Observable<T[]> {

        where = where || [];

        if (!showDeleted) {
            where.push(
                {fieldPath: 'deleted', filterOperator: '!=', value: true}
            );
        }

        return this.firestoreProxyCacheService.query(
            path + '/' + feedId + '/' + Globals.messagesCollectionName,
            where,
            [
                {fieldPath: 'deleted', directionStr: 'asc'},
                {fieldPath: 'time', directionStr: orderAsc ? 'asc' : 'desc'}],
            );
    }

    async createOrUpdateFeed(path: dbPaths, feed: Feed): Promise<string> {
        feed.changed = UtilService.getCurrentUtcUnixTimestampAsNumber();

        if (feed.docId != null) {
            return await this.firestore.updateDocument(path, feed.docId, feed);
        } else {
            feed.time = UtilService.getCurrentUtcUnixTimestampAsNumber();
            feed.owner = this.userService.getCurrentUserUid();
            const createdFeed = await this.firestore.addDocument(path, feed);
            if (!createdFeed || !createdFeed.docId ) {
                console.error('Error creating feed');
                return '';
            }
            return createdFeed.docId;
        }
    }

    async deleteFeed(path: string, feed: Feed) {
        const alert = await this.alertController.create({
            header: 'Löschen',
            message: 'Wollen Sie wirklich löschen?',
            buttons: [{
                text: 'Nein'
            },
            {
                text: 'Ja',
                handler: async () => {
                    await this.firestore.deleteDocument(path, feed.docId);
                }
            }]
        });
        await alert.present();
    }

    async createOrUpdateMessage<T extends Message>(path: dbPaths, feedId: string, message: T, originalMessage: T | null = null): Promise<T>  {
        const hasChanges = originalMessage ? await this.checkIfMessageHasChangesAndAskUserToSaveIfNot(message, originalMessage) : true;
        if (!hasChanges) {
            return message;
        }

        await this.firestore.mergeDocument(feedId, path, {
            hasMessages: true,
            changed: UtilService.getCurrentUtcUnixTimestampAsNumber()
        });
        if (message.createdBy == null){
            message.createdBy = this.userService.getCurrentUserUid();
        }
        if (message.time == null){
            message.time = UtilService.getCurrentUtcUnixTimestampAsNumber();
        }

        message.changed = UtilService.getCurrentUtcUnixTimestampAsNumber();

        if (message.docId != null) {
            await this.firestore.setDocument(path + '/' + feedId + '/' + Globals.messagesCollectionName, message.docId, message);
            return message;
        } else {
            message.time = moment().unix();
            message.createdBy = this.userService.getCurrentUserUid();
            return await this.firestore.addDocument<T>(path + '/' + feedId + '/' + Globals.messagesCollectionName, message);
        }
    }

    async deleteMessage(path: string, feedId: string, message: Message) {
        await this.firestore.deleteDocument(path + '/' + feedId + '/' + Globals.messagesCollectionName, message.docId);
    }

    async createNew(path: string, feed?: Feed): Promise<string> {
        const p = path;

        if (feed === undefined) {
            const createdFeed = await this.firestore.addDocument<Feed>(p, {
                isArbeitsgruppenFeed: feed?.isArbeitsgruppenFeed,
                time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
                owner: this.userService.getCurrentUserUid(),
                hidden: false,
                deleted: false,
                hasMessages: false,
                changed: UtilService.getCurrentUtcUnixTimestampAsNumber(),
                name: feed?.name,
                arbeitsgruppe: feed?.arbeitsgruppe
            });
            return createdFeed.docId;
        } else {
            feed.time = UtilService.getCurrentUtcUnixTimestampAsNumber();
            // feed.isArbeitsgruppenFeed = false;
            const createdFeed = await this.firestore.addDocument<Feed>(p, feed);
            return createdFeed.docId;
        }
    }

    getMessage<T>(path: string, feedId: string, docId: string): Observable<T> {
        const fullPath = path + '/' + feedId + '/' + Globals.messagesCollectionName;
        const result = this.firestoreProxyCacheService.getDocumentObservable<T>(fullPath, docId);
        this.visitService.setVisitForCurrentUser(fullPath, docId);
        return result;
    }

    getNewDocId(path: string, feedId?: string) {
        return this.firestore.getNewDocId();
    }

    getGroupIconName(feed: Feed){
        const ag = this.arbeitsgruppenService.arbeitsgruppen.getValue().list.find(ag => ag.id === feed.arbeitsgruppe);
        if(ag && ag.isUserAssignable === false){
            return 'apps';
        }
        return 'albums-outline';
    }

    public async checkIfMessageHasChangesAndAskUserToSaveIfNot(message: Message, originalMessage: Message): Promise<boolean> {
        const  hasChanges = UtilService.hasMessageChanged(message, originalMessage);

            if (hasChanges) {
                return true;

            } else {
                const alert = await this.alertController.create({
                    header: 'Speichern',
                    message: 'Sie haben keine Änderungen vorgenommen. Wollen Sie trotzdem speichern? Beachten Sie, dass ggf. Benachrichtigungen verschickt werden.',
                    buttons: [{
                        text: 'nein',
                        role: 'cancel',
                        handler: () => {
                            return false;
                        }
                    }, {
                        text: 'Ja',
                        role: 'confirm',
                        handler: () => {
                            return true;
                        }
                    }]
                });
                await alert.present();
            }

        }
}
