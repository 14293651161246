import { Injectable } from '@angular/core';
import { environment } from '../../brand/environments/environment';

export enum LogLevel {
    VERBOSE = 'VERBOSE',   // Logs everything including data
    DEBUG = 'DEBUG',       // Logs messages without data
    PRODUCTION = 'PRODUCTION' // Logs only errors
}

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    private logLevel: LogLevel;

    public static Log(
        context: any | string,
        message: string,
        data?: any,
        logLevel = LogLevel.DEBUG){
        const logger = new LoggingService();
        logger.logLevel = logLevel;
        logger.log(context, message, data);
    }

    constructor() {

        const isVerbose = environment.production === false;
        
        if (isVerbose) {
            this.logLevel = LogLevel.VERBOSE;
        } else {
            this.logLevel = environment.production ? LogLevel.PRODUCTION : LogLevel.DEBUG;
        }
    }

    setLogLevel(level: LogLevel) {
        this.log(this, `Changing log level to: ${level}`);
        this.logLevel = level;
    }

    private getPrefix(context: any | string): string {
        if (typeof context === 'string') {
            return `[${context}]`;
        }
        const className = context.constructor.name;
        return `[${className}]`;
    }

    private getErrorPrefix(context: any | string): string {
        if (typeof context === 'string') {
            return `[${context} ERROR]`;
        }
        const className = context.constructor.name;
        return `[${className} ERROR]`;
    }

    logMethodStart(context: any | string, message: string, data?: any) {
        if (this.logLevel === LogLevel.VERBOSE) {
            const prefix = this.getPrefix(context);
            if (data) {
                console.log(`${prefix} Starting: ${message}`, data);
            } else {
                console.log(`${prefix} Starting: ${message}`);
            }
        }
    }

    log(context: any | string, message: string, data?: any) {
        const prefix = this.getPrefix(context);
        
        switch (this.logLevel) {
            case LogLevel.VERBOSE:
                if (data) {
                    console.log(`${prefix} ${message}`, data);
                } else {
                    console.log(`${prefix} ${message}`);
                }
                break;
            case LogLevel.DEBUG:
                console.log(`${prefix} ${message}`);
                break;
            case LogLevel.PRODUCTION:
                // Don't log in production mode (except errors)
                break;
        }
    }

    logVerbose(context: any | string, message: string, data?: any) {
        if( this.logLevel !== LogLevel.VERBOSE){
            return;
        }
        const prefix = this.getPrefix(context);
        if (data) {
            console.log(`${prefix} ${message}`, data);
        } else {
            console.log(`${prefix} ${message}`);
        }
    }

    logError(context: any | string, message: string, error?: any) {
        const prefix = this.getErrorPrefix(context);
        console.error(`${prefix} ${message}`, error);
    }

    logWarning(context: any | string, message: string, data?: any) {
        const prefix = this.getPrefix(context);
        console.warn(`${prefix} WARNING: ${message}`, data ?? '');
    }
}
