import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AlertController, IonToast, LoadingController, Platform, ToastController} from '@ionic/angular';
import {NavigationService} from './navigation.service';
import firebase from 'firebase/compat';
import {BehaviorSubject, firstValueFrom, Observable, Subscription} from 'rxjs';
import {User} from '../models/user';
import {App} from '@capacitor/app';
// @ts-ignore -> import existiert wird aber nicht registriert
import moment from 'moment';
import {LocalCacheService} from './local-cache.service';
import {LoggingService} from "./logging.service";

@Injectable({
    providedIn: 'root'
})


export class AuthService {
    isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    currentFirebaseUser$: BehaviorSubject<firebase.User> = new BehaviorSubject<firebase.User>(null);
    public isInitialized = false;
    private isInitInProgress: boolean = false;
    private initPromise: Promise<void> | null = null;
    private subscription: Subscription = new Subscription();
    private userSubscription: Subscription = new Subscription();

    constructor(
        private angularFireAuth: AngularFireAuth,
        private nav: NavigationService,
        private loggingService: LoggingService,
        private toast: ToastController,
        private alertController: AlertController,
    ) {

        this.isInitInProgress = false;
        this.isInitialized = false;
    }

    async Init(): Promise<void> {
        this.loggingService.logMethodStart(this, 'Init - this.isInitialized:', this.isInitialized);

        if (this.isInitialized) {
            return Promise.resolve();
        }

        if(!this.isInitInProgress){
            this.isInitInProgress = true;
            this.initPromise = new Promise(async (resolve, reject) => {
                try {
                    this.loggingService.logMethodStart(this, 'AuthService.Init Started');

                    this.subscription.add(this.angularFireAuth.authState.subscribe(async user => {
                        this.loggingService.logMethodStart(this, 'Init+authState - User LoggendIn or Logged Out', user);
                        if (user && user.uid /*&& user.emailVerified*/ && user.email && user.email !== '' && user.email !== null && user.email !== undefined) {

                            // Wenn der User sich anmeldet oder registriert, dann ist er authentifiziert.
                            // emailVerified und accountFreigegeben werden als Autorisierung im userService ausgewertet.

                            this.loggingService.logVerbose(this, 'Changing currentFirebaseUser$', user);
                            this.currentFirebaseUser$.next(user);

                                this.loggingService.logVerbose(this, 'Changing isLoggedIn$', true);
                                if(this.isLoggedIn$.getValue() !== true) {
                                    this.isLoggedIn$.next(true);
                                }

                            this.isInitialized = true;
                            console.log('AuthService.Init isLoggedIn:', this.isLoggedIn$.getValue());
                            resolve();
                        } else {
                            await this.handleAuthUserReturnedNull(user);
                            this.isInitialized = true;
                            console.log('AuthService.Init Finish', this.isLoggedIn$.getValue());
                            resolve();
                        }
                    }));
                } catch (error) {
                    console.log('AuthService.Init Error', error);
                    reject(error);
                } finally {
                    this.isInitInProgress = false;
                    this.initPromise = null;
                }
            });

        }else {
            if (!this.initPromise) {
                this.initPromise = new Promise((resolve, reject) => {
                });
            }
            return this.initPromise;
        }

        return this.initPromise;
    }

    async login(email: string, password: string) {
        try {
            return await this.angularFireAuth.signInWithEmailAndPassword(email, password);
        } catch (error: any) {
            this.loggingService.logError(this, 'login', error);
            if (error) {
                await this.handleLoginFailure(error);
            }
            await this.signOut();
            return Promise.reject();
        }
    }

    async signOut() {
        this.loggingService.logMethodStart(this, 'signOut');
        this.isLoggedIn$.next(false);
        return this.angularFireAuth.signOut();
    }

    private async handleLoginFailure(error: any) {
        this.loggingService.logMethodStart(this, error.message);
        if (error.message === 'tenant not found') {
            const toast = await this.toast.create({
                header: 'Login',
                message: 'Anmeldung fehlgeschlagen. Informationen unvollständig.',
                duration: 5000,
                color: 'danger',
                position: 'top',
                positionAnchor: 'header'
            });
            await toast.present();
        } else if (error.code === 'auth/invalid-credential') {
            const toast = await this.toast.create({
                header: 'Login',
                message: 'E-Mailadresse oder Passwort falsch.',
                duration: 5000,
                color: 'danger',
                position: 'top',
                positionAnchor: 'header'
            });
            await toast.present();
        } else if (error.code === 'auth/too-many-requests') {
            const toast = await this.toast.create({
                header: 'Login',
                message: 'Zu viele Anmeldeversuche. Bitte versuchen Sie es später erneut',
                duration: 5000,
                color: 'danger',
                position: 'top',
                positionAnchor: 'header'
            });
            await toast.present();
        } else if (error.code === 'auth/user-disabled') {
            const toast = await this.toast.create({
                header: 'Login',
                message: 'Ihr Benutzerkonto wurde deaktiviert. Bitte wenden Sie sich an die Redaktion.',
                duration: 5000,
                color: 'danger',
                position: 'top',
                positionAnchor: 'header'
            });
            await toast.present();
        } else if (error.code === 'auth/missing-password') {
            const toast = await this.toast.create({
                header: 'Login',
                message: 'Bitte geben Sie ein Passwort ein.',
                duration: 5000,
                color: 'danger',
                position: 'top',
                positionAnchor: 'header'
            });
            await toast.present();
        } else {
            console.log('error', error.message);
            const toast = await this.toast.create({
                header: 'Login',
                message: 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es später erneut.',
                duration: 5000,
                color: 'danger',
                position: 'top',
                positionAnchor: 'header'
            });
            await toast.present();
        }
    }

    private async handleAuthUserReturnedNull(currentFirebaseUser: firebase.User) {
        this.loggingService.logMethodStart(this, 'handleAuthUserReturnedNull', currentFirebaseUser);
        if((currentFirebaseUser === null || currentFirebaseUser === undefined) || currentFirebaseUser.isAnonymous){
            this.loggingService.logVerbose(this, 'Changing currentFirebaseUser$', null);
            if(this.currentFirebaseUser$.getValue() != null) {
                this.currentFirebaseUser$.next(null);
            }
            //this.userService.currentUser$.next(null);
            //this.tenantService.clearTenant();
            if (this.isLoggedIn$.getValue() !== false) {
                this.isLoggedIn$.next(false);
            }
        }
    }



    isLoggedIn(): Observable<boolean> {
        return this.isLoggedIn$;
    }

    async createNewUser(email: string, password: string, displayName: string){
        this.loggingService.logMethodStart(this, 'createNewUser', email)
        try{
            const userCredential = await this.angularFireAuth.createUserWithEmailAndPassword(email, password);
            await userCredential.user.updateProfile({
                displayName: displayName,
                photoURL: null
            });
            await this.sendVerificationEmail(userCredential);
            return userCredential;
        }catch(error) {
            if (error.code) {
                this.loggingService.log(this, error.message);
                if (error.code === 'auth/email-already-in-use') {
                    // const succeeded = await this.authUserAlreadyInUse_andIsTryingToRegister_tryToLoginInsteadAndSwitchTenant(email, passwort, tenantId);
                    // if(!succeeded) {
                    const toast = await this.toast.create({
                        header: 'Fehler',
                        message: 'E-Mailadresse ist bereits registriert.',
                        duration: 5000,
                        color: 'danger',
                        position: 'top',
                        positionAnchor: 'header'
                    });
                    await toast.present();
                    // }
                } else if (error.code === 'auth/invalid-email') {
                    const toast = await this.toast.create({
                        header: 'Fehler',
                        message: 'E-Mailadresse ist nicht gültig.',
                        duration: 5000,
                        color: 'danger',
                        position: 'top',
                        positionAnchor: 'header'
                    });
                    await toast.present();
                } else if (error.code === 'auth/operation-not-allowed') {
                    const toast = await this.toast.create({
                        header: 'Fehler',
                        message: 'Registrierung zur Zeit nicht möglich. Bitte versuchen Sie es später erneut.',
                        duration: 5000,
                        color: 'danger',
                        position: 'top',
                        positionAnchor: 'header'
                    });
                    await toast.present();
                } else if (error.code === 'auth/weak-password') {
                    const toast = await this.toast.create({
                        header: 'Fehler',
                        message: 'Ihr Passwort ist zu schwach. Bitte wählen Sie ein Passwort mit mindestens 6 Zeichen.',
                        duration: 5000,
                        color: 'danger',
                        position: 'top',
                        positionAnchor: 'header'
                    });
                    await toast.present();
                } else {
                    const toast = await this.toast.create({
                        header: 'Fehler',
                        message: 'Registrierung fehlgeschlagen. Bitte versuchen Sie es später erneut.',
                        duration: 5000,
                        color: 'danger',
                        position: 'top',
                        positionAnchor: 'header'
                    });
                    await toast.present();
                }
            }
            return null;
        }
    }
    /*_registerMember(
        firma: string,
        anrede: string,
        titel: string,
        vorname: string,
        nachname: string,
        email: string,
        passwort: string,
        tenantId: string) {
        this.angularFireAuth.createUserWithEmailAndPassword(email, passwort)
            .then(async (userCredential) => {
                await this.sendVerificationEmail(userCredential);
                await this.createClientDocument(userCredential, firma, anrede, titel, vorname, nachname, email, tenantId, false);
                return userCredential;
            })
            .then(() => this.nav.navigateToStart())
            .catch(async (error: any) => {
                if (error.code) {
                    console.error(error.message);
                    if (error.code === 'auth/email-already-in-use') {
                        const succeeded = await this.authUserAlreadyInUse_andIsTryingToRegister_tryToLoginInsteadAndSwitchTenant(email, passwort, tenantId);
                        if(!succeeded) {
                            const toast = await this.toast.create({
                                header: 'Fehler',
                                message: 'E-Mailadresse ist bereits registriert.',
                                duration: 5000,
                                color: 'danger',
                                position: 'top',
                                positionAnchor: 'header'
                            });
                            await toast.present();
                        }
                    } else if (error.code === 'auth/invalid-email') {
                        const toast = await this.toast.create({
                            header: 'Fehler',
                            message: 'E-Mailadresse ist nicht gültig.',
                            duration: 5000,
                            color: 'danger',
                            position: 'top',
                            positionAnchor: 'header'
                        });
                        await toast.present();
                    } else if (error.code === 'auth/operation-not-allowed') {
                        const toast = await this.toast.create({
                            header: 'Fehler',
                            message: 'Registrierung zur Zeit nicht möglich. Bitte versuchen Sie es später erneut.',
                            duration: 5000,
                            color: 'danger',
                            position: 'top',
                            positionAnchor: 'header'
                        });
                        await toast.present();
                    } else if (error.code === 'auth/weak-password') {
                        const toast = await this.toast.create({
                            header: 'Fehler',
                            message: 'Ihr Passwort ist zu schwach. Bitte wählen Sie ein Passwort mit mindestens 6 Zeichen.',
                            duration: 5000,
                            color: 'danger',
                            position: 'top',
                            positionAnchor: 'header'
                        });
                        await toast.present();
                    } else {
                        const toast = await this.toast.create({
                            header: 'Fehler',
                            message: 'Registrierung fehlgeschlagen. Bitte versuchen Sie es später erneut.',
                            duration: 5000,
                            color: 'danger',
                            position: 'top',
                            positionAnchor: 'header'
                        });
                        await toast.present();
                    }
                }
            });
    }

    registerOwner(
        firma: string,
        anrede: string,
        titel: string,
        vorname: string,
        nachname: string,
        email: string,
        passwort: string,
        clientName: string,
        primaryColor: string,
        secondaryColor: string) {

        // this.angularFireAuth.createUserWithEmailAndPassword(email, passwort)
        //     .then(async (userCredential) => {
        //         await this.sendVerificationEmail(userCredential);
        //         const tenantId = await this.tenantService.createTenant(clientName, primaryColor, secondaryColor);
        //         await this.createClientDocument(userCredential, firma, anrede, titel, vorname, nachname, email, tenantId, true);
        //         await this.tenantService.useTenant(tenantId);
        //         await this.angularFireAuth.signInWithEmailAndPassword(email, passwort);
        //         return userCredential;
        //     })
        //     .then(() => this.nav.navigateToStart())
        //     .catch(async (error: any) => {
        //         if (error.code) {
        //             console.error(error.message);
        //             if (error.code === 'auth/email-already-in-use') {
        //                 const toast = await this.toast.create({
        //                     header: 'Fehler',
        //                     message: 'E-Mailadresse ist bereits registriert.',
        //                     duration: 5000,
        //                     color: 'danger',
        //                     position: 'top',
        //                     positionAnchor: 'header'
        //                 });
        //                 await toast.present();
        //             } else if (error.code === 'auth/invalid-email') {
        //                 const toast = await this.toast.create({
        //                     header: 'Fehler',
        //                     message: 'E-Mailadresse ist nicht gültig.',
        //                     duration: 5000,
        //                     color: 'danger',
        //                     position: 'top',
        //                     positionAnchor: 'header'
        //                 });
        //                 await toast.present();
        //             } else if (error.code === 'auth/operation-not-allowed') {
        //                 const toast = await this.toast.create({
        //                     header: 'Fehler',
        //                     message: 'Registrierung zur Zeit nicht möglich. Bitte versuchen Sie es später erneut.',
        //                     duration: 5000,
        //                     color: 'danger',
        //                     position: 'top',
        //                     positionAnchor: 'header'
        //                 });
        //                 await toast.present();
        //             } else if (error.code === 'auth/weak-password') {
        //                 const toast = await this.toast.create({
        //                     header: 'Fehler',
        //                     message: 'Ihr Passwort ist zu schwach. Bitte wählen Sie ein Passwort mit mindestens 6 Zeichen.',
        //                     duration: 5000,
        //                     color: 'danger',
        //                     position: 'top',
        //                     positionAnchor: 'header'
        //                 });
        //                 await toast.present();
        //             } else {
        //                 const toast = await this.toast.create({
        //                     header: 'Fehler',
        //                     message: 'Registrierung fehlgeschlagen. Bitte versuchen Sie es später erneut.',
        //                     duration: 5000,
        //                     color: 'danger',
        //                     position: 'top',
        //                     positionAnchor: 'header'
        //                 });
        //                 await toast.present();
        //             }
        //         }
        //     });
    }


    async completeSignUp(email: string, vorname: string, nachname: string, password: string, tenantId: string): Promise<void> {
        console.log('tenantId: ', tenantId);
        const user = await this.angularFireAuth.signInWithEmailLink(email, window.location.href);
        if (user) {
            await user.user.updatePassword(password);
            await this.createClientDocument(user, '', '', '', vorname, nachname, email, tenantId, false);
            await this.nav.navigateToLogin();
        }
    }
*/
    async passwordRecover(passwordResetEmail: string) {
        return this.angularFireAuth.sendPasswordResetEmail(passwordResetEmail)
            .then(async () => {
                const alert = await this.alertController.create({
                    header: 'Passwort zurücksetzen',
                    message: 'Wir haben Ihnen eine E-Mail mit einem Link zum Ändern Ihres Passwortes gesendet.',
                    buttons: ['OK']
                });
                await alert.present();

            }).catch(async (error: any) => {
                if (error.code) {
                    console.log(error.message);
                    if (error.code === 'auth/invalid-email') {
                        const toast = await this.toast.create({
                            header: 'Fehler',
                            message: 'E-Mailadresse ist nicht gültig.',
                            duration: 5000,
                            color: 'danger',
                            position: 'top',
                            positionAnchor: 'header'
                        });
                        await toast.present();
                    } else if (error.code === 'auth/user-not-found') {
                        const toast = await this.toast.create({
                            header: 'Fehler',
                            message: 'Benutzter mit dieser E-Mailadresse nicht gefunden.',
                            duration: 5000,
                            color: 'danger',
                            position: 'top',
                            positionAnchor: 'header'
                        });
                        await toast.present();
                    } else {
                        const toast = await this.toast.create({
                            header: 'Fehler',
                            message: 'Passwort zurücksetzen fehlgeschlagen. Bitte versuchen Sie es später erneut.',
                            duration: 5000,
                            color: 'danger',
                            position: 'top',
                            positionAnchor: 'header'
                        });
                        await toast.present();
                    }
                }
            });
    }

    private async sendVerificationEmail(userCredential: firebase.auth.UserCredential) {
        if (userCredential && userCredential.user.emailVerified === false) {
            await userCredential.user.sendEmailVerification()
                .then(async () => {
                    const alert = await this.alertController.create({
                        header: 'Registrierung',
                        message: 'Wir haben Ihnen soeben eine E-Mail mit einem Link zum Bestätigen Ihrer E-Mailadresse geschickt. Bitte bestätigen Sie ihre E-Mailadresse.',
                        buttons: ['OK']
                    });
                    await alert.present();
                });
        }
    }



    private async authUserAlreadyInUse_andIsTryingToRegister_tryToLoginInsteadAndSwitchTenant(email: string, passwort: string, tenantId: string): Promise<boolean> {
        try {
            // await this.login(email, passwort);
            // const userCredential = await this.angularFireAuth.signInWithEmailAndPassword(email, passwort);
            throw new Error('not implemented! AR : TODO: User Registrierung unter anderem Tenant');

        }catch (e) {
            console.log('authUserAlreadyInUse_andIsTryingToRegister_tryToLoginInsteadAndSwitchTenant', e);
            return false;
        }

    }
//TODO:refactor
    /*private async authUserAlreadyInUse_andLoggedIn_andTenantDoesNotMatch_createUserToTenant(user: firebase.User, tenantId_userClaim: string, currentTenantId: string): Promise<User> {
        if(!tenantId_userClaim){
            throw new Error('tenantId_userClaim not set');
        }
        const existingUserFromUserTenantClaim = await this.userService.fetchUserFromAnotherTenant(user.uid, tenantId_userClaim);
        if (!existingUserFromUserTenantClaim){
            throw new Error('existingUserFromUserTenantClaim not set');
        }

        const newUser: User = {
            arbeitsgruppenExplicitIncluded: [], arbeitsgruppenImplicitExcluded: [],
            UserFreigaben: [],
            adresse: existingUserFromUserTenantClaim.adresse,
            anrede: existingUserFromUserTenantClaim.anrede,
            benachrichtigungenPerEmail: existingUserFromUserTenantClaim.benachrichtigungenPerEmail,
            favorites: [],
            firma: existingUserFromUserTenantClaim.firma,
            freitext: existingUserFromUserTenantClaim.freitext,
            hidden: false,
            isRooterUser: false,
            markedForDeletion: false,
            mobil: existingUserFromUserTenantClaim.mobil,
            nachname: existingUserFromUserTenantClaim.nachname,
            position: existingUserFromUserTenantClaim.position,
            profilFreigabe: null,
            roles: [],
            telefon: existingUserFromUserTenantClaim.telefon,
            titel: existingUserFromUserTenantClaim.titel,
            topics: [],
            vorname: existingUserFromUserTenantClaim.vorname,
            website: existingUserFromUserTenantClaim.website,
            uid: user.uid,
            email: user.email,
            isFirstLogin: true,
            accountFreigegeben: false,
            tenantId: currentTenantId,
            lastLogin: moment().unix()
        }
        
        return await this.userService.createUser(newUser);
    }*/
/*
    private async handleInvalidUserClaimTenantId(dbUser: User) {
        const loading = await this.loadingCtrl.create();
        await loading.present();

        await this.tenantService.switchTenant(dbUser, dbUser.tenantId);
        this.isInitialized = false;
        this.userSubscription.unsubscribe();
        this.subscription.unsubscribe();
        setTimeout(async () => {
            await loading.dismiss();
            await this.Init(); // TODO:AR: ACHTUNG Recursion
        }, 2000);
    }*/
}
