import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthService} from '../../services/auth-service.service';
import {UserService} from '../../services/user.service';
import {IonRouterOutlet, ModalController, Platform} from '@ionic/angular';
import {filter, Observable, Subscription} from 'rxjs';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {ChatService} from '../../services/chat.service';
import {LogoService} from '../../services/logo.service';
import {TenantService} from '../../services/tenant.service';
import {NavigationService} from '../../services/navigation.service';
import {ChatPage} from '../../pages/chat/chat.page';
import {UserSettingsService} from "../../services/user-settings.service";


@Component({
    selector: 'app-custom-toolbar',
    templateUrl: './custom-toolbar.component.html',
    styleUrls: ['./custom-toolbar.component.scss'],
})
export class CustomToolbarComponent implements OnInit, OnDestroy {
    protected currentUrl: string;
    private currentData: Data;
    protected favoriteFeatureEnabled: boolean;
    supportChatEnabled = false;
    protected showChatWithRedaktion: boolean;
    protected hasSupportChat: boolean;
    protected logoUrl: string;

    constructor(
        private userService: UserService,
        public platform: Platform,
        private authService: AuthService,
        private router: Router,
        public route: ActivatedRoute,
        private logoService: LogoService,
        private modalController: ModalController,
        private chatService: ChatService,
        private tenantService: TenantService,
        protected nav: NavigationService,
        protected ionRouterOutlet: IonRouterOutlet,
        private userSettingsService: UserSettingsService
    ) {
        this.showLogo = true;
    }

    subscription: Subscription = new Subscription();

    @Input() showFavoriteButton: boolean;
    @Input() showLogo: boolean;
    @Input() isEdit = false;
    @Output() editClicked = new EventEmitter<boolean>();
    @Input() showSupportChat = true;
    @Input() contextTitle: string;
    @Output() bellClicked = new EventEmitter<boolean>();

    get userLoggedIn(): Observable<boolean> {
        return this.userService.currentUserIsLoggedInAndValid;
    }

    ngOnInit() {

        // await this.authService.Init();
        this.subscription.add(this.logoService.logoUrl$.subscribe(url => {
            this.logoUrl = url;
        }));

        this.userService.currentUserIsLoggedIn$.subscribe(isLoggedIn => {
            if (isLoggedIn) {
                this.logoService.fetchLogoUrl();
            } else {
                this.logoUrl = '';
            }
        });

        this.subscription.add(this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            ).subscribe((event: NavigationEnd) => {
                this.currentUrl = event.url;
            }));

        this.subscription.add(this.route.data.subscribe(async data => {
            await this.authService.Init();
            this.currentData = data;
            const subTitle = this.contextTitle ?? this.currentData.name ?? this.currentData.title ?? '';
            this.showChatWithRedaktion = subTitle !== undefined;
            this.userService.currentUserIsLoggedInAndValid.subscribe(async isLoggedIn => {
                if(isLoggedIn == true) {
                    this.hasSupportChat = await this.chatService.getSupportChat(window.location.pathname) !== null;
                }
            })

        }));

        this.subscription.add(this.tenantService.tenant$.subscribe(tenant => {
            this.favoriteFeatureEnabled = tenant?.features?.favorites.enabled;
            this.supportChatEnabled = tenant?.features?.chats_redaktion.enabled;
        }));
    }

    get subTitle(): string {
        return this.contextTitle ?? this.currentData?.name ?? this.currentData?.title ?? '';
    }

    protected get favoriteEnabled(): boolean {
        return this.userSettingsService.userAccountSettings.getValue()?.favorites?.some(fav => fav.path === this.currentUrl);
    }

    get Type(): string {
        if (this.currentUrl.includes('news')) {
            return 'news';
        } else if (this.currentUrl.includes('wiki')) {
            return 'wiki';
        } else if (this.currentUrl.includes('calendar')) {
            return 'calendar';
        } else if (this.currentUrl.includes('documents')) {
            return 'documents';
        } else if (this.currentUrl.includes('chats')) {
            return 'chats';
        } else if (this.currentUrl.includes('contacts')) {
            return 'contact';
        } else if (this.currentUrl.includes('survey')) {
            return 'survey';
        } else if (this.currentUrl.includes('chat')) {
            return 'chat';
        } else {
            return 'Unknown';
        }
    }

    async chatWithRedaktion() {
        const chatId = await this.chatService.getSupportChat(this.currentUrl);
        if (!chatId) {
            const modal = await this.modalController.create({
                component: ChatPage,
                cssClass: '',
                componentProps: {
                    headerTitle: 'Chat mit Redaktion',
                    chatId,
                    isModal: true,
                    currentUrl: this.currentUrl,
                    title: this.subTitle
                }
            });
            await modal.present();
            modal.onWillDismiss().then(async () => {
                this.hasSupportChat = await this.chatService.getSupportChat(window.location.pathname) !== null;
            });
        } else {
            const modal = await this.modalController.create({
                component: ChatPage,
                cssClass: '',
                componentProps: {
                    headerTitle: 'Chat mit Redaktion',
                    chatId,
                    isModal: true
                }
            });
            await modal.present();
        }
    }

    async addToFavorite() {
        const favorite = {
            title: this.subTitle,
            path: this.currentUrl,
            itemType: this.Type
        };

        await this.userSettingsService.toggleFavorite(this.userService.getCurrentUserUid(), favorite);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
