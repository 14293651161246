import {Injectable} from '@angular/core';
import {
    BehaviorSubject,
    combineLatestWith,
    EMPTY,
    filter,
    firstValueFrom,
    Observable,
    of,
    Subscription,
    switchMap,
    tap
} from 'rxjs';
import {SettingsPaths, User, UserNotificationSettings, UserSystemWorkflowSettings} from '../models/user';
import {ProfilFreigabe} from '../models/profilFreigabe';
import {FirestoreProxyService, Query} from './firestore-proxy.service';
import {map} from 'rxjs/operators';

import {Contact} from '../models/contact';
import {Platform, ToastController} from '@ionic/angular';
import {dbPaths} from './globals';
import {LoggingService} from './logging.service';
import {TenantService} from "./tenant.service";
import {AuthService} from "./auth-service.service";
import {UserSettingsService} from "./user-settings.service";
import {NavigationService} from "./navigation.service";
import {FirestoreProxyCacheService} from "./firestore-proxy-cache.service";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
    currentUserIsLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    currentUserIsActivated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    userFreigabenKey = 'UserFreigaben';
    isRedaktion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isInitialized = false;
    private isInitInProgress: boolean = false;
    private initPromise: Promise<void> | null = null;
    private dbUserLoadSubscription = new Subscription();
    private userSystemWorkflowSettingsSubscription = new Subscription();

    get currentUserIsLoggedInAndValid(): Observable<boolean> {
        return this.currentUserIsLoggedIn$
            .pipe(combineLatestWith(this.currentUser$))
            .pipe(combineLatestWith(this.currentUserIsActivated$))
            .pipe(map(([[isLoggedIn, user], isActivated]) =>
                isLoggedIn && !!user && !!user.uid && isActivated));
    }

    constructor(
        private firestoreProxyService: FirestoreProxyService,
        private platform: Platform,
        private loggingService: LoggingService,
        private tenantService: TenantService,
        private authService: AuthService,
        private userSettingsService: UserSettingsService,
        private toast: ToastController,
        private navigationService: NavigationService,
        private firestoreProxyCacheService: FirestoreProxyCacheService
    ) {
        this.currentUser$.subscribe(async user => {
            this.loggingService.logMethodStart(this, 'handleCurrentUserChanged', {user});
            this.handleCurrentUserChanged(user);
            await this.manageUserSettingChange(user);
        });

        this.currentUserIsLoggedInAndValid.subscribe(async isLoggedInAndValid => {
            this.loggingService.logMethodStart(this, 'handleCurrentUserIsLoggedInAndValidChanged', {isLoggedInAndValid});
            await this.handleCurrentUserIsLoggedInAndValidChanged(isLoggedInAndValid);
        })
    }

    public async Init(): Promise<void> {
        this.loggingService.logMethodStart(this, 'Init - this.isInitialized:', this.isInitialized);

        if (this.isInitialized) {
            return Promise.resolve();
        }
        if (this.isInitInProgress) {
            if (!this.initPromise) {
                this.initPromise = new Promise((resolve, reject) => {
                });
            }
            return this.initPromise;
        }

        this.isInitInProgress = true;
        this.initPromise = new Promise(async (resolve, reject) => {
            try {
                await this.authService.Init();
                await this.tenantService.Init();

                this.authService.currentFirebaseUser$
                    .pipe(combineLatestWith(this.authService.isLoggedIn$))
                    .pipe(combineLatestWith(this.tenantService.tenant$))
                    .pipe(
                        map(([[firebaseUser, isLoggedIn], tenant]) => ({firebaseUser, isLoggedIn, tenant})),
                        tap(data => this.loggingService.log(this, 'Auth state changed', data)),
                        // Only proceed when all conditions are met
                        tap(({firebaseUser, isLoggedIn, tenant}) => {
                            if (!firebaseUser || !firebaseUser.uid || !isLoggedIn || !tenant) {
                                this.dbUserLoadSubscription.unsubscribe();

                                // Reset user state when conditions aren't met
                                if (this.currentUserIsLoggedIn$.getValue() === true) {
                                    this.currentUserIsLoggedIn$.next(false);
                                }
                                if (this.currentUser$.getValue() !== undefined) {
                                    this.currentUser$.next(undefined);
                                }

                                this.isInitialized = true;
                                this.loggingService.logVerbose(this, 'UserService.Init finish currentUserIsLoggedIn$:', false);

                                resolve();

                            }
                        }),
                        filter(({firebaseUser, isLoggedIn, tenant}) =>
                            !!firebaseUser && !!firebaseUser.uid && isLoggedIn && !!tenant
                        )
                    )
                    .subscribe(({firebaseUser, tenant}) => {
                        this.loggingService.logMethodStart(this, 'Subscribing to dbUser', firebaseUser.uid);

                        this.dbUserLoadSubscription.unsubscribe();
                        this.dbUserLoadSubscription = this.loadObservableUserByUid(firebaseUser.uid, tenant.tenantId).subscribe(user => {
                            this.loggingService.log(this, 'loadObservableUserByUid changed', {user});
                            if(user == null || user.uid == null) {
                                this.loggingService.log(this, 'loadObservableUserByUid changed - user is null! Dies kann bei der Registrierung passieren, wenn AuthUser vor DBUser angelegt wird. Ist aber nicht schlimm, denn das Event kommt wieder sobald der Prozess fertig ist.', {user, uid: firebaseUser.uid, tenantId: tenant.tenantId});
                                this.isInitialized = true;
                                resolve();
                                return;
                            }
                            this.loggingService.logVerbose(this, 'UserService.Init finish dbUserLoadSubscription:', true);
                            this.currentUser$.next(user);
                            if (this.currentUserIsLoggedIn$.getValue() === false) {
                                this.currentUserIsLoggedIn$.next(true);
                            }
                            this.isInitialized = true;
                            this.loggingService.logVerbose(this, 'UserService.Init finish currentUserIsLoggedIn$:', true);

                            resolve();

                        });
                    });

            } catch (error) {
                this.loggingService.logError(this, 'Error during userService initialization', error);
                reject(error);
            } finally {
                this.isInitInProgress = false;
                this.initPromise = null;
            }
        });

        return this.initPromise;
    }

    private handleCurrentUserChanged(user: User) {
        this.loggingService.logMethodStart(this, 'handleCurrentUserChanged', {user});

        if((!user || !user.uid || !user.docId) && this.authService.isLoggedIn$.getValue() === true) {
            this.authService.signOut().then(()=>{
                this.loggingService.logWarning(this, 'handleCurrentUserChanged: signOut', {user});
            });
        }

        if (user && user.uid && user.docId) {
            if(this.currentUserIsActivated$.getValue() !== user?.accountFreigegeben ?? false) {
                    this.loggingService.log(this, 'handleCurrentUserChanged - currentUserIsActivated$ changed', {user});
                this.currentUserIsActivated$.next(user?.accountFreigegeben ?? false);
            }

            // update roles
            this.isRedaktion$.next(this.isInRole('redaktion'));
            this.isAdmin$.next(this.isInRole('admin'));
        }
        else {
            this.isRedaktion$.next(false);
            this.isAdmin$.next(false);
        }
    }

    private async manageUserSettingChange(user: User) {
        this.loggingService.logMethodStart(this, 'manageUserSettingChange', {user});
        if (user && user.uid && user.docId) {
            // update userSettings subscription
            if (this.userSettingsService.currentUserDocId.getValue() == null
                || this.userSettingsService.currentUserDocId.getValue() == undefined
                || this.userSettingsService.currentUserDocId.getValue() != user.docId
            ) {

                /**
                 * An dieser Stelle wird erwartet, dass der User vorher nicht angemeldet war, weil seine Settings nicht existierten.
                 * Erst audit-Werte setzen, bevor die Settings geladen werden - um Loop zu vermeiden
                 */
                await this.userSettingsService.setAuditTag(user);
                this.userSettingsService.loadSubscribe(user);
            }
        }
    }

    private async handleCurrentUserIsLoggedInAndValidChanged(isLoggedInAndValid: boolean) {
       this.userSystemWorkflowSettingsSubscription.unsubscribe();
        if(isLoggedInAndValid){
            // User-Workflow-Settings
           this.userSystemWorkflowSettingsSubscription = this.userSettingsService.userSystemWorkflowSettings
               .subscribe(async userSystemWorkflowSettings =>{
                   if(userSystemWorkflowSettings?.userDocId == this.currentUser$.getValue()?.docId){
                      if(userSystemWorkflowSettings.needToUpdateProfile == true){
                          await this.navigationService.navigateToOwnProfile();
                          await this.userSettingsService.mergeUserSettings(
                              this.currentUser$.getValue()?.docId,
                              {
                                  needToUpdateProfile: false
                              } as UserSystemWorkflowSettings,
                              SettingsPaths.UserSystemWorkflowSettings);
                      }
                   }
               }
           )
       }
    }

    // async addUser(
    //     uid: string,
    //     firma: string,
    //     anrede: string,
    //     titel: string,
    //     vorname: string,
    //     nachname: string,
    //     email: string,
    //     tenantId: string,
    //     isOwner: boolean,
    //     isRooterUser: boolean = false,
    //     UserFreigaben: string[] = [],
    //     adresse: string = "",
    //     arbeitsgruppen: number[] = [],
    //     favorites: Favorit[] = [],
    //     freitext: string = "",
    //     hidden: boolean = false,
    //     markedForDeletion: boolean = false,
    //     mobil: string = "",
    //     position: string = "",
    //     telefon: string = "",
    //     topics: string[] = [],
    //     website: string = "",
    //     profilFreigabe: ProfilFreigabe = ProfilFreigabe.Privat,
    //     accountFreigegeben: boolean = false,
    //     roles: string[] = [],
    //     benachrichtigungenPerEmail: boolean = true,
    //     ): Promise<void> {
    //
    //     try {
    //         this.loggingService.logMethodStart(this, 'Creating new user', {uid, email, tenantId, isOwner});
    //         let roles = [];
    //         if (isOwner) {
    //             roles = ['admin'];
    //         }
    //
    //         const user: User = {
    //             arbeitsgruppenExplicitIncluded: [], arbeitsgruppenImplicitExcluded: [],
    //             isRooterUser,
    //             UserFreigaben,
    //             adresse,
    //             favorites,
    //             freitext,
    //             hidden,
    //             markedForDeletion,
    //             mobil,
    //             position,
    //             telefon,
    //             topics,
    //             website,
    //             uid,
    //             firma,
    //             anrede,
    //             titel,
    //             vorname,
    //             nachname,
    //             email,
    //             profilFreigabe,
    //             accountFreigegeben,
    //             tenantId,
    //             roles,
    //             benachrichtigungenPerEmail,
    //         };
    //         this.tenantService.ensureUserIsAssignToATenant(user);
    //
    //         return this.firestoreProxyService.setDocument(dbPaths.usersPath, user.uid, user)
    //             .catch(error => {
    //                 console.error('Something went wrong with added user to firestoreProxyService: ', error);
    //             });
    //
    //         this.loggingService.log(this, 'User created successfully', {uid});
    //     } catch (error) {
    //         this.loggingService.logError(this, 'Failed to create user', error);
    //         throw error;
    //     }
    // }


    async registerNewUser(
        firma: string,
        anrede: string,
        titel: string,
        vorname: string,
        nachname: string,
        email: string,
        password: string,
        ){
        try {
            this.loggingService.logMethodStart(this, 'Creating new user', {email});

            let roles = [];

            let user: User = {
                UserFreigaben: [],
                accountFreigegeben: false,
                adresse: "",
                anrede: anrede,
                email: email,
                firma: firma,
                freitext: "",
                hidden: false,
                markedForDeletion: false,
                mobil: "",
                nachname: nachname,
                position: "",
                profilFreigabe: ProfilFreigabe.Privat,
                roles: roles,
                telefon: "",
                tenantId: this.tenantService.getCurrentTenantId(),
                titel: titel,
                vorname: vorname,
                website: "",
                uid: undefined // authUser.user.uid <- wird später gesetzt und ist erforderlich
            }

            try {
                user = this.tenantService.ensureUserIsAssignToATenant(user);
            }catch (e) {
                this.loggingService.logError(this, 'registerNewUser: tenantId is empty #1001', {email});
                const toast = await this.toast.create({
                    header: 'Fehler',
                    message: 'Fehler beim Registrieren des Benutzers. Bitte versuchen Sie es erneut. #1001',
                    duration: 5000,
                    color: 'danger',
                    position: 'top',
                    positionAnchor: 'header'
                });
                await toast.present();
                return;
            }

            const authUser = await this.authService.createNewUser(email, password, vorname + ' ' + nachname);
            if(!authUser){
                this.loggingService.logError(this, 'registerNewUser: authUser is empty #1002', {email});
                const toast = await this.toast.create({
                    header: 'Fehler',
                    message: 'Fehler beim Registrieren des Benutzers. Bitte versuchen Sie es erneut. #1002',
                    duration: 5000,
                    color: 'danger',
                    position: 'top',
                    positionAnchor: 'header'
                });
                await toast.present();
                return;
            }

            user.uid = authUser.user.uid;

            await this.createUser(user);
            await this.tenantService.useTenant(user.tenantId);

            this.loggingService.log(this, 'User created successfully', {email});
        }catch (e) {
            this.loggingService.logError(this, 'Failed to create user', e);
            const toast = await this.toast.create({
                header: 'Fehler',
                message: 'Fehler beim Registrieren des Benutzers. Bitte versuchen Sie es erneut. #9001',
                duration: 5000,
                color: 'danger',
                position: 'top',
                positionAnchor: 'header'
                });
            await toast.present();
        }
    }

    async createUser(user: User):Promise<User> {
        this.loggingService.logMethodStart(this, 'Creating new user', {user});
        if(!user
            /*|| !user.uid - beim Invite ist die uid noch nicht bekannt*/
            || !user.email || !user.tenantId){
            this.loggingService.logError(this, 'createUser: user is empty #1003', {user});
            const toast = await this.toast.create({
                header: 'Fehler',
                message: 'Fehler beim Registrieren des Benutzers. Bitte versuchen Sie es erneut. #1003',
                duration: 5000,
                color: 'danger',
                position: 'top',
                positionAnchor: 'header'
            });
            await toast.present();
            return;
        }

        try {
            user = this.tenantService.ensureUserIsAssignToATenant(user);
            const dbUser = await this.firestoreProxyService.addDocument(dbPaths.usersPath, user);
            await this.setUserDefaultSetting(dbUser);
            return dbUser;
        }catch(error){
            this.loggingService.logError('Something went wrong with added user to firestoreProxyService: ', error);
            const toast = await this.toast.create({
                header: 'Fehler',
                message: 'Fehler beim Registrieren des Benutzers. Bitte versuchen Sie es erneut. #9002',
                duration: 5000,
                color: 'danger',
                position: 'top',
                positionAnchor: 'header'
            });
            await toast.present();
            throw new Error('Something went wrong with added user to firestoreProxyService: ' + error);
        }
    }

    private async setUserDefaultSetting(dbUser: User) {

        await this.userSettingsService.mergeUserSettings(dbUser.docId, {
            needToUpdateProfile: true
        } as UserSystemWorkflowSettings,
            SettingsPaths.UserSystemWorkflowSettings);

        await this.userSettingsService.mergeUserSettings(dbUser.docId,{
            benachrichtigungenPerEmail: true
        } as UserNotificationSettings,
            SettingsPaths.UserNotificationSettings);

    }

    loadObservableUserByUid(uid: string, tenantId: string): Observable<User> {
        this.loggingService.logMethodStart(this, 'Getting user by UID', {uid});
        return this.firestoreProxyService.query<User>(`${dbPaths.tenantPath}/${tenantId}/${dbPaths.usersPath}`, [{
            fieldPath: 'uid',
            filterOperator: '==',
            value: uid
        }], null, 1, false).pipe(
            map(x => {
                this.loggingService.log(this, 'Retrieved user by UID', {user: x[0]});
                let result = x[0];
                if (result) {
                    this.tenantService.ensureUserIsAssignToATenant(result);
                }

                return result;
            })
        );
    }

    loadUserByDocId(docId: string): Observable<User> {
        this.loggingService.logMethodStart(this, 'Getting user by docId', {docId});
        return this.firestoreProxyService.getDocument<User>(dbPaths.usersPath, docId);
    }

    getUserByDocId(docId: string): Promise<User> {
        return firstValueFrom(this.loadUserByDocId(docId));
    }
    async getUserByUid(uid: string): Promise<User> {
        if (!(uid?.length > 0)) {
            this.loggingService.logError(this, 'getUserByUid: uidArray is empty', uid);
            throw new Error('uidArray is empty');
        }
        this.loggingService.logMethodStart(this, 'Getting user by UID', {uid});

        const users = await firstValueFrom(this.firestoreProxyCacheService.query<User>(
            dbPaths.usersPath,
            [{
                fieldPath: 'uid',
                filterOperator: '==',
                value: uid
            }],
            null,
            1
        ));

        return users[0];
    }

    async getUserNameByUid(uid: string): Promise<string> {
        const user = this.getUserByUid(uid);
        return user.then(x => x.vorname + ' ' + x.nachname);
    }

    async fetchUserFromAnotherTenant(uid: string, tenantId_userClaim: string) {
        if (!(uid?.length > 0)) {
            this.loggingService.logError(this, 'fetchUserFromAnotherTenant: uidArray is empty', uid);
            throw new Error('uidArray is empty');
        }

        const users = await firstValueFrom(this.firestoreProxyService.query<User>(
            `${dbPaths.tenantPath}/${tenantId_userClaim}/${dbPaths.usersPath}`,
            [{
                fieldPath: 'uid',
                filterOperator: '==',
                value: uid
            }],
            null,
            1,
            false
        ));

        return users[0];
    }

    getCurrentUserDocId(): string {
        this.loggingService.logMethodStart(this, 'Getting current user doc ID');
        const docId = this.currentUser$.getValue()?.docId;
        this.loggingService.log(this, 'Current user doc ID', {docId});
        return docId;
    }

    getCurrentUserUid(): string {
        this.loggingService.logMethodStart(this, 'Getting current user ID');
        const uid = this.authService.currentFirebaseUser$.getValue()?.uid;
        this.loggingService.log(this, 'Current user ID', {uid});
        return uid;
    }

    getUserByEMail(email: string): Observable<User> {
        return this.firestoreProxyService.query<User>(dbPaths.usersPath, [{
            fieldPath: 'email',
            filterOperator: '==',
            value: email
        }], null, 1)
            .pipe(
                map(users => users[0])
            );
    }

    getCurrentUserName(): string {
        this.loggingService.logMethodStart(this, 'Getting current user name');
        const user = this.currentUser$.getValue();
        const name = user?.vorname + ' ' + user?.nachname;
        this.loggingService.log(this, 'Current user name', {name});
        return name;
    }

    getAllUser(): Observable<User[]> {
        this.loggingService.logMethodStart(this, 'Getting all users');
        return this.firestoreProxyCacheService.query<User>(dbPaths.usersPath, [{
            fieldPath: 'uid',
            filterOperator: '!=',
            value: ''
        }]);
    }

    async updateUser(user: Partial<User>): Promise<void> {
        try {
            this.loggingService.logMethodStart(this, 'Updating user', {docId: user.docId, uid: user.uid});
            await this.firestoreProxyService.mergeDocument(user.docId, dbPaths.usersPath, user);
            this.loggingService.log(this, 'User updated successfully', {docId: user.docId, uid: user.uid});
        } catch (error) {
            this.loggingService.logError(this, 'Failed to update user', error);
            throw error;
        }
    }

    resetUnreadCount(docId: string) {
        this.loggingService.logMethodStart(this, 'Resetting unread count', {docId});
        this.firestoreProxyService.mergeDocument(docId, dbPaths.usersPath, {unreadCount: 0})
            .then(() => this.loggingService.log(this, 'Reset unread count successfully'))
            .catch(error => this.loggingService.logError(this, 'Failed to reset unread count', error));
    }

    /// -- Kontaktfreigaben >>>
    updateProfilFreigabe(docId: string, profilFreigabe: ProfilFreigabe) {
        this.firestoreProxyService.mergeDocument(docId, dbPaths.usersPath, {profilFreigabe});
    }

    async removeUserFreigabe(uid: string, removeUid: string) {
        try {
            this.loggingService.logMethodStart(this, 'Removing user freigabe', {uid, removeUid});
            await this.firestoreProxyService.removeValueFromArray(uid, dbPaths.usersPath, this.userFreigabenKey, removeUid);
            await this.removeUserFreigabeLocal(removeUid);
            this.loggingService.log(this, 'User freigabe removed successfully');
        } catch (error) {
            this.loggingService.logError(this, 'Failed to remove user freigabe', error);
            throw error;
        }
    }

    addUserFreigabe(uid: string, freizugebenUid: string) {
        this.firestoreProxyService.addValueToArray(uid, dbPaths.usersPath, this.userFreigabenKey, [freizugebenUid])
            .then(() => this.addUserFreigabeLocal(freizugebenUid));
    }

    allUsersMitBeidseitigerFreigabe(): Observable<User[]> {
        return this.firestoreProxyService.query<User>(dbPaths.usersPath,
            [{
                fieldPath: 'UserFreigaben',
                filterOperator: 'array-contains',
                value: this.getCurrentUserUid()
            },
                {
                    fieldPath: 'tenantId',
                    filterOperator: 'in',
                    value: [this.currentUser$.getValue().tenantId]
                }
            ]).pipe(map(x => x.map(y => {
            if (this.currentUser$.getValue().UserFreigaben.indexOf(y.uid) > -1) {
                return y;
            }
        })));
    }

    allUsersMitFreigabeAlle(): Observable<User[]> {
        return this.firestoreProxyService.query(dbPaths.usersPath,
            [{
                fieldPath: 'hidden',
                filterOperator: '!=',
                value: true
            },
                {
                fieldPath: 'profilFreigabe',
                filterOperator: '==',
                value: ProfilFreigabe.Alle
            },
                {
                    fieldPath: 'tenantId',
                    filterOperator: 'in',
                    value: [this.currentUser$.getValue().tenantId]
                }]);
    }

    getAllAdminsAndRedaktion(): Observable<User[]> {
        return this.firestoreProxyCacheService.query(dbPaths.usersPath,
            [{
                fieldPath: 'roles',
                filterOperator: 'array-contains-any',
                value: ['admin', 'redaktion']
            },
                {
                    fieldPath: 'tenantId',
                    filterOperator: 'in',
                    value: [this.currentUser$.getValue().tenantId]
                }]);
    }

    getAllUserCount(): Observable<number> {
        return this.firestoreProxyCacheService.count<User>(dbPaths.usersPath, [{
            fieldPath: 'hidden',
            filterOperator: '!=',
            value: true
        },{
            fieldPath: 'accountFreigegeben',
            filterOperator: '==',
            value: true
        }])
    }

    /// --- Arbeitsgruppen >>>

    getUsersWichAreExplicitIncludedArbeitsgruppeCount(arbeitsgruppeId: number): Observable<number> {
        this.loggingService.logMethodStart(this, 'Getting users count wich are explicit included arbeitsgruppe', {arbeitsgruppeId});
        return this.userSettingsService.querySettingsForAllUsers({
            fieldPath: 'arbeitsgruppenExplicitIncluded',
            filterOperator: 'array-contains',
            value: arbeitsgruppeId
        }).pipe(switchMap((setting) => {
            let q :Query[] = null;
            if(setting?.length > 0){
                q = [{
                    fieldPath: 'docId',
                    filterOperator: 'in',
                    value: setting.map(x => x.userDocId)
                }]
                return this.firestoreProxyCacheService.count<User>(dbPaths.usersPath, q)
            }else {
                return of(0);
            }
        }));
    }

    getUsersWichAreNotImplicitExcludesArbeitsgruppeCount(arbeitsgruppeId: number): Observable<number> {
        this.loggingService.logMethodStart(this, 'Getting users count wich are not implicit excludes arbeitsgruppe', {arbeitsgruppeId});
        return this.userSettingsService.querySettingsForAllUsers({
            fieldPath: 'arbeitsgruppenImplicitExcluded',
            filterOperator: 'array-contains',
            value: arbeitsgruppeId
        }).pipe(switchMap((setting) => {
            let q :Query[] = null;
            if(setting?.length > 0){
                q = [{
                    fieldPath: 'docId',
                    filterOperator: 'not-in',
                    value: setting.map(x => x.userDocId)
                }]
            }
            return this.firestoreProxyCacheService.count<User>(dbPaths.usersPath, q)
        }));

    }

    getUsersWichAreExplicitIncludedArbeitsgruppe(arbeitsgruppeId: number): Observable<User[]> {
        this.loggingService.logMethodStart(this, 'Getting users wich are explicit included arbeitsgruppe', {arbeitsgruppeId});
        return this.userSettingsService.querySettingsForAllUsers({
            fieldPath: 'arbeitsgruppenExplicitIncluded',
                filterOperator: 'array-contains',
            value: arbeitsgruppeId
        }).pipe(switchMap((setting) => {
            let q :Query[] = null;
            if(setting?.length > 0){
                q = [{
                    fieldPath: 'docId',
                    filterOperator: 'in',
                    value: setting.map(x => x.userDocId)
                }]
                return this.firestoreProxyCacheService.query<User>(dbPaths.usersPath, q)
            }else {
                return of([]);
            }
        }));
    }

    getUsersWichAreNotImplicitExcludesArbeitsgruppe(arbeitsgruppeId: number): Observable<User[]> {
        this.loggingService.logMethodStart(this, 'Getting users wich are not implicit excludes arbeitsgruppe', {arbeitsgruppeId});
        return this.userSettingsService.querySettingsForAllUsers({
            fieldPath: 'arbeitsgruppenImplicitExcluded',
                filterOperator: 'array-contains',
            value: arbeitsgruppeId
        }).pipe(switchMap((setting) => {
            let q :Query[] = null;
            if(setting?.length > 0){
                q = [{
                    fieldPath: 'docId',
                    filterOperator: 'not-in',
                    value: setting.map(x => x.userDocId)
                }]
            }
            return this.firestoreProxyCacheService.query<User>(dbPaths.usersPath, q)
        }));
    }

    /// <<< Kontaktfreigaben ---
    getUsersProfileWhereAccessAllowed(agList: number[]): Observable<User[]> {
        const ag = agList;
        if (!ag || !(ag.length > 0)) {
            return of([]);
        }
        return this.firestoreProxyService.query<User>(dbPaths.usersPath,
            [
                {
                    fieldPath: 'profilFreigabe',
                    filterOperator: '==',
                    value: ProfilFreigabe.Arbeitsgruppen
                },
                {
                    fieldPath: 'arbeitsgruppen',
                    filterOperator: 'array-contains-any',
                    value: ag
                },
                {
                    fieldPath: 'tenantId',
                    filterOperator: 'in',
                    value: [this.currentUser$.getValue().tenantId]
                }
            ]);
    }

    /// Kontakte >>>
    getUserNamesByDocIds(userDocIds: string[]): Observable<{ docId: string, vorname: string, name: string, firma: string }[]> {
        if (userDocIds.length === 0) {
            return EMPTY;
        }

        return this.firestoreProxyService.query<User>(
            dbPaths.usersPath,
            [{
                fieldPath: 'docId',
                filterOperator: 'in',
                value: userDocIds
            },
                {
                    fieldPath: 'hidden',
                    filterOperator: '!=',
                    value: true
                }
            ])
            .pipe(
                map(users => users.filter(user => user.hidden !== true)),
                map(u => {
                    const result = u.map(x => {
                        return {
                            docId: x.docId,
                            vorname: x.vorname,
                            name: x.nachname,
                            firma: x.firma
                        };
                    });

                    return result.sort((a, b) =>
                        (a?.name?.trim().toLocaleLowerCase() > b?.name?.trim().toLocaleLowerCase()) ? 1 :
                            (b?.name?.trim().toLocaleLowerCase() > a?.name?.trim().toLocaleLowerCase() ? -1 : 0));
                }));
    }

    /// <<< Arbeitsgruppen ---

    /// Kontakte >>>
    getAllContacts(): Observable<Contact[]> {
        return this.firestoreProxyService.query<User>(
            dbPaths.usersPath,
            [{
                fieldPath: 'accountFreigegeben',
                filterOperator: '==',
                value: true
            }])
            .pipe(map(x =>
                x.filter(f => (f.docId !== null) && (f.hidden !== true))
                    .map(y => {
                        return {
                            docId: y.docId,
                            uid: y.uid,
                            vorname: `${y.vorname}`,
                            name: `${y.nachname}`,
                            firma: `${y.firma}`,
                            hidden: false
                        };
                    })));


        /*results.filter( k => k.hidden !== true)
                    .sort((x, y) => `${x.name}${x.vorname}`.toLocaleLowerCase() > `${y.name}${y.vorname}`.toLocaleLowerCase() ? 1 : -1);*/
    }

    getUsersByRole(role: string) {
        return this.firestoreProxyService.query<User>(
            dbPaths.usersPath,
            [{
                fieldPath: 'roles',
                filterOperator: 'array-contains',
                value: role
            }]);
    }

    private isInRole(role: string): boolean {
        this.loggingService.logMethodStart(this, 'Checking role', {role});
        if (role === '') {
            return true;
        }

        const user = this.currentUser$.getValue();
        if (user === undefined || user === null) {
            return false;
        }
        const hasRole = user.roles != null && user.roles.some(r => r === role);
        this.loggingService.log(this, `User ${hasRole ? 'has' : 'does not have'} role: ${role}`);
        return hasRole;
    }

    private addUserFreigabeLocal(freizugebenUid: string) {
        this.currentUser$.toPromise().then(user => {
            if (!user.UserFreigaben) {
                user.UserFreigaben = [freizugebenUid];
            } else {
                const index = user.UserFreigaben.toString().indexOf(freizugebenUid);
                if (index === -1) {
                    user.UserFreigaben.push(freizugebenUid);
                }
            }
        });
    }

    private async removeUserFreigabeLocal(removeUid: string) {
        try {
            // Use firstValueFrom instead of toPromise
            const user = await firstValueFrom(this.currentUser$);

            if (!user?.UserFreigaben) {
                return;
            }

            // Assuming UserFreigaben is an array
            const index = user.UserFreigaben.indexOf(removeUid);
            if (index > -1) {
                user.UserFreigaben.splice(index, 1);
            }
        } catch (error) {
            console.error('Error removing user freigabe:', error);
            // Handle error appropriately
        }
    }

    async extractUserCustomClaims(): Promise<{ [p: string]: any }> {
        const idToken = await this.authService.currentFirebaseUser$.getValue()?.getIdTokenResult();
        const claims = idToken?.claims;
        return claims;
    }

}
