import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {NavigationService} from '../services/navigation.service';
import {switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth-service.service';
import {AppInitializationService} from "../services/app-initialization.service";

@Injectable({
    providedIn: 'root'
})
export class AuthnGuardService {

    constructor(
        private authService: AuthService,
        private appInitializationService: AppInitializationService,
        private nav: NavigationService) {
    }

    canActivate(): Observable<boolean> | boolean {
        console.log('AuthnGuardService.canActivate()');
        return from(this.appInitializationService.Initialize()).pipe(switchMap(() => {
            return this.authService.isLoggedIn().pipe(tap(async loggedIn => {
                if (!loggedIn) {
                    await this.nav.navigateToLogin();
                    return false;
                }
                return true;
            }));
        }));
    }
}
