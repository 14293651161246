import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FirestoreProxyService} from './firestore-proxy.service';
import {dbPaths} from './globals';
import {FirestoreProxyCacheService} from "./firestore-proxy-cache.service";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    constructor(private firestore: FirestoreProxyService,
                private firestoreProxyCacheService: FirestoreProxyCacheService) {
    }

    get<T>(docid: string): Observable<T> {
        return this.firestore.getDocument<T>(dbPaths.settingsPath, docid);
    }

    getObservable<T>(docid: string): Observable<T> {
        return this.firestoreProxyCacheService.getDocumentObservableIfExistOtherwiseReturnNullAndStayObserved<T>(dbPaths.settingsPath, docid);
    }

    async update<T>(docid: string, doc: T) {
        return this.firestore.setDocument(dbPaths.settingsPath, docid, doc);
    }

}
