// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
  padding-left: 48px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: x-small;
  line-height: 1.5;
}

.infoimage {
  padding-bottom: 5px;
}

a {
  color: var(--ion-color-secondary) !important;
}

.radtkesolutions {
  color: #ee7203;
  font-size: x-small;
  margin-top: 15px;
  margin-bottom: 10px;
}
.radtkesolutions img {
  margin: 0px 0 0 0;
}
.radtkesolutions .radtkesolutions-intro {
  padding-left: 36px;
}
.radtkesolutions .radtkesolutions-intro ion-icon {
  position: relative;
  top: 1px;
}

hr {
  background: lightgrey;
  margin: 0 2em;
}`, "",{"version":3,"sources":["webpack://./src/brand/components/imprint/imprint.component.scss"],"names":[],"mappings":"AAAA;EAEE,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAOA;EACE,4CAAA;AAJF;;AAQA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AALF;AAOE;EACE,iBAAA;AALJ;AAQE;EACE,kBAAA;AANJ;AAQI;EACE,kBAAA;EACA,QAAA;AANN;;AAWA;EAEE,qBAAA;EACA,aAAA;AATF","sourcesContent":[".info{\n  // text-align: center;\n  padding-left: 48px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  font-size: x-small;\n  line-height: 1.5;\n}\n\n.infoimage{\n  padding-bottom: 5px;\n}\n\n.infolink{\n  //padding-top: 10px;\n}\n\na{\n  color: var(--ion-color-secondary) !important;\n}\n\n\n.radtkesolutions{\n  color: #ee7203;\n  font-size: x-small;\n  margin-top: 15px;\n  margin-bottom: 10px;\n  \n  img{\n    margin: 0px 0 0 0;\n  }\n\n  .radtkesolutions-intro{\n    padding-left: 36px;\n\n    ion-icon{\n      position: relative;\n      top: 1px;\n    }\n  }\n}\n\nhr {\n\n  background: lightgrey;\n  margin: 0 2em;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
