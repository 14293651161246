import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColorService} from '../../services/color.service';

@Component({
    selector: 'app-color-choice',
    templateUrl: './color-choice.component.html',
    styleUrls: ['./color-choice.component.scss'],
})
export class ColorChoiceComponent implements OnInit {

    showPrimaryPicker: boolean;
    showSecondaryPicker: boolean;

    primaryHue = 0;
    primarySaturation = 100;
    primaryBrightness = 100;
    primaryRed = 0;
    primaryGreen = 0;
    primaryBlue = 0;

    secondaryHue = 0;
    secondarySaturation = 100;
    secondaryBrightness = 100;
    secondaryRed = 0;
    secondaryGreen = 0;
    secondaryBlue = 0;

    @Input() primaryHexColor: string;
    @Input() showPrimaryButton: boolean;
    @Input() secondaryHexColor: string;
    @Input() showSecondaryButton: boolean;
    @Output() onSelectColor = new EventEmitter();

    constructor(private colorService: ColorService) {
        this.showPrimaryPicker = false;
        this.showPrimaryButton = false;
        this.showSecondaryPicker = false;
        this.showSecondaryButton = false;
    }

    ngOnInit() {
        if (!this.primaryHexColor) {
            this.primaryHexColor = '#d16403';
        }
        let hsb = this.colorService.hexToHsb(this.primaryHexColor);
        this.primaryHue = hsb[0];
        this.primarySaturation = hsb[1];
        this.primaryBrightness = hsb[2];

        if (!this.secondaryHexColor) {
            this.secondaryHexColor = '#096972';
        }
        hsb = this.colorService.hexToHsb(this.secondaryHexColor);
        this.secondaryHue = hsb[0];
        this.secondarySaturation = hsb[1];
        this.secondaryBrightness = hsb[2];
    }

    public openPrimaryPicker() {
        const rgb = this.colorService.hsbToRgb(this.primaryHue / 360, this.primarySaturation / 100, this.primaryBrightness / 100);
        this.primaryRed = rgb[0];
        this.primaryGreen = rgb[1];
        this.primaryBlue = rgb[2];
        document.documentElement.style.setProperty('--primary-hue', this.primaryHue.toString());
        this.showPrimaryPicker = true;
    }

    public openSecondaryPicker() {
        const rgb = this.colorService.hsbToRgb(this.secondaryHue / 360, this.secondarySaturation / 100, this.secondaryBrightness / 100);
        this.secondaryRed = rgb[0];
        this.secondaryGreen = rgb[1];
        this.secondaryBlue = rgb[2];
        document.documentElement.style.setProperty('--secondary-hue', this.secondaryHue.toString());
        this.showSecondaryPicker = true;
    }

    saveChange() {
        if (this.showPrimaryPicker) {
            this.onSelectColor.emit(this.primaryHexColor);
            this.showPrimaryPicker = false;
        } else if (this.showSecondaryPicker) {
            this.onSelectColor.emit(this.secondaryHexColor);
            this.showSecondaryPicker = false;
        }
    }

    updatePrimaryColor() {
        const rgb = this.colorService.hsbToRgb(this.primaryHue / 360, this.primarySaturation / 100, this.primaryBrightness / 100);
        this.primaryHexColor = this.colorService.rgbToHex(rgb[0], rgb[1], rgb[2]);
        this.primaryRed = rgb[0];
        this.primaryGreen = rgb[1];
        this.primaryBlue = rgb[2];
        document.documentElement.style.setProperty('--primary-hue', this.primaryHue.toString());
    }

    updateSecondaryColor() {
        const rgb = this.colorService.hsbToRgb(this.secondaryHue / 360, this.secondarySaturation / 100, this.secondaryBrightness / 100);
        this.secondaryHexColor = this.colorService.rgbToHex(rgb[0], rgb[1], rgb[2]);
        this.secondaryRed = rgb[0];
        this.secondaryGreen = rgb[1];
        this.secondaryBlue = rgb[2];
        document.documentElement.style.setProperty('--secondary-hue', this.secondaryHue.toString());
    }

    updatePrimaryColorFromHex() {
        const hsb = this.colorService.hexToHsb(this.primaryHexColor);
        this.primaryHue = hsb[0];
        this.primarySaturation = hsb[1];
        this.primaryBrightness = hsb[2];
        this.updatePrimaryColor();
    }

    updateSecondaryColorFromHex() {
        const hsb = this.colorService.hexToHsb(this.secondaryHexColor);
        this.secondaryHue = hsb[0];
        this.secondarySaturation = hsb[1];
        this.secondaryBrightness = hsb[2];
        this.updateSecondaryColor();
    }

    updatePrimaryColorFromRgb() {
        this.primaryHexColor = this.colorService.rgbToHex(this.primaryRed, this.primaryGreen, this.primaryBlue);
        const hsb = this.colorService.rgbToHsb(this.primaryRed, this.primaryGreen, this.primaryBlue);
        this.primaryHue = hsb[0];
        this.primarySaturation = hsb[1];
        this.primaryBrightness = hsb[2];
        this.updatePrimaryColor();
    }

    updateSecondaryColorFromRgb() {
        this.secondaryHexColor = this.colorService.rgbToHex(this.secondaryRed, this.secondaryGreen, this.secondaryBlue);
        const hsb = this.colorService.rgbToHsb(this.secondaryRed, this.secondaryGreen, this.secondaryBlue);
        this.secondaryHue = hsb[0];
        this.secondarySaturation = hsb[1];
        this.secondaryBrightness = hsb[2];
        this.updateSecondaryColor();
    }
}
