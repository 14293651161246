import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import {environment} from '../../brand/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor() { }

  async getAppInfoString(): Promise<string> {
    try {
      const appInfo = await App.getInfo();
      const appVersion = `${appInfo.version} (${appInfo.build}) [${environment.production === true ? 'P' : 'D'}]`;
      return appVersion; // appInfo.version + '(' + appInfo.build + '--)' + ' [' + environment.production ? 'P' : 'D' + ']';
    }
    catch { return `${environment.appVersion} [${environment.production === true ? 'P' : 'D'}]`; }
  }

  async getAppVersion(): Promise<number> {
    try {
      const appInfo = await App.getInfo();
      // tslint:disable-next-line:radix
      return Number.parseInt(appInfo.version.split('.').join(''));
    }catch (e) {
      return 0;
    }


  }
}
