// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-list-header {
  margin-bottom: 0 !important;
}

.bottom-padding {
  height: 100px;
}

ion-skeleton-text {
  border-radius: 4px;
}

ion-avatar {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/brand/components/start-page/start-page.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAAF","sourcesContent":[".custom-list-header {\n  margin-bottom: 0 !important;\n}\n\n.bottom-padding {\n  height: 100px;\n}\n\n// Styles for skeleton loader\nion-skeleton-text {\n  border-radius: 4px;\n}\n\nion-avatar {\n  width: 20px;\n  height: 20px;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
