import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {BehaviorSubject, Observable, combineLatest, of, takeUntil, takeWhile, firstValueFrom, EMPTY, throwError} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import {dbPaths, Globals} from './globals';
import {Tenant} from '../models/tenant';
import {arrayRemove, arrayUnion, doc, getDoc} from '@angular/fire/firestore';
import {LoggingService} from './logging.service';
import firestore = firebase.firestore;
import {environment} from "../../brand/environments/environment";
import {AuthService} from "./auth-service.service";
import {Router} from "@angular/router";
import { switchMap } from 'rxjs/operators';

export interface Query {
    fieldPath: string;
    filterOperator: WhereFilterOp;
    value: any;
}

export type WhereFilterOp =
    | '<'
    | '<='
    | '=='
    | '!='
    | '>='
    | '>'
    | 'array-contains'
    | 'in'
    | 'array-contains-any'
    | 'not-in';

export interface Order {
    fieldPath: string;
    directionStr?: OrderByDirection;
}

export type OrderByDirection = 'desc' | 'asc';

export type DocumentChangeType = 'added' | 'removed' | 'modified';

export interface PagedResult<T> {
    items: T[];
    lastVisible: any;
    hasMore: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FirestoreProxyService {
    public currentTenant = new BehaviorSubject<Tenant>(null);

    constructor(
        private angularFirestore: AngularFirestore,
        private logger: LoggingService,
        private authService: AuthService,
        private router: Router,
    ) {
    }

    prependTenant(path: dbPaths | string): string {
        let tenantId = '';
        if(environment.useStaticTenant && environment.tenantId){
            tenantId = environment.tenantId;
        }

        if (!tenantId){
            tenantId = this.currentTenant.getValue().tenantId;
        }

        if(!tenantId) {
            throw new Error('No tenantId given');
        }

        if (path.startsWith('/')) {
            path = path.substring(1);
        }
        return `${dbPaths.tenantPath}/${tenantId}/${path}`;
    }

    setDocumentWithoutTenant<T>(id: string, path: dbPaths | string, obj: T): Promise<void> {
        return this.angularFirestore.collection(path).doc<T>(id).set(obj);
    }

    async setDocument<T>(path: dbPaths | string, id: string, obj: T, prependTenant = true): Promise<void> {
        try {
            const prependedPath = prependTenant ? this.prependTenant(path) : path;
            this.logger.logMethodStart(this, `Setting document at path: ${prependedPath}, ID: ${id}`, obj);
            await this.angularFirestore.collection(prependedPath).doc<T>(id).set(obj);
            this.logger.log(this, `Document set successfully`);
        } catch (error) {
            this.logger.logError(this, `Failed to set document at path: ${this.prependTenant(path)}, ID: ${id}`, error);
            throw error;
        }
    }

    async addDocumentWithoutTenant<T>(path: string, document: T): Promise<T> {
        const addedDoc = await this.angularFirestore.collection<T>(path).add(document);
        return {...document, docId: addedDoc.id};
    }

    async addDocument<T>(path: dbPaths | string, document: T): Promise<T> {
        try {
            const prependedPath = this.prependTenant(path);
            this.logger.logMethodStart(this, `Adding document to path: ${prependedPath}`, document);
            const addedDoc = await this.angularFirestore.collection<T>(prependedPath).add(document);
            this.logger.log(this, `Document added successfully with ID: ${addedDoc.id}`);
            return {...document, docId: addedDoc.id};
        } catch (error) {
            this.logger.logError(this, `Failed to add document to path: ${this.prependTenant(path)}`, error);
            throw error;
        }
    }

    async documentExists(id: string, path: string): Promise<boolean> {
        try {
            const prependedPath = this.prependTenant(path);
            this.logger.logMethodStart(this, `Checking if document exists at path: ${prependedPath}, ID: ${id}`);
            
            // Using the firstValueFrom from rxjs to replace toPromise()
            const docSnapshot = await firstValueFrom(
                this.angularFirestore.collection(prependedPath).doc(id).get()
            );
            
            const exists = docSnapshot.exists;
            this.logger.log(this, `Document exists check result: ${exists}`);
            return exists;
        } catch (error) {
            this.logger.logError(this, `Failed to check document existence at path: ${path}, ID: ${id}`, error);
            throw error;
        }
    }

    async documentExistsWithQuery(path: dbPaths, where: Query[], prependTenant = true): Promise<boolean> {
        try {
            const prependedPath = prependTenant ? this.prependTenant(path) : path;
            this.logger.logMethodStart(this, `Checking if document exists with query at path: ${prependedPath}`, { where });
            
            // Create a query with the specified conditions and limit to 1 result for efficiency
            const querySnapshot = await firstValueFrom(
                this.angularFirestore.collection(prependedPath, ref => {
                    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                    
                    if (where && where.length > 0) {
                        where.forEach(q => {
                            if (q && q.fieldPath && q.value !== undefined) {
                                query = query.where(q.fieldPath, q.filterOperator, q.value);
                            }
                        });
                    }
                    
                    // Limit to 1 since we only need to know if any document exists
                    return query.limit(1);
                }).get()
            );
            
            const exists = !querySnapshot.empty;
            this.logger.log(this, `Document exists with query: ${exists}`);
            return exists;
        } catch (error) {
            this.logger.logError(this, `Failed to check document existence with query at path: ${path}`, error);
            throw error;
        }
    }

    async documentExistsWithoutTenant(id: string, path: string): Promise<boolean>{
        const result = await this.angularFirestore.collection(path).doc(id).get().toPromise();
        return result.exists;
    }

    async updateDocument<T>(path: dbPaths | string, id: string, obj: T, prependTenant = true): Promise<string> {
        try {
            const prependedPath = prependTenant ? this.prependTenant(path) : path;
            this.logger.logMethodStart(this, `Updating document at path: ${prependedPath}, ID: ${id}`, obj);
            await this.angularFirestore.collection(prependedPath).doc<T>(id).update(obj);
            this.logger.log(this, `Document updated successfully`);
            return id;
        } catch (error) {
            this.logger.logError(this, `Failed to update document at path: ${this.prependTenant(path)}, ID: ${id}`, error);
            throw error;
        }
    }

    mergeDocument<T>(id: string, path: dbPaths | string, obj: T, prependTenant = true): Promise<void> {
        try {
            const prependedPath = prependTenant ? this.prependTenant(path) : path;
            this.logger.logMethodStart(this, `Merging document at path: ${prependedPath}, ID: ${id}`, obj);
            return this.angularFirestore.collection(prependedPath).doc(id).set(
                obj, {merge: true}
            );
        } catch (error) {
            this.logger.logError(this, `Failed to merge document at path: ${this.prependTenant(path)}, ID: ${id}`, error);
            throw error;
        }
    }

    getDocument<T>(path: dbPaths | string, docId: string, prependTenant = true): Observable<T> {
        this.logger.logMethodStart(this, `Getting document at path: ${path}, ID: ${docId}`);
        return this.angularFirestore.collection(prependTenant ? this.prependTenant(path) : path).doc<T>(docId).get()
            .pipe(takeWhile(() => this.authService.isLoggedIn$.getValue()))
            .pipe(map(x => {
                return {...x.data(), docId: x.id};
            }));
    }

    getDocumentConnectedObservable<T>(path: dbPaths | string, docId: string, prependTenant = true): Observable<T> {
        this.logger.logMethodStart(this, `Getting document at path: ${path}, ID: ${docId}`);
        return this.angularFirestore.collection(prependTenant ? this.prependTenant(path) : path).doc<T>(docId)
            .snapshotChanges()
            .pipe(
                takeWhile(() => this.authService.isLoggedIn$.getValue()),
                switchMap(snapshot => {
                    // Überprüfen, ob das Dokument existiert und Daten enthält
                    if (!snapshot.payload.exists) {
                        this.logger.logWarning(this, `Document not found at path: ${path}, ID: ${docId}`);
                        this.handleDocumentNotFound();
                        return EMPTY;
                    }
                    
                    const data = snapshot.payload.data() as T;
                    // Überprüfen, ob das Dokument Daten außer der ID enthält
                    if (!data || Object.keys(data).length === 0) {
                        this.logger.logWarning(this, `Document exists but contains no data at path: ${path}, ID: ${docId}`);
                        this.handleDocumentNotFound();
                        return EMPTY;
                    }
                    
                    // Dokument existiert und hat Daten, geben wir es zurück
                    return of({
                        ...data,
                        docId: snapshot.payload.id
                    } as T);
                }),
                catchError(error => {
                    this.logger.logError(this, `Error fetching document at path: ${path}, ID: ${docId}`, error);
                    this.handleDocumentNotFound();
                    return EMPTY;
                })
            );
    }

    getDocumentConnectedObservableWithoutTenant<T>(path: string, docId: string): Observable<T> {
        this.logger.logMethodStart(this, `Getting document at path: ${path}, ID: ${docId}`);
        return this.angularFirestore.collection(path).doc<T>(docId)
            .snapshotChanges()
            .pipe(
                takeWhile(() => this.authService.isLoggedIn$.getValue()),
                switchMap(snapshot => {
                    // Überprüfen, ob das Dokument existiert und Daten enthält
                    if (!snapshot.payload.exists) {
                        this.logger.logWarning(this, `Document not found at path: ${path}, ID: ${docId}`);
                        this.handleDocumentNotFound();
                        return EMPTY;
                    }
                    
                    const data = snapshot.payload.data() as T;
                    // Überprüfen, ob das Dokument Daten außer der ID enthält
                    if (!data || Object.keys(data).length === 0) {
                        this.logger.logWarning(this, `Document exists but contains no data at path: ${path}, ID: ${docId}`);
                        this.handleDocumentNotFound();
                        return EMPTY;
                    }
                    
                    // Dokument existiert und hat Daten, geben wir es zurück
                    return of({
                        ...data,
                        docId: snapshot.payload.id
                    } as T);
                }),
                catchError(error => {
                    this.logger.logError(this, `Error fetching document at path: ${path}, ID: ${docId}`, error);
                    this.handleDocumentNotFound();
                    return EMPTY;
                })
            );
    }

    stateChanges<T>(path: string,
                    where?: Query[],
                    order?: Order[],
                    // limit: number = 20, // Anforderung Kunde: Es sollen alle Daten geladen werden.
                    documentChangeType: DocumentChangeType[] = ["added", "removed", "modified"]
    ): Observable<T[]> {
        return this.angularFirestore
            .collection<T>(this.prependTenant(path), ref => {
                let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                if (where && where.length > 0) {
                    where.forEach(q => {
                        query = query.where(q.fieldPath, q.filterOperator, q.value);
                    });
                }
                if (order && order.length > 0) {
                    order.forEach(o => {
                        query = query.orderBy(o.fieldPath, o.directionStr);
                    });
                }
                // if (limit) { query = query.limit(limit); }
                return query;
            })
            .stateChanges(documentChangeType)
            .pipe(map(x => {
                return x.filter(y => documentChangeType.some(dt => dt === y.type)).map(y => {
                    return {...y.payload.doc.data(), docId: y.payload.doc.id
                    };
                });
            }))
            .pipe(takeWhile(() => this.authService.isLoggedIn$.getValue()));
    }

    async deleteDocument(path: dbPaths | string, id: string, prependTenant = true) {
        try {
            const prependedPath = prependTenant ? this.prependTenant(path) : path;
            this.logger.logMethodStart(this, `Deleting document at path: ${prependedPath}, ID: ${id}`);
            await this.deleteVisits(path, id);
            await this.angularFirestore.collection(prependedPath).doc(id).delete();
            this.logger.log(this, `Document deleted successfully`);
        } catch (error) {
            this.logger.logError(this, `Failed to delete document at path: ${this.prependTenant(path)}, ID: ${id}`, error);
            throw error;
        }
    }

    async deleteVisits(path: string, docId: string) {
        const docRef = this.angularFirestore.collection(this.prependTenant(path)).doc(docId).ref;

        const visitsCollectionRef = docRef.collection(Globals.visitsCollectionName);
        const visitsSnapshot = await visitsCollectionRef.get();

        for (const visitDoc of visitsSnapshot.docs) {
            await visitDoc.ref.delete();
        }
    }

    count<T>(path: string, where?: Query[]): Observable<number> {
        try {
            const prependedPath = this.prependTenant(path);
            this.logger.logMethodStart(this, `Counting documents at path: ${prependedPath}`, { where });
            
            const collection = this.angularFirestore
                .collection<T>(prependedPath, ref => {
                    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                    if (where && where.length > 0) {
                        where.forEach(q => {
                            if (q && q.fieldPath && q.value !== undefined) {
                                query = query.where(q.fieldPath, q.filterOperator, q.value);
                            }
                        });
                    }
                    return query;
                });
                
            return collection.get().pipe(
                map(snapshot => {
                    const count = snapshot.size;
                    this.logger.log(this, `Document count for ${prependedPath}: ${count}`);
                    return count;
                }),
                takeWhile(() => this.authService.isLoggedIn$.getValue()),
                catchError(error => {
                    this.logger.logError(this, `Failed to count documents at path: ${prependedPath}`, error);
                    throw error;
                })
            );
        } catch (error) {
            this.logger.logError(this, `Error setting up count operation for path: ${path}`, error);
            return of(0);
        }
    }

    query<T>(
        path: string, 
        where?: Query[], 
        order?: Order[], 
        limit?: number, 
        prependTenant = true
    ): Observable<T[]> {
        try {
            const prependedPath = prependTenant ? this.prependTenant(path) : path;
            this.logger.logMethodStart(this, `Querying collection at path: ${prependedPath}`, { where, order, limit });
            
            // Check if there are any 'in' queries with more than 30 items
            if (where && where.length > 0) {
                const largeInQueries = where.filter(q => 
                    q && q.filterOperator === 'in' && 
                    Array.isArray(q.value) && 
                    q.value.length > 30
                );
                
                // If we have large 'in' queries, handle them specially
                if (largeInQueries.length > 0) {
                    return this.handleLargeInQueries<T>(prependedPath, where, order, limit);
                }
            }
            
            // Original query implementation for standard cases
            return this.angularFirestore
                .collection<T>(prependedPath, ref => {
                    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                    if (where && where.length > 0) {
                        where.forEach(q => {
                            if (q && q.fieldPath && q.value !== undefined) {
                                query = query.where(q.fieldPath, q.filterOperator, q.value);
                            }
                        });
                    }
                    if (order && order.length > 0) {
                        order.forEach(o => {
                            query = query.orderBy(o.fieldPath, o.directionStr);
                        });
                    }
                    if (limit) {
                        query = query.limit(limit);
                    }
                    return query;
                })
                .valueChanges({idField: 'docId'})
                .pipe(takeWhile(() => this.authService.isLoggedIn$.getValue()))
                .pipe(
                    map(results => {
                        this.logger.log(this, `Query ${prependedPath} returned ${results.length} results`);
                        return results;
                    }),
                    catchError(error => {
                        this.logger.logError(this, `Query ${prependedPath} failed for path: ${prependedPath}`, error);
                        throw error;
                    })
                );
        } catch (error) {
            this.logger.logError(this, `Failed to setup query for path: ${path}`, error);
            throw error;
        }
    }

    /**
     * Handles queries with 'in' filters that have more than 30 elements
     * by splitting them into multiple queries and combining the results
     */
    private handleLargeInQueries<T>(
        path: string, 
        where: Query[], 
        order?: Order[], 
        limit?: number
    ): Observable<T[]> {
        try {
            this.logger.log(this, `Handling large 'in' queries for path: ${path}`);
            
            // Find the 'in' queries that need to be chunked
            const largeInQueries = where.filter(q => 
                q && q.filterOperator === 'in' && 
                Array.isArray(q.value) && 
                q.value.length > 30
            );
            
            // Get other standard queries
            const standardQueries = where.filter(q => 
                !largeInQueries.some(lq => lq.fieldPath === q.fieldPath && q.filterOperator === 'in')
            );
            
            // If we have multiple large 'in' queries, we'll need to handle them separately
            // For now, we'll just handle the first one as it's the most common case
            const largeInQuery = largeInQueries[0];
            
            // Split the values into chunks of max 30 items
            const valueChunks = this.chunkArray(largeInQuery.value, 30);
            
            // Create separate queries for each chunk
            const chunkQueries: Observable<T[]>[] = valueChunks.map(chunk => {
                // Create a new query object for this chunk
                const chunkQuery: Query = {
                    fieldPath: largeInQuery.fieldPath,
                    filterOperator: largeInQuery.filterOperator,
                    value: chunk
                };
                
                // Combine with other standard queries
                const combinedQueries = [...standardQueries, chunkQuery];
                
                // Execute the query for this chunk
                return this.angularFirestore
                    .collection<T>(path, ref => {
                        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                        
                        // Apply all the query conditions
                        combinedQueries.forEach(q => {
                            if (q && q.fieldPath && q.value !== undefined) {
                                query = query.where(q.fieldPath, q.filterOperator, q.value);
                            }
                        });
                        
                        // Apply ordering if specified
                        if (order && order.length > 0) {
                            order.forEach(o => {
                                query = query.orderBy(o.fieldPath, o.directionStr);
                            });
                        }
                        
                        // Apply limit if specified and this is the first chunk
                        // Note: we can't apply limit to each chunk as it would give incorrect results
                        // Instead, we'll apply it after merging all results
                        
                        return query;
                    })
                    .valueChanges({idField: 'docId'})
                    .pipe(takeWhile(() => this.authService.isLoggedIn$.getValue()));
            });
            
            // If no chunk queries were created, return empty array
            if (chunkQueries.length === 0) {
                return of([]);
            }
            
            // Combine all the chunk query results
            return combineLatest(chunkQueries).pipe(
                map(chunkResults => {
                    // Flatten all chunk results into a single array
                    // Using a compatible alternative to .flat() for older ES versions
                    const allResults = this.flattenArray(chunkResults);
                    
                    // Remove duplicates (if any) based on docId
                    const uniqueResults = Array.from(
                        new Map(allResults.map(item => [item['docId'], item])).values()
                    );
                    
                    // Sort the combined results according to the specified order
                    let sortedResults = [...uniqueResults];
                    if (order && order.length > 0) {
                        sortedResults.sort((a, b) => {
                            // Apply each ordering criteria in sequence
                            for (const o of order) {
                                const fieldPath = o.fieldPath;
                                const direction = o.directionStr || 'asc';
                                
                                // Get the values to compare
                                const valueA = this.getNestedProperty(a, fieldPath);
                                const valueB = this.getNestedProperty(b, fieldPath);
                                
                                // Skip to next criteria if values are equal
                                if (valueA === valueB) {
                                    continue;
                                }
                                
                                // Handle null/undefined values (typically sorted first)
                                if (valueA === null || valueA === undefined) {
                                    return direction === 'asc' ? -1 : 1;
                                }
                                if (valueB === null || valueB === undefined) {
                                    return direction === 'asc' ? 1 : -1;
                                }
                                
                                // Compare based on value type
                                if (typeof valueA === 'string' && typeof valueB === 'string') {
                                    return direction === 'asc' 
                                        ? valueA.localeCompare(valueB) 
                                        : valueB.localeCompare(valueA);
                                } else {
                                    return direction === 'asc' 
                                        ? (valueA < valueB ? -1 : 1) 
                                        : (valueA < valueB ? 1 : -1);
                                }
                            }
                            
                            // All criteria matched exactly, items are equal
                            return 0;
                        });
                    }
                    
                    // Apply limit if specified
                    let finalResults = sortedResults;
                    if (limit && finalResults.length > limit) {
                        finalResults = finalResults.slice(0, limit);
                    }
                    
                    this.logger.log(this, `Large 'in' query returned ${finalResults.length} results from ${allResults.length} total results`);
                    return finalResults;
                }),
                catchError(error => {
                    this.logger.logError(this, `Large 'in' query failed for path: ${path}`, error);
                    throw error;
                })
            );
        } catch (error) {
            this.logger.logError(this, `Failed to handle large 'in' query for path: ${path}`, error);
            throw error;
        }
    }
    
    /**
     * Gets a nested property from an object using a dot-notation path
     */
    private getNestedProperty(obj: any, path: string): any {
        if (!obj || !path) {
            return undefined;
        }
        
        const pathParts = path.split('.');
        let current = obj;
        
        for (const part of pathParts) {
            if (current === null || current === undefined) {
                return undefined;
            }
            current = current[part];
        }
        
        return current;
    }
    
    /**
     * Splits an array into chunks of a specified size
     */
    private chunkArray<T>(array: T[], chunkSize: number): T[][] {
        const chunks: T[][] = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    getCollection<T>(path: dbPaths | string, prependTenant = true): Observable<T[]> {
        return this.angularFirestore.collection<T>(prependTenant ? this.prependTenant(path) : path).valueChanges({idField: 'docId'})
            .pipe(takeUntil(this.authService.isLoggedIn$));
    }

    getCollectionChangesDocIdsWithoutTenant(path: string): Observable<string[]>{
        return this.angularFirestore.collection(path)
            .snapshotChanges()
            .pipe(map(
                (actions => {
                    return actions.map(action => {
                        return action.payload.doc.id;
                    });
                })
            ));
    }

    addValueToArray(id: string, path: dbPaths | string, key: string, arrayValue: any[]) {
        return this.angularFirestore.collection(this.prependTenant(path)).doc(id)
            .update({
                [key]: arrayUnion(...arrayValue)
            });
    }


    removeValueFromArray(id: string, path: string, key: string, arrayValue: any) {
        return this.angularFirestore.collection(this.prependTenant(path)).doc(id)
            .update({
                [key]: arrayRemove(arrayValue)
            });
    }

    getNewDocId() {
        return this.angularFirestore.createId();
    }

    private flattenArray<T>(arrays: T[][]): T[] {
        const result: T[] = [];
        for (const array of arrays) {
            result.push(...array);
        }
        return result;
    }

    /**
     * Query with pagination support
     * @returns Observable of PagedResult containing items, lastVisible cursor, and hasMore flag
     * 
     * How to use:
     * // First page
        this.firestoreService.queryPaged<YourType>(
            'yourCollection',
            yourWhereConditions,
            yourOrderingOptions,
            20 // pageSize
        ).subscribe(result => {
            this.items = result.items;
            this.lastVisible = result.lastVisible; // Store for next page
            this.hasMoreItems = result.hasMore;
        });

        // Load next page when needed
        loadNextPage() {
        if (this.lastVisible && this.hasMoreItems) {
            this.firestoreService.queryPaged<YourType>(
                'yourCollection',
                yourWhereConditions,
                yourOrderingOptions,
                20,
                true, // prependTenant
                this.lastVisible // Pass the last document from previous page
            ).subscribe(result => {
            // Append new items to existing items
                this.items = [...this.items, ...result.items];
                this.lastVisible = result.lastVisible;
                this.hasMoreItems = result.hasMore;
            });
        }
        }
     * 
     * 
     */
    queryPaged<T>(
        path: dbPaths,
        where?: Query[], 
        order?: Order[], 
        pageSize: number = 20, 
        prependTenant = true,
        startAfter?: any // Document snapshot or field values to start after
    ): Observable<PagedResult<T>> {
        try {
            const prependedPath = prependTenant ? this.prependTenant(path) : path;
            this.logger.logMethodStart(this, `Querying paged collection at path: ${prependedPath}`, 
                { where, order, pageSize, hasStartAfter: !!startAfter });
            
            // Check if there are any 'in' queries with more than 30 items
            if (where && where.length > 0) {
                const largeInQueries = where.filter(q => 
                    q && q.filterOperator === 'in' && 
                    Array.isArray(q.value) && 
                    q.value.length > 30
                );
                
                // For large 'in' queries, we currently don't support pagination directly
                // You would need to modify handleLargeInQueries to support pagination
                if (largeInQueries.length > 0) {
                    this.logger.logWarning(this, 
                        `Pagination with large 'in' queries (>30 items) is not fully supported.`);
                    // Fall back to non-paged handling but with limit
                    return this.handleLargeInQueries<T>(prependedPath, where, order, pageSize)
                        .pipe(
                            map(items => ({
                                items,
                                lastVisible: items.length > 0 ? items[items.length - 1] : null,
                                hasMore: items.length >= pageSize
                            }))
                        );
                }
            }
            
            // For pagination, we need to use snapshotChanges instead of valueChanges
            // to access the document snapshots for cursor-based pagination
            return this.angularFirestore
                .collection<T>(prependedPath, ref => {
                    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                    
                    // Apply where clauses
                    if (where && where.length > 0) {
                        where.forEach(q => {
                            if (q && q.fieldPath && q.value !== undefined) {
                                query = query.where(q.fieldPath, q.filterOperator, q.value);
                            }
                        });
                    }
                    
                    // Apply ordering - required for startAfter to work
                    if (order && order.length > 0) {
                        order.forEach(o => {
                            query = query.orderBy(o.fieldPath, o.directionStr);
                        });
                    } else if (startAfter) {
                        // If using startAfter, at least one orderBy is required
                        query = query.orderBy('docId');
                    }
                    
                    // Apply startAfter if provided
                    if (startAfter) {
                        query = query.startAfter(startAfter);
                    }
                    
                    // Always apply a limit for pagination
                    query = query.limit(pageSize + 1); // +1 to check if there are more items
                    
                    return query;
                })
                .snapshotChanges()
                .pipe(
                    takeWhile(() => this.authService.isLoggedIn$.getValue()),
                    map(snapshots => {
                        // Convert the snapshots to objects with docId
                        const items = snapshots.map(snapshot => {
                            return {
                                ...snapshot.payload.doc.data(),
                                docId: snapshot.payload.doc.id
                            } as T;
                        });
                        
                        // Check if there are more items
                        const hasMore = items.length > pageSize;
                        
                        // Remove the extra item we added to check if there are more
                        const pageItems = hasMore ? items.slice(0, pageSize) : items;
                        
                        // Get the last document snapshot for next pagination
                        const lastVisible = pageItems.length > 0 
                            ? snapshots[pageItems.length - 1].payload.doc 
                            : null;
                        
                        this.logger.log(this, 
                            `Paged query ${prependedPath} returned ${pageItems.length} results, hasMore: ${hasMore}`);
                        
                        return {
                            items: pageItems,
                            lastVisible,
                            hasMore
                        };
                    }),
                    catchError(error => {
                        this.logger.logError(this, `Paged query failed for path: ${prependedPath}`, error);
                        throw error;
                    })
                );
        } catch (error) {
            this.logger.logError(this, `Failed to setup paged query for path: ${path}`, error);
            throw error;
        }
    }

    private handleDocumentNotFound(): void {
        this.logger.log(this, 'Redirecting to 404 page due to document not found');
        this.router.navigate(['/404']);
    }

    /**
     * Holt ein Dokument aus Firestore und gibt ein Observable zurück.
     * Im Gegensatz zu getDocumentConnectedObservable leitet diese Methode NICHT zur 404-Seite weiter,
     * wenn das Dokument nicht existiert, sondern gibt null zurück und hält das Observable aktiv.
     * 
     * Speziell für die Verwendung mit visits-Kollektionen, bei denen es normal ist,
     * dass Dokumente möglicherweise nicht existieren.
     */
    getDocumentConnectedObservableIfExistOtherwiseReturnNullAndStayObserved<T>(
        path: dbPaths | string, 
        docId: string, 
        prependTenant = true
    ): Observable<T | null> {
        this.logger.logMethodStart(this, `Getting document (if exists) at path: ${path}, ID: ${docId}`);
        return this.angularFirestore.collection(prependTenant ? this.prependTenant(path) : path).doc<T>(docId)
            .snapshotChanges()
            .pipe(
                takeWhile(() => this.authService.isLoggedIn$.getValue()),
                map(snapshot => {
                    // Wenn das Dokument nicht existiert, gib null zurück aber halte das Observable aktiv
                    if (!snapshot.payload.exists) {
                        this.logger.log(this, `Document not found at path: ${path}, ID: ${docId}, returning null`);
                        return null;
                    }
                    
                    const data = snapshot.payload.data() as T;
                    // Wenn das Dokument keine Daten außer der ID hat, gib null zurück
                    if (!data || Object.keys(data).length === 0) {
                        this.logger.log(this, `Document exists but contains no data at path: ${path}, ID: ${docId}, returning null`);
                        return null;
                    }
                    
                    // Dokument existiert und hat Daten, geben wir es zurück
                    return {
                        ...data,
                        docId: snapshot.payload.id
                    } as T;
                }),
                catchError(error => {
                    this.logger.logError(this, `Error fetching document at path: ${path}, ID: ${docId}`, error);
                    // Bei Fehlern gib null zurück, aber halte das Observable aktiv
                    return of(null);
                })
            );
    }
}
