import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {from, Observable} from 'rxjs';
import { UserService } from '../services/user.service';
import {NavigationService} from '../services/navigation.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth-service.service';
import {AppInitializationService} from "../services/app-initialization.service";

@Injectable({
  providedIn: 'root'
})
export class AuthzGuard implements CanActivate {

  constructor(
      private user: UserService,
        private appInitializationService: AppInitializationService,
      private nav: NavigationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return from(this.appInitializationService.Initialize()).pipe(switchMap(() => {
            return this.user.currentUserIsLoggedInAndValid.pipe(tap(async isValid => {
                if (!isValid) {
                    await this.nav.navigateToLogin();
                    return false;
                }

      if (route == null || route.data == null || route.data.role == null) {
          return true;
      }

              const user = this.user.currentUser$.getValue();
              const res =  (route.data.role == null ||
                  (user != null && user.roles != null
                      && user.roles.some(ro => route.data.role.includes(ro))));

              if(res === false){
                  await this.nav.navigateTo403();
              }
              return res;
          }));
      }));


  }
}

