import { Injectable } from '@angular/core';
import {ActivatedRoute, CanActivate, Route, Router} from '@angular/router';
import { UserService } from '../services/user.service';
import {from, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {AuthService} from "../services/auth-service.service";
import {NavigationService} from "../services/navigation.service";
import {routes} from "../app-routing.module";
import {AlertController, ModalController} from "@ionic/angular";
import {InvitationService} from "../services/invitation.service";

@Injectable({
  providedIn: 'root'
})
export class InvitedGuard implements CanActivate {

  constructor(private authService: AuthService,
              private nav: NavigationService,
              private alertController: AlertController,
              private invitationService: InvitationService,
              private userService: UserService, private router: Router, private route: ActivatedRoute) {}

  canActivate(): Observable<boolean> {

      return from(this.authService.Init()).pipe(switchMap(() => {
          return this.authService.isLoggedIn().pipe(tap(async loggedIn => {
              if (!loggedIn) {
                  this.route.queryParams.subscribe(params => {
                      // Get the 'token' query parameter

                      console.log('Token:', params['token']);
                  });

                  if(this.route.snapshot.params.path.includes('token')){
                      const token = new URLSearchParams(new URL(this.router.url).search).get('token');
                      alert('token: ' + token);
                      this.invitationService.verifyCustomToken(token)
                          .then(async (result) => {
                              if (!result) {
                                  const alert = await this.alertController.create({
                                      header: 'Registrierung abschließen',
                                      message: `Ihr Link ist ungültig oder abgelaufen. Möchten Sie einen neuen Link erhalten?`,
                                      buttons: [
                                          { text: 'Nein' },
                                          {
                                              text: 'Ja',
                                              handler: async () => {

                                              }
                                          }
                                      ]
                                  });
                                  await alert.present();
                              }
                          });
                  }
              }
              return true;
          }));
      }));





    return this.userService.currentUser$.pipe(
      map(user => {
        if (!user || user.invited === true) {
          console.log('User is not allowed to access this page');
          this.router.navigate(['/start']);
          return false;
        }
        return true;
      })
    );
  }
}
