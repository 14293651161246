import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FileService} from './file.service';
import {dbPaths} from './globals';
import {FeedService} from './feed.service';
import {UserService} from './user.service';
import {Document} from '../models/document';
import {FilePicker} from '@capawesome/capacitor-file-picker';
import {ToastController} from '@ionic/angular';
import {FirestoreProxyService, Order} from './firestore-proxy.service';
import {first} from 'rxjs/operators';
import {UtilService} from "./util.service";

@Injectable({
    providedIn: 'root'
})
export class LogoService {
    private logoUrl = new BehaviorSubject<string>(null);

    logoUrl$ = this.logoUrl.asObservable();

    constructor(
        private fileService: FileService,
        private feedService: FeedService,
        private userService: UserService,
        private firestore: FirestoreProxyService,
        private toastController: ToastController
    ) {
    }

    fetchLogoUrl() {
        const order: Order = {fieldPath: 'time', directionStr: 'desc'};
        this.firestore.query(dbPaths.settingsPath + '/logo/feed', [], [order], 1).pipe(first()).subscribe((docs: any[]) => {
            if (docs.length > 0) {
                const newestLogo = docs[0];
                this.logoUrl.next(newestLogo?.url);
            }
        });
    }

    async uploadLogo() {
        const result = await FilePicker.pickFiles({
            limit: 1
        });

        const file = result.files[0];
        if (!file.name.toLowerCase().endsWith('.png') && !file.name.toLowerCase().endsWith('.jpg')) {
            const toast = await this.toastController.create({
                header: 'Fehler',
                message: 'Nur Bilder sind erlaubt.',
                duration: 5000,
                color: 'danger'
            });
            await toast.present();
            return;
        }

        const prom = await this.fileService.uploadBlob(file.blob,
            dbPaths.settingsPath + '/' + 'logo', file.name);
        console.log("my logo",prom.url)
        const document: Document = {
            changed: 0, published: false,
            time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
            createdBy: this.userService.getCurrentUserUid(),
            referenceItemId: file.name,
            name: 'logo.png',
            content: file.name,
            url:prom.url,
            archived: false,
            deleted: false,
            orderIndex: UtilService.getCurrentUtcUnixTimestampAsNumber()
        };

        await this.feedService.createOrUpdateMessage(dbPaths.settingsPath, 'logo', document);

        const toast = await this.toastController.create({
            header: 'Upload',
            message: 'Logo erfolgreich hochgeladen',
            duration: 5000,
            color: 'success'
        });
        await toast.present();

        this.fetchLogoUrl();
    }
}
