import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable, Subscription, switchMap, map} from "rxjs";
import {
  Favorit,
  SettingsPaths,
  User,
  UserAccountSettings,
  UserArbeitsgruppenSettings,
  UserAuditSettings,
  UserBaseSettings,
  UserNotificationSettings,
  UserSystemWorkflowSettings
} from "../models/user";
import {FirestoreProxyService, Query} from "./firestore-proxy.service";
import {LoggingService} from "./logging.service";
import {dbPaths} from "./globals";
import {UtilService} from "./util.service";
import {tap} from "rxjs/operators";
import {FirestoreProxyCacheService} from "./firestore-proxy-cache.service";

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  userAccountSettings: BehaviorSubject<UserAccountSettings> = new BehaviorSubject<UserAccountSettings>(undefined);
  userSystemWorkflowSettings: BehaviorSubject<UserSystemWorkflowSettings> = new BehaviorSubject<UserSystemWorkflowSettings>(undefined);
  userNotificationSettings: BehaviorSubject<UserNotificationSettings> = new BehaviorSubject<UserNotificationSettings>(undefined);
  userArbeitsgruppenSettings: BehaviorSubject<UserArbeitsgruppenSettings> = new BehaviorSubject<UserArbeitsgruppenSettings>(undefined);

  currentUserDocId: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  private dbUserSettingsLoadSubscription = new Subscription();

  constructor(
      private firestoreProxyService: FirestoreProxyService,
      private firestoreProxyCacheService: FirestoreProxyCacheService,
      private loggingService: LoggingService,
      private utilService: UtilService,
  ) {
  }

  loadSubscribe(user: User): void {
    // If the user hasn't changed, no need to re-subscribe
  if (this.currentUserDocId.getValue() === user.docId) {
    return;
  }
  
  this.currentUserDocId.next(user.docId);

  // Unsubscribe from all previous subscriptions
  this.dbUserSettingsLoadSubscription.unsubscribe();
  // Create a new Subscription container
  this.dbUserSettingsLoadSubscription = new Subscription();

    this.dbUserSettingsLoadSubscription.add(this.loadUserSetting<UserAccountSettings>(user, SettingsPaths.UserAccountSettings)
        .subscribe(settings => {
          if(settings && settings.userDocId == user.docId) {
            this.userAccountSettings.next(settings)
          }else {
            this.userAccountSettings.next(null)
            this.loggingService.log(this, 'loadSubscribe - userAccountSettings is null', {settings, user});
          }
        }));

    this.dbUserSettingsLoadSubscription.add(this.loadUserSetting<UserSystemWorkflowSettings>(user, SettingsPaths.UserSystemWorkflowSettings)
        .subscribe(settings => {
          if(settings && settings.userDocId == user.docId) {
            this.userSystemWorkflowSettings.next(settings)
          }else {
            this.userSystemWorkflowSettings.next(null)
            this.loggingService.log(this, 'loadSubscribe - userSystemSettings is null', {settings, user});
          }
        }));

    this.dbUserSettingsLoadSubscription.add(this.loadUserSetting<UserNotificationSettings>(user, SettingsPaths.UserNotificationSettings)
        .subscribe(settings => {
          if(settings && settings.userDocId == user.docId) {
            this.userNotificationSettings.next(settings)
          }else {
            this.userNotificationSettings.next(null)
            this.loggingService.log(this, 'loadSubscribe - userNotificationSettings is null', {settings, user});
          }
        }));

    this.dbUserSettingsLoadSubscription.add(this.loadUserSetting<UserArbeitsgruppenSettings>(user, SettingsPaths.UserArbeitsgruppenSettings)
        .subscribe(settings => {
          this.loggingService.log(this, 'loadSubscribe - userArbeitsgruppenSettings', {settings, user});
          if(settings && settings.userDocId == user.docId) {
            this.userArbeitsgruppenSettings.next(settings)
          }else {
            this.userArbeitsgruppenSettings.next({userDocId: user.docId} as UserArbeitsgruppenSettings)
            this.loggingService.log(this, 'loadSubscribe - userArbeitsgruppenSettings is null', {settings, user});
          }
        }));

  }

  public async getUserSettings<T>(user: User, settingsType: SettingsPaths): Promise<T> {
    // TOD:AR: Observe & Cache
    const settings = await firstValueFrom(this.loadUserSetting<T>(user, settingsType));
    return settings;
  }

  public loadUserSetting<T>(user: User, settingsType: SettingsPaths): Observable<T> {
    this.loggingService.logMethodStart(this, 'loadUserSetting');
    return this.firestoreProxyCacheService.getDocumentObservableIfExistOtherwiseReturnNullAndStayObserved<T>(
        `${dbPaths.usersPath}/${user.docId}/${dbPaths.settingsPath}`, settingsType.toString());
  }

  public querySettingsForAllUsers<T extends UserBaseSettings>(query: Query): Observable<T[]>{
    this.loggingService.logMethodStart(this, 'querySettingsForAllUsers');
    return this.firestoreProxyCacheService.query<User>(dbPaths.usersPath, [{
      fieldPath: 'uid',
      filterOperator: '!=',
      value: ''
    },{
      fieldPath: 'accountFreigegeben',
      filterOperator: "==",
      value: true
    }, {
      fieldPath: 'hidden',
      filterOperator: "==",
      value: false
    }]).pipe(switchMap(([user]) =>
        this.firestoreProxyCacheService.query<T>(`${dbPaths.usersPath}/${user.docId}/${dbPaths.settingsPath}`, [query])
    ))
  }

  async mergeUserSettings<T extends UserBaseSettings>(userDocId: string, settings: Partial<T>, settingsPath: SettingsPaths) {
    try {
      settings.userDocId = userDocId;
      await this.firestoreProxyService.mergeDocument(settingsPath, `${dbPaths.usersPath}/${userDocId}/${dbPaths.settingsPath}`, settings);
    } catch (error) {
      this.loggingService.logError(this, 'Failed to update UserSettings', error);
      throw error;
    }
  }

  async setAuditTag(user: User) {
    const userAuditSettings: UserAuditSettings = {
      userDocId: user.docId,
      lastLogin: UtilService.getCurrentUtcUnixTimestampAsNumber(),
      appVersion: await this.utilService.getAppVersion()
    }
    await this.mergeUserSettings(user.docId, userAuditSettings, SettingsPaths.UserAuditSettings);
  }

  async toggleFavorite(userDocId: string, favorite: Favorit) {
    const userAccountSettings = this.userAccountSettings.getValue();
    if(userAccountSettings && userAccountSettings.userDocId == userDocId && userAccountSettings.favorites?.some(fav => fav.path === favorite.path)) {
      await this.mergeUserSettings(userDocId, {
        favorites: userAccountSettings.favorites.filter(fav => fav.path !== favorite.path)
      } as UserAccountSettings, SettingsPaths.UserAccountSettings);
    }else{
      await this.mergeUserSettings(userDocId, {
        favorites: [...(userAccountSettings?.favorites ?? []), favorite]
      } as UserAccountSettings, SettingsPaths.UserAccountSettings);
    }
  }

  async updateFcmToken(userDocId: string, fcmToken: string) {
    if (fcmToken === undefined) {
      fcmToken = '';
    }
    const notificationSettings = this.userNotificationSettings.getValue();
    
    if (!fcmToken) {
      // Empty token means we're removing the token (logout case)
      // For now, we don't know which device is logging out, so we keep the tokens
      // The tokens will be properly managed when we implement device identification
      return;
    }
    
    // Check if we already have this token
    if (notificationSettings && notificationSettings.userDocId == userDocId) {
      const existingTokens = notificationSettings.fcmTokens || [];
      
      // If token already exists, don't update
      if (existingTokens.includes(fcmToken)) {
        return;
      }
      
      // Add the new token to the existing tokens
      await this.mergeUserSettings(userDocId, {
        fcmTokens: [...existingTokens, fcmToken]
      } as UserNotificationSettings, SettingsPaths.UserNotificationSettings);
    } else {
      // No existing settings, create new with single token
      await this.mergeUserSettings(userDocId, {
        fcmTokens: [fcmToken]
      } as UserNotificationSettings, SettingsPaths.UserNotificationSettings);
    }
  }

  async removeFcmToken(userDocId: string, fcmToken: string) {
    if (!fcmToken) {
      return;
    }
    
    const notificationSettings = this.userNotificationSettings.getValue();
    if (notificationSettings && notificationSettings.userDocId == userDocId && notificationSettings.fcmTokens) {
      const existingTokens = notificationSettings.fcmTokens || [];
      
      // Remove the token
      if (existingTokens.includes(fcmToken)) {
        await this.mergeUserSettings(userDocId, {
          fcmTokens: existingTokens.filter(token => token !== fcmToken)
        } as UserNotificationSettings, SettingsPaths.UserNotificationSettings);
      }
    }
  }

  async toggleSilentFeedNotificationSubscription(userDocId: string, feedId: string) {
    const notificationSettings = this.userNotificationSettings.getValue();
    if(notificationSettings && notificationSettings.userDocId == userDocId && notificationSettings.excludedNotificationsFeedIds?.includes(feedId)) {
      await this.mergeUserSettings(userDocId, {
        excludedNotificationsFeedIds: notificationSettings.excludedNotificationsFeedIds.filter(id => id !== feedId)
      } as UserNotificationSettings, SettingsPaths.UserNotificationSettings)
    }else{
      await this.mergeUserSettings(userDocId, {
        excludedNotificationsFeedIds: [...(notificationSettings?.excludedNotificationsFeedIds ?? []), feedId]
      } as UserNotificationSettings, SettingsPaths.UserNotificationSettings)
    }
  }

  async hasFeedNotificationSubscriptionEnabled(userDocId: string, feedId: string): Promise<boolean> {
    const notificationSettings = this.userNotificationSettings.getValue();
    return !(notificationSettings && notificationSettings.userDocId == userDocId && notificationSettings?.excludedNotificationsFeedIds?.includes(feedId));
  }


  async removeFromImplicitExcludedArbeitsgruppe(userDocId: string, arbeitsgruppeId: number) {
    await this.mergeUserSettings<UserArbeitsgruppenSettings>(userDocId, {
      arbeitsgruppenImplicitExcluded: this.userArbeitsgruppenSettings.getValue()?.arbeitsgruppenImplicitExcluded.filter(id => id !== arbeitsgruppeId)
    }, SettingsPaths.UserArbeitsgruppenSettings);
  }

  async addToImplicitExcludedArbeitsgruppe(userDocId: string, arbeitsgruppeId: number) {
    await this.mergeUserSettings<UserArbeitsgruppenSettings>(userDocId, {
      arbeitsgruppenImplicitExcluded: [...(this.userArbeitsgruppenSettings.getValue()?.arbeitsgruppenImplicitExcluded ?? []), arbeitsgruppeId]
    }, SettingsPaths.UserArbeitsgruppenSettings);
  }

  async addToExplicitIncludedArbeitsgruppe(userDocId: string, arbeitsgruppeId: number) {
    await this.mergeUserSettings<UserArbeitsgruppenSettings>(userDocId, {
      arbeitsgruppenExplicitIncluded: [...(this.userArbeitsgruppenSettings.getValue()?.arbeitsgruppenExplicitIncluded ?? []), arbeitsgruppeId]
    }, SettingsPaths.UserArbeitsgruppenSettings)
  }

  async removeFromExplicitIncludedArbeitsgruppe(userDocId: string, arbeitsgruppeId: number) {
    await this.mergeUserSettings<UserArbeitsgruppenSettings>(userDocId, {
      arbeitsgruppenExplicitIncluded: this.userArbeitsgruppenSettings.getValue()?.arbeitsgruppenExplicitIncluded.filter(id => id !== arbeitsgruppeId)
    }, SettingsPaths.UserArbeitsgruppenSettings)
  }
}
