import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChatService} from '../../services/chat.service';
import {ActivatedRoute} from '@angular/router';
import {firstValueFrom, Observable, Subject, Subscription} from 'rxjs';
import {Message} from '../../models/message';
import {UserService} from '../../services/user.service';
import {NavigationService} from '../../services/navigation.service';
import {AlertController, IonContent, IonInput, IonItemSliding, ModalController, Platform} from '@ionic/angular';
import {ChatFeed} from '../../models/chatFeed';
import {Keyboard} from '@capacitor/keyboard';
import {PictureService} from '../../services/picture.service';
import {ChatMessage} from '../../models/chatMessage';
import {map} from 'rxjs/operators';
import {VisitService} from '../../services/visit.service';
import {UserSettingsService} from "../../services/user-settings.service";
import {dbPaths, Globals} from '../../services/globals';
import {environment} from "../../../brand/environments/environment";
import {TenantService} from "../../services/tenant.service";
import {LoggingService} from "../../services/logging.service";
import {UtilService} from '../../services/util.service';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit, OnDestroy {
  chatFotosEnabled: boolean = false;
  protected iAmOwner: boolean;
  public contextTitle: string;
  protected showNotificationBell = true; // false; // nur für bogk
  private isVisitAlreadySet = false;


  constructor(
      private chatService: ChatService,
      private route: ActivatedRoute,
      private nav: NavigationService,
      public userService: UserService,
      private userSettingsService: UserSettingsService,
      private alertController: AlertController,
      private platform: Platform,
      private pictureService: PictureService,
      private modalController: ModalController,
      private visitService: VisitService,
      private tenantService: TenantService,
      private loggingService: LoggingService,
  ) { }

  @ViewChild('content') content: IonContent;
  @ViewChild('slidingItem') slidingItem: IonItemSliding;
  @ViewChild('txtChat') txtChat: IonInput;
  @ViewChild('chatTitleInput') chatTitleInput: IonInput;
  @Input() chatId: string;
  @Input() isModal: false;
  @Input() currentUrl: string;
  @Input() title: string;
  chatFeed$: Observable<ChatFeed>;
  message: string;
  isArbeitsgruppenChat: boolean;
  isSupportChat: boolean;
  notificationsEnabled = true;
  subscription: Subscription = new Subscription();
  messagesLoaded = false;
  messages: ChatMessage[] = [];
  eventsSubject: Subject<void> = new Subject<void>();
  msgBoxDisabled: boolean | string;
  isEditMode: boolean = false;
  chatFeed!: ChatFeed;
  editableChatName: string = '';
  chatParticipants: string[] = [];
  isLoading: boolean = false;

  async ngOnInit() {
    try {
      if (this.isModal && this.chatId){
        this.loadMessages();
      }else if (this.route && this.route.params) {
        this.subscription.add(this.route.params.subscribe(x => {
          if (x?.id && this.chatId !== x.id) {
            this.chatId = x.id;
            this.loadMessages();
          }
        }));
      }
    } catch (error) {
      this.loggingService.logError(this, error);
    }finally {
      this.isLoading = false;
    }

    this.subscription.add(this.userService.currentUser$.subscribe(async user => {
      this.notificationsEnabled = await this.userSettingsService.hasFeedNotificationSubscriptionEnabled(user.docId, this.chatId)
    }));
  
    if (this.userService.isRedaktion$.getValue()) {
        this.showNotificationBell = true; // nur für bogk
    }

    if (this.platform.is('capacitor')) {
      Keyboard.addListener('keyboardDidShow', async () => {
        await this.scrollToBottom();
      });
    }
  }

  private async loadMessages() {
    this.chatFeed$ = this.chatService.getChat(this.chatId);
    this.subscription.add(this.chatFeed$.subscribe( async feed => {
      this.iAmOwner = !feed.isArbeitsgruppenFeed && feed.owner === this.userService.getCurrentUserUid();
      this.chatFeed = feed;
      this.editableChatName = feed.name || feed.defaultName;
      this.chatParticipants = feed?.participants || [];
      this.contextTitle = 'Chat: ' + feed.name || feed.defaultName;
      this.isArbeitsgruppenChat = feed.arbeitsgruppe !== undefined;
      this.isSupportChat = feed.isSupportChat;
    }));

    this.subscription.add(this.chatService.getMessages(this.chatId)
        .subscribe(async newMessages => {
          this.messages = newMessages;
          this.messagesLoaded = true;
          setTimeout(async () => {
            await this.scrollToBottom();
          }, 800);

        }));

    await this.setVisit();
  }

  async ionViewWillLeave() {
    await this.setVisit();
  }

  async ionViewDidEnter() {
    await this.scrollToBottom();
  }

  async setVisit() {
    if (!this.isVisitAlreadySet) {
      await this.visitService.setVisitForCurrentUser(dbPaths.chatsPath, this.chatId);
      this.isVisitAlreadySet = true;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public createPhoto() {
    if (!this.chatFotosEnabled) {
      return;
    }
    this.pictureService.takePhoto().then(async url => {
      console.log('createPhoto', url);

      await this.sendPhoto(url);

    }).catch(async error => {
      console.error('Error creating photo:', error);
      let header = 'Fehler';
      let message = 'Ein unerwarteter Fehler ist aufgetreten.';

      if (error.message === 'User denied access to camera') {
        header = 'Kamerazugriff verweigert';
        message = 'Bitte erlauben Sie den Zugriff auf die Kamera in den Geräteeinstellungen.';
      } else if (error.message === 'User denied access to photos') {
        header = 'Zugriff auf die Fotomediathek verweigert';
        message = 'Bitte erlauben Sie den Zugriff auf die Fotomediathek in den Geräteeinstellungen.';
      }

      const alert = await this.alertController.create({
        header,
        message,
        buttons: ['OK']
      });
      await alert.present();
    });
  }


  isCapacitor(): boolean {
    return this.platform.is('capacitor');
  }

  public async sendPhoto(url) {
    if (url != null && url.length > 0) {
      // Add photo message to UI immediately with pending flag
      const temporaryMessage: ChatMessage = {
        deleted: false,
        published: true,
        from: this.userService.getCurrentUserName(),
        content: '',
        picture: {
          url: url,
          format: 'image/jpeg'
        },
        time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
        createdBy: this.userService.getCurrentUserUid(),
        changed: UtilService.getCurrentUtcUnixTimestampAsNumber(),
        pending: true,
        docId: 'temp-photo-' + Date.now() // Temporary ID that will be replaced
      };
      
      // Add to local messages array
      this.messages = [...this.messages, temporaryMessage];
      await this.scrollToBottom(0);
      
      try {
        // Send to server
        const newMessageDocId = await this.chatService.sendPhoto(this.chatId, url);
        
        // Update the message in our local array once we get the response
        const messageIndex = this.messages.findIndex(m => m === temporaryMessage);
        if (messageIndex >= 0) {
          // Remove pending flag and update with real docId
          this.messages[messageIndex] = {
            ...temporaryMessage,
            pending: false,
            docId: newMessageDocId
          };
        }
        
        await this.setVisit();
      } catch (error) {
        this.loggingService.logError(this, 'Error sending photo', error);
        
        // Update UI to show error state
        const messageIndex = this.messages.findIndex(m => m === temporaryMessage);
        if (messageIndex >= 0) {
          this.messages[messageIndex] = {
            ...temporaryMessage,
            pending: false
          };
        }
      }
    }
    await this.scrollToBottom(300);
  }

  public async sendMessage($event: any) {
    $event.preventDefault()
    if (this.message.trim().length === 0){
      return
    }
    this.msgBoxDisabled = true;
    await this.txtChat.setFocus();
    if (this.isModal && !this.chatId) {
      // TODO : ACHTUNG! QUICKHACK! Wenn die chatId null ist, ist die Annahme,
      //  dass es sich um einen Modalen SupportChat handelt, weil dieser erst angelegt werden soll, wenn eine Message gesendet worden ist.
      this.chatId = await this.chatService.createSupportChat(this.currentUrl, this.title);
      this.loadMessages();
    }

    const messageText = this.message;
    this.message = '';
    await this.send(messageText);
    this.msgBoxDisabled = false;
    setTimeout(async () => {
      await this.txtChat.setFocus(); // the disabled=false doesn't apply until the next element update of
                                    // ionic so a timeout is need to make sure setFocus takes effect
                                   // after the input gets enabled
    },80)
  }

  private async send(messageText: string) {
    if (messageText != null && messageText.trim().length > 0) {
      // Add message to UI immediately with pending flag
      const temporaryMessage: ChatMessage = {
        deleted: false,
        published: true,
        from: this.userService.getCurrentUserName(),
        content: messageText,
        picture: null,
        time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
        createdBy: this.userService.getCurrentUserUid(),
        changed: UtilService.getCurrentUtcUnixTimestampAsNumber(),
        pending: true,
        docId: 'temp-' + Date.now() // Temporary ID that will be replaced
      };
      
      // Add to local messages array
      this.messages = [...this.messages, temporaryMessage];
      await this.scrollToBottom(0);
      
      try {
        // Send to server
        const newMessageDocId = await this.chatService.sendMessage(this.chatId, messageText);
        
        // Update the message in our local array once we get the response
        const messageIndex = this.messages.findIndex(m => m === temporaryMessage);
        if (messageIndex >= 0) {
          // Remove pending flag and update with real docId
          this.messages[messageIndex] = {
            ...temporaryMessage,
            pending: false,
            docId: newMessageDocId
          };
        }
        
        await this.setVisit();
      } catch (error) {
        this.loggingService.logError(this, 'Error sending message', error);
        
        // Update UI to show error state
        const messageIndex = this.messages.findIndex(m => m === temporaryMessage);
        if (messageIndex >= 0) {
          this.messages[messageIndex] = {
            ...temporaryMessage,
            content: temporaryMessage.content + ' (Sendefehler)',
            pending: false
          };
        }
      }
    }
    
    await this.scrollToBottom(300);
  }

  async scrollToBottom(duration: number = 0) {
    await this.content.scrollToBottom(duration);

  }

  isMyMessage(msg: Message) {
    this.loggingService.logWarning(this, 'TODO: refactor me!');
    return msg.createdBy === this.userService.getCurrentUserUid();
  }

  public isSupportChatAsObservable(): Observable<boolean> {
    return this.chatFeed$.pipe(map(feed => feed.isSupportChat));
  }

  public isArbeitsgruppenFeed(): Observable<boolean> {
    return this.chatFeed$.pipe(map(feed => feed.isArbeitsgruppenFeed));
  }
  async openDetails() {
    if (this.isModal){
      await this.dismissModal();
    }

    if (await firstValueFrom(this.isArbeitsgruppenFeed()) !== true) {
      this.nav.navigateToChatDetails(this.chatId);
    }
  }

  async changeNotifications() {
    await this.userSettingsService.toggleSilentFeedNotificationSubscription(this.userService.getCurrentUserDocId(), this.chatId);
    this.notificationsEnabled = !this.notificationsEnabled;
  }

  async dismissModal() {
    await this.modalController.dismiss({
      message: this.message
    });
  }

  async archiveChat(chat: ChatFeed) {
    const alert = await this.alertController.create({
      header: chat.archived ? 'Chat wiederherstellen' : 'Chat archivieren',
      message: `Wollen Sie den Chat wirklich ${chat.archived ? 'wiederherstellen' : 'archivieren'}?`,
      buttons: [
        { text: 'Nein' },
        {
          text: 'Ja',
          handler: async () => {
            await this.chatService.archiveChat(chat);
            if (this.isModal){
              await this.dismissModal();
            }else {
              await this.nav.navigateToChats();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async deleteChat(chat: ChatFeed) {

    if (!this.userService.isRedaktion$.getValue()) {
      return;
    }
    const alert = await this.alertController.create({
      header: 'Chat löschen',
      message: 'Möchten Sie diesen Chat wirklich löschen?',
      buttons: [
        { text: 'Abbrechen' },
        {
          text: 'Löschen',
          handler: async () => {
            await this.chatService.deleteChat(this.chatId);
            if (this.isModal){
              await this.dismissModal();
            }else {
              await this.nav.navigateToChats();
            }
          }
        }
      ]
    })
    await alert.present();
  }

  async navigateToContext() {
    const chat = await firstValueFrom(this.chatService.getChat(this.chatId));
    await this.nav.navigateToChatContext(chat.context);
  }

  async enableEditMode() {
    if (this.canEditChat(this.chatFeed)) {
      this.editableChatName = this.chatFeed.name || this.chatFeed.defaultName;
      this.isEditMode = true;
    }
    setTimeout(async () => {
      await this.chatTitleInput.setFocus();
    });
  }

  async saveChatName() {
    const trimmedName = this.editableChatName?.trim();

    if (!trimmedName) {
      this.chatFeed.defaultName = await this.chatService.getChatDefaultName(this.chatFeed);
      this.chatFeed.name = '';
    } else {
      this.chatFeed.name = trimmedName;
    }

    await this.chatService.updateChat(this.chatFeed);

    this.isEditMode = false;
  }

  canEditChat(chat: ChatFeed): boolean {
    this.loggingService.logWarning(this, 'TODO: refactor me!')
    return chat?.owner === this.userService.getCurrentUserUid();
  }

  async deleteMessage(message: ChatMessage) {
    try {
      await this.chatService.deleteMessage(this.chatId,message.docId);
    } catch (error) {
      console.error('Fehler beim Löschen der Nachricht:', error);
    }
  }

  trackMessageByDocId(index: number, message: ChatMessage): string {
    return message?.docId || '';
  }

  protected readonly alert = alert;
}

