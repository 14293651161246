import { Injectable } from '@angular/core';

import {Hit} from '@algolia/client-search';
import {SearchItem} from '../models/searchItem';
import {NavigationService} from './navigation.service';
import {UserService} from './user.service';
import {UtilService} from './util.service';
import {AuthService} from './auth-service.service';
import {environment} from '../../brand/environments/environment';
import {VisitService} from './visit.service';
import {map} from 'rxjs/operators';
import {TenantService} from "./tenant.service";
import {LoggingService} from "./logging.service";
import {algoliasearch, SearchClient} from "algoliasearch";
import {ArbeitsgruppenService} from "./arbeitsgruppen.service";
import {ChatService} from "./chat.service";

const searchClient: SearchClient = algoliasearch(
      environment.algolia.appId,
      environment.algolia.apiKey,


    // {
    //   requestsCache: createNullCache(),
    //   responsesCache: createNullCache(),
    // }
);
@Injectable({
  providedIn: 'root'
})
export class SearchService {

  config = {
    indexName: environment.algolia.indexName,
    searchClient
  };

  constructor(
      private nav: NavigationService,
      private userService: UserService,
      private authService: AuthService,
      private visitService: VisitService,
      private tenantService: TenantService,
      private arbeitsgruppenService: ArbeitsgruppenService,
      private loggingService: LoggingService,
      private chatService: ChatService
  ) {
    console.log('init search service ' + environment.algolia.indexName);
  }

  async searchItems(searchTerm: string, limit: number = 512): Promise<Hit<any>> {
    await this.authService.Init();

    let filter = '';
    const agFilter: string[] = [];
    const currentUser = this.userService.currentUser$.getValue();
    const tenantId = this.tenantService.tenant$.getValue()?.tenantId;

    if (!tenantId || tenantId === ''){
      this.loggingService.logError(this, 'No tenantId', {tenantId})
      return [];
    }

    console.log('currentUser', currentUser);
    if (currentUser && currentUser.uid && currentUser.accountFreigegeben) {
      const agUserFilter = this.arbeitsgruppenService.currentUserArbeitsgruppen.getValue()?.map(ag => `arbeitsgruppe:${ag}`) ?? [];
      agFilter.push(...agUserFilter);
	  agFilter.push(`arbeitsgruppe:0`);

      const chatFilter = 'owner:' + currentUser.uid + ' OR participants:' + currentUser.uid;
      agFilter.push(chatFilter);
    }else {
      filter += ' AND visibility:public';
    }

    if (agFilter.length > 0) {
      filter += ` AND (${agFilter.join(' OR ')})`;
    }

    const feedsFilter = 'tenantId:' + tenantId + ' AND published=1 AND NOT status:deleted' + filter;

    const results = await searchClient.search({
      requests:[{
        indexName: environment.algolia.indexName,
        query: searchTerm,
        filters: `${feedsFilter}`,
        hitsPerPage: limit ?? 0,
      }]
    });


    const res = results.results[0] as unknown as {hits: Hit<any>[]};

    if(res?.hits?.length > 0) {
      for (const h of res?.hits) {
        const hit: SearchItem = h as unknown as SearchItem;
        const path = hit.itemPath.replace(/^\/tenant\/[^\/]+/, '').replace(/\/$/, '');
        hit.viewLocalTimeStampHasUnreadMessages = this.visitService
            .hasCurrentUserVisitSinceObservable(path, hit.itemDocId, hit.modified)
            .pipe(map(hasVisit => !hasVisit));
      }
    }
    console.log(res?.hits);
    return res?.hits;
  }

  openItem(item: Hit<SearchItem>) {
    switch (item.itemType) {
      case 'news':
        this.nav.navigateToNewsContent(item.feedId, item.itemDocId);
        break;
      case 'wiki':
        this.nav.navigateToWikiDocument( item.itemDocId);
        break;
      case 'survey':
        this.nav.navigateToSurvey(item.feedId, item.itemDocId);
        break;
      case 'document':
        this.nav.navigateToDocument(item.feedId, item.itemDocId);
        break;
      case 'event':
        this.nav.navigateToCalendarItem(item.itemDocId);
        break;
      case 'chat':
        this.nav.navigateToChat(item.itemDocId);
        break;
    }
  }

  getIcon(item: any) {
    switch (item.itemType) {
      case 'news':
        return 'newspaper';
      case 'wiki':
        return 'book';
      case 'survey':
        return 'list';
      case 'document':
        return 'document';
      case 'event':
        return 'calendar';
      case 'chat':
        if(this.chatService.determineItemIsSupportChat(item)){
          return 'chatbox-ellipses'
        }else {
          return 'chatbubble-ellipses';
        }
    }
  }

  getTimeStampLocalString(item: any) {
    if (item.modified && item.modified > 0) {
      return UtilService.convertUtcUnixTimestampToLocalString(item.modified);
    }else {
      return '';
    }
  }
}
