import { Injectable } from '@angular/core';
import {NavigationEnd, Route, Router} from '@angular/router';
import {routes} from '../app-routing.module';
import {User} from '../models/user';
import {Platform} from '@ionic/angular';
import {Tenant} from '../models/tenant';
import {TenantService} from './tenant.service';
import {Location} from '@angular/common';
import {LoggingService, LogLevel} from "./logging.service";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    public selectedIndex = 0;

    public appPages: any;

    constructor(private router: Router,
                private platform: Platform) {

        router.events.subscribe((val) => {

            const host = window.location.host;
            // console.log('Host:', host); // TODO: AR: Switch Tenant by host url


            if (!(val instanceof NavigationEnd)) {
                return;
            }
            const url = (val as any).url as string;
            if (url === undefined) {
                return;
            }
            this.selectedIndex = this.appPages?.findIndex((r: { url: string; }) => {
                return url.startsWith(r.url);
      }) ?? 0;
        });
    }

    public async initNavigation(user: User, currentTenant: Tenant) {
        this.appPages = routes.filter(r =>
            (r && r.data != null && r.data)
            && (r.data.title && r.data.title.length > 0)
            && (r.data.role == null || (user != null && user.roles != null && user.roles.some(ro => r.data.role.includes(ro))))
            && (r.data.platform == null || this.platform.is(r.data.platform))
            && (this.tenantHasFeatureEnabled(user,  currentTenant, r.data))
        ).map(r => ({
            title: r.data.title,
            url: r.data.url,
            icon: r.data.icon,
            feature:r.data.feature

        }));
    }

    public destroyNavigation() {
        this.appPages = null;
    }

    tenantHasFeatureEnabled(user: User, currentTenant: Tenant, data: any) {
        const feature = data.feature;

        if (user == null || currentTenant == null) {
            return false;
        }

        if (data.role === 'root' && user.roles.some(r => r === 'root')) {
            return true;
        }

        if (feature === undefined) {
            return true;
        }

        if (currentTenant.features == null) {
            currentTenant.features = {};
        }

        // if (!currentTenant.features[feature]) {
        //   currentTenant.features[feature] = false;
        //   this.tenantService.updateTenant(currentTenant);
        // }
        if(currentTenant.features[feature]?.enabled === undefined){
            LoggingService.Log(this, 'tenantHasFeatureEnabled : feature.enabled is undefined=>' + feature,
                {feature: feature, tenant: currentTenant});
        }
        return (feature == null || currentTenant.features[feature]?.enabled === true);
    }

    async navigateToRegisterMail(email: string) {
        await this.router.navigate(['/register', email]);
    }

    async navigateToRegister() {
        await this.router.navigate(['/register']);
    }

    async navigateToLogin() {
        await this.router.navigate(['/login'], {replaceUrl: true});
    }


    async navigateToLoginEmail(email: string) {
        await this.router.navigate(['/login', email], {replaceUrl: true});
    }

  async navigateToStart() {
    await this.router.navigate(['/start'], { replaceUrl: true });
    this.selectedIndex = 0;
  }

  async navigateToChatDetails(chatId: string) {
    await this.router.navigate(['/chats', chatId, 'details']);
  }

  async navigateToChat(chatId: string) {
    await this.router.navigate(['/chats', chatId]);
  }

  async navigateToChats() {
    await this.router.navigate(['/chats'], {replaceUrl: true});
  }

  async navigateToChatContext(context: string) {
    await this.router.navigate([context]);
  }

  async navigateToNewsFeed(feedId: string) {
    await this.router.navigate(['/news', feedId]);
  }

  async navigateToNewsContent(feedId: string, newsId: string) {
    await this.router.navigate(['/news', feedId, 'feed', newsId]);
  }

  async navigateToNewlyCreatedNewsContent(feedId: string, newsId: string) {
    await this.router.navigate(['/news', feedId, 'feed', newsId], {replaceUrl: true});
  }

  async navigateToNewNewsContent(feedId: string) {
    await this.router.navigate(['/news', feedId, 'feed']);
  }

    async navigateToContact(docId: string) {
        await this.router.navigate(['/contacts', docId]);
    }

  async navigateToCalendar() {
    await this.router.navigate(['/calendars']);
  }

  async navigateToNewSurveyFeed(feedDocId: string) {
    await this.router.navigate(['/surveys', feedDocId, 'feed'], {replaceUrl: true});
  }

  async navigateToSurvey(feedDocId: string, surveyId: string) {
    await this.router.navigate(['/surveys', feedDocId, 'feed', surveyId]);
  }

  async navigateToNewSurvey(feedDocId: string, surveyId: string) {
    await this.router.navigate(['/surveys', feedDocId, 'feed', surveyId], {replaceUrl: true});
  }

    async navigateToSurveys(feedDocId: string) {
        await this.router.navigate(['/surveys', feedDocId]);
    }

    async navigateToSurveyAnalyse(feedId: string, surveyId: string) {
        await this.router.navigate(['/survey-analyse', feedId, surveyId]);
    }

  async navigateToWikiRoot() {
    await this.router.navigate(['/wiki']);
  }

  async navigateToWikiDocument(docId: string) {
    await this.router.navigate(['/wiki', docId]);
  }

  async navigateToProfile(uid: string) {
    await this.router.navigate(['/profile', uid]);
  }

  async navigateToOwnProfile() {
    await this.router.navigate(['/profile'], {replaceUrl: true});
  }

  async navigateToDocument(feedId: string, itemDocId: string) {
    await this.router.navigate(['/documents', feedId, itemDocId]);
  }

  async navigateToCalendarItem(itemDocId: string) {
    await this.router.navigate(['/calendar', itemDocId]);
  }

  async navigateToNewEvent() {
    await this.router.navigate(['/calendar']);
  }

  async navigateToEvent(id: string) {
    await this.router.navigate(['/calendar', id]);
  }

  async navigateToNewlyCreatedEvent(id: string) {
    await this.router.navigate(['/calendar', id], {replaceUrl: true});
  }

    async navigateTo403() {
        await this.navigateToLogin();
    }
}
