import {Injectable} from '@angular/core';
import {Tenant} from '../models/tenant';
import {dbPaths} from './globals';
import {FirestoreProxyService} from './firestore-proxy.service';
import {TenantIdGeneratorService} from './tenant-id-generator.service';
import {environment} from '../../brand/environments/environment';
import {combineLatestWith, firstValueFrom, Observable, of, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {LocalCacheService} from './local-cache.service';
import {User} from "../models/user";
import {LoggingService} from "./logging.service";
import {UtilService} from "./util.service";
import {AuthService} from "./auth-service.service";

@Injectable({
    providedIn: 'root'
})
export class TenantService {

    private isInitialized = false;
    private isInitInProgress = false;
    private initPromise: Promise<void> | null = null;

    public tenant$ = this.firestoreProxyService.currentTenant;

    constructor(
         private tenantIdGeneratorService: TenantIdGeneratorService,
         private firestoreProxyService: FirestoreProxyService,
         private localCacheService: LocalCacheService,
         private loggingService: LoggingService,
         private authService: AuthService
    ) {
        this.isInitInProgress = false;
        this.isInitialized = false;

        this.tenant$.subscribe( async tenant => {
            if (tenant) {
                if (tenant.primaryColor && tenant.secondaryColor) {
                    this.updateCssColorVars(tenant.primaryColor, tenant.secondaryColor);
                }
            }
        });
        this.loadTenantFromLocalCache();
    }

    public async Init(): Promise<void> {
        this.loggingService.logMethodStart(this, 'Initialize TenantService');

        if (this.isInitialized) {
            this.loggingService.log(this, 'Already initialized, returning');
            return Promise.resolve();
        }

        if(!this.isInitInProgress){
            this.isInitInProgress = true;
            this.initPromise = new Promise<void>(async (resolve, reject) => {
            try {
                this.loggingService.logMethodStart(this, 'Init - this.isInitialized:', this.isInitialized);

                await this.authService.Init();

                this.authService.currentFirebaseUser$
                    .pipe(combineLatestWith(this.authService.isLoggedIn$))
                    .subscribe(async ([firebaseUser, isLoggedIn]) => {
                        this.loggingService.logMethodStart(this, 'firebaseUser or isLoggedIn changed', {firebaseUser, isLoggedIn})
                        if (firebaseUser && firebaseUser.uid && isLoggedIn == true){
                            const idToken = await firebaseUser.getIdTokenResult();
                            if(idToken && idToken.claims && idToken.claims.tenantId && idToken.claims.tenantId.length > 0) {
                                await this.useTenant(idToken.claims.tenantId);
                            }
                        }else{
                            this.clearTenant();
                        }

                        this.isInitialized = true;
                        this.loggingService.logVerbose(this, 'TenantService.Init finish tenantId:', this.tenant$.getValue()?.tenantId);

                        resolve();

                    });
            } catch (error) {
                this.loggingService.logError(this, 'Error during AppInitializationService initialization', error);
                reject(error);
            } finally {
                this.isInitInProgress = false;
                this.initPromise = null;
            }
        });
        }else {
            if (!this.initPromise) {
                this.initPromise = new Promise((resolve, reject) => {
                });
            }
            return this.initPromise;
        }
        return this.initPromise;
    }

    public isTenantIdKnown(): Observable<boolean>{
        return this.tenant$
            .pipe(map(t => t?.tenantId != null && t?.tenantId.length > 0))
            .pipe(combineLatestWith(of(environment.useStaticTenant && environment.tenantId?.length > 0 )))
            .pipe(map(([currentTenant, environmentTenant]) => {
                return currentTenant || environmentTenant; // TODO:AR: aus SubDomain
            }));
    }

    async getTenant(tenantId: string): Promise<Tenant> {
        return firstValueFrom(
            this.firestoreProxyService.getDocument<Tenant>(dbPaths.tenantPath, tenantId, false));
    }

    loadTenantFromLocalCache() {
        this.loggingService.logMethodStart(this, 'Loading Tenant from Local Cache');
        this.localCacheService.getData<Tenant>(null, this.localCacheService.storeKeys.tenant).then(possibleTenantData => {
            if (possibleTenantData !== null && possibleTenantData.tenantId === this.getCurrentTenantId()) {
                this.loggingService.logVerbose(
                    this,
                    'Tenant loaded from Local Cache',
                    { tenantId: possibleTenantData.tenantId });
                this.firestoreProxyService.currentTenant.next(possibleTenantData);
            }
        });
    }

    async switchTenant(user: User, tenantId: string): Promise<void> {
        this.loggingService.logMethodStart(this, 'Switching Tenant', {tenantId});
        const userPath = `${dbPaths.tenantPath}/${tenantId}/${dbPaths.usersPath}`;
        const switchTenantUsers = await firstValueFrom(this.firestoreProxyService.query<User>(userPath, [
            {
                fieldPath: 'uid',
                filterOperator: '==',
                value: user.uid
            }
        ], null, 1, false)
        );
        const switchTenantUser = switchTenantUsers[0];
        if(!(switchTenantUser) || switchTenantUser.tenantId !== tenantId || switchTenantUser.uid !== user.uid) {
            throw new Error('Switching Tenant failed. User is not assigned to the tenant or tenantId is invalid.');
        }
        switchTenantUser.tenantSwitchTimestamp = UtilService.getCurrentUtcUnixTimestampAsNumber();
        try {
            await this.firestoreProxyService.updateDocument(userPath, switchTenantUser.docId, switchTenantUser, false);
        }catch (e) {
            this.loggingService.logError(this, 'Switching Tenant failed. Update User failed.', e);
            throw new Error('Switching Tenant failed. Update User failed.');
        }

        await this.useTenant(tenantId);
    }

    private tenantSubscription = new Subscription();

    async useTenant(tenantId: string): Promise<void> {
        this.loggingService.logMethodStart(this, 'Using Tenant', { tenantId });

        // Use environment-defined tenant ID if applicable
        if (environment.useStaticTenant && environment.tenantId) {
            tenantId = environment.tenantId;
        }

        if (!tenantId) {
            throw new Error('No tenantId provided!');
        }

        // Cleanup any existing tenant subscriptions
        this.tenantSubscription.unsubscribe();

        return new Promise<void>((resolve, reject) => {
            let isFirstTenantLoad = true;

            // Subscribe to the tenant changes
            this.tenantSubscription = this.firestoreProxyService
                .getDocumentConnectedObservableWithoutTenant<Tenant>(dbPaths.tenantPath, tenantId)
                .pipe(
                    tap(async (tenant) => {
                        if (tenant) {
                            this.loggingService.logMethodStart(this, 'Tenant loaded', { tenantId });
                            // Ensure tenant-specific configuration
                            const currentTenant = this.firestoreProxyService.currentTenant.getValue();
                            if (currentTenant == null || currentTenant == undefined || !UtilService.deepEqual(currentTenant, tenant)) {

                                await this.ensureTenantFeaturesAndConfiguration(tenant);

                                // Update cache and the current tenant
                                this.localCacheService.storeData(null, this.localCacheService.storeKeys.tenant, tenant);
                                this.firestoreProxyService.currentTenant.next(tenant);
                            }
                            // Resolve the promise once the first tenant is loaded
                            if (isFirstTenantLoad) {
                                isFirstTenantLoad = false;
                                this.loggingService.log(this, 'Tenant loaded successfully', { tenantId });
                                resolve();
                            }
                        }
                        else {
                            this.loggingService.logError(this, 'Failed to load tenant', { tenantId });
                            // TODO:Tenant nicht vorhanden. Erstellen????!
                            resolve();
                        }
                    })
                )
                .subscribe({
                    error: (err) => {
                        this.loggingService.logError(
                            this,
                            `Failed to load tenant: ${err.message}`,
                            { tenantId }
                        );
                        reject(err); // Reject the promise if an error occurs
                    },
                });
        });
    }

    public async createTenant(
        clientName: string,
        primaryColor: string,
        secondaryColor: string): Promise<string> {

        const tenantId = await this.tenantIdGeneratorService.generateTenantId();
        const tenantDocument = {
            tenantId,
            clientName,
            primaryColor,
            secondaryColor,
            maxUsers: environment.defaultMaxUsers,
            features: environment.defaultBrandFeatures
        };

        await this.firestoreProxyService.setDocumentWithoutTenant(tenantId, dbPaths.tenantPath, tenantDocument);
        return tenantId;
    }

    async updateTenant(tenant: Tenant) {
        await this.firestoreProxyService.mergeDocument(tenant.tenantId, dbPaths.tenantPath, tenant, false);
    }

    updateCssColorVars(colorPrime: string, colorSecond: string) {
        if (colorPrime && colorPrime.length > 0) {
            document.documentElement.style.setProperty(`--ion-color-primary`, colorPrime);
        }
        if (colorSecond && colorSecond.length > 0) {
            document.documentElement.style.setProperty(`--ion-color-secondary`, colorSecond);
        }
    }

    clearTenant() {
        if(this.firestoreProxyService.currentTenant.getValue() !== null) {
            this.firestoreProxyService.currentTenant.next(null);
        }
    }

    async isTenantExisting(tenantId: string): Promise<boolean> {
        return await this.firestoreProxyService.documentExistsWithoutTenant(dbPaths.tenantPath, tenantId);
    }

    getAllTenants(): Observable<Tenant[]> {
        return this.firestoreProxyService.query<Tenant>(dbPaths.tenantPath, null, null, null, false);
    }

    getCurrentTenantId(): string {
        return this.firestoreProxyService.currentTenant.getValue()?.tenantId;
    }

    async deleteTenant(tenantId: string) {
        await this.firestoreProxyService.deleteDocument(dbPaths.tenantPath, tenantId, false);
    }

    private async ensureTenantFeaturesAndConfiguration(tenant: Tenant) {
        this.loggingService.logMethodStart(this, 'ensureTenantFeaturesAndConfiguration', { tenantId: tenant.tenantId });
        const defaultBrandFeatures = environment.defaultBrandFeatures;
        const tenantMetaData = environment.metaData;
        let hasChanges = false;

        if(!tenant.tenantId && (tenant.docId)){
            tenant.tenantId = tenant.docId;
            hasChanges = true;
        }
        else if(!tenant.tenantId){
            throw new Error('Unable to set tenantId');
        }

        if (tenant.maxUsers == null || tenant.maxUsers == undefined) {
            tenant.maxUsers = environment.defaultMaxUsers;
            hasChanges = true;
        }

        if (tenant.useStaticTenant == null || tenant.useStaticTenant == undefined) {
            tenant.useStaticTenant = environment.useStaticTenant;
            hasChanges = true;
        }

        for (const feature in defaultBrandFeatures) {
            if (defaultBrandFeatures.hasOwnProperty(feature)) {
                if (!tenant.features) {
                    tenant.features = {};
                }
                if (tenant.features[feature] === undefined || tenant.features[feature]?.enabled === undefined) {
                    tenant.features[feature] = defaultBrandFeatures[feature];
                    hasChanges = true;
                }
            }
        }
        for (const metaData in tenantMetaData) {
            if (tenantMetaData.hasOwnProperty(metaData)) {
                if (!tenant.metaData) {
                    tenant.metaData = {};
                }
                if (tenant.metaData[metaData] === undefined) {
                    tenant.metaData[metaData] = tenantMetaData[metaData];
                    hasChanges = true;
                }
            }
        }
        if (hasChanges) {
            await this.updateTenant(tenant);
        }
    }

    ensureUserIsAssignToATenant(user: User) {
        if(user?.tenantId && user.tenantId.length > 0) {
            return user;
        }

        if (this.getCurrentTenantId()) {
            user.tenantId = this.getCurrentTenantId();
            return user;
        }

        if(environment.useStaticTenant){
            user.tenantId = environment.tenantId;
            return user;
        }

        if(!user.tenantId) {
            throw new Error('User is not assigned to a tenant');
        }
    }

}
