import {Component, Input, OnInit} from '@angular/core';
import {FeatureOption} from "../../models/featureOption";
import {ModalController, ToastController} from "@ionic/angular";

@Component({
    selector: 'app-change-feature-properties-popup',
    templateUrl: './change-feature-properties-popup.component.html',
    styleUrls: ['./change-feature-properties-popup.component.scss'],
})
export class ChangeFeaturePropertiesPopupComponent implements OnInit {


    @Input() feature: FeatureOption = {enabled: true, displayName: "Akten Stapel"}
    @Input() featureKey: string = "";

    constructor(
        private toastCtrl: ToastController,
        private modalCtrl: ModalController
    ) {

    }

    ngOnInit() {
    }

    async saveFeature() {
        if (this.feature.displayName.replace(" ", "") === "") {
            const toast = await this.toastCtrl.create({
                header: 'Speichern nicht Möglich',
                icon: "warning",
                message: 'Bitte fülle alle Pflichtfelder aus',
                duration: 3000,
                color: 'danger'
            });
            await toast.present();
            return
        }

        await this.modalCtrl.dismiss({
            change: true,
            feature: this.feature,
            featureKey: this.featureKey
        });

    }

    async closePopup() {
        await this.modalCtrl.dismiss({
            change: false,
            feature: null,
            featureKey: this.featureKey
        });

    }
}
