import {EventEmitter, Injectable} from '@angular/core';

import Quill from 'quill';
import {AlertController, LoadingController, Platform} from "@ionic/angular";
import {EventType, Router} from "@angular/router";
import {UserService} from "./user.service";
import {NavigationService} from "./navigation.service";
import {firstValueFrom, Subscription} from "rxjs";
import {Browser} from "@capacitor/browser";
import { BuckedCompadibleDocument } from '../models/buckedCompadibleDocument';
import { dbPaths } from './globals';
import {HttpClient} from "@angular/common/http";
import {FileService} from "./file.service";
import {LoggingService} from "./logging.service";
import {QuillEditorInstanceType} from "../models/quillEditorInstanceType";


const Parchment = Quill.import('parchment');

const Link = Quill.import('formats/link');
Link.whitelist = ['button'];

let allowWikiPageLikings = true;
const wikiDocPrefix = 'va-wikiDoc::'

const isWikiDocRegex= RegExp(".*"+wikiDocPrefix+".*");

const urlRegex = RegExp('(https?:(\\\\\\\\))?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)');


const specialEditorAllowedPages = ['pages', 'wiki'];
// quill constants
const List = Quill.import('formats/list');
export const Delta = Quill.import('delta');


export class AdvancedLink extends Link {
    static nav: NavigationService;
    static platformIsWeb = false;
    static isLinkWikiDocumentChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    static editView = false;


    static askOpenInBrowserFunction = async (name, func) => {
        console.log('Empty');
    };

    static setNavigationService(service: NavigationService) {
        AdvancedLink.nav = service;
    }

    static setPlatformIsWeb(b: boolean) {
        AdvancedLink.platformIsWeb = b;
    }

    static setEditView(editView: boolean) {
        if (editView === AdvancedLink.editView) return;
        AdvancedLink.editView = editView;
    }

    static async openLinkInAppBrowser(routerLink: string) {
        let formattedUrl = routerLink;
        if (!/^https?:\/\//i.test(routerLink)) {
            // Prepend "https://" if protocol is missing
            formattedUrl = 'https://' + routerLink;
        }
        await Browser.open({
            url: formattedUrl,
            windowName: '_self',
        });
    }

    static setBrowserAskOpenFunction(func) {
        AdvancedLink.askOpenInBrowserFunction = func;
    }

    static create(value) {
        const node = super.create(value);

        if (!node.getAttribute('routerlink')) {
            node.setAttribute('routerlink', value);
        }
        node.addEventListener('click', async (e) => {
            if (!urlRegex.test(value)) {
                AdvancedLink.isLinkWikiDocumentChange.emit(true);
            } else {
                AdvancedLink.isLinkWikiDocumentChange.emit(false);
            }
            if (AdvancedLink.editView) {
                e.preventDefault();
                return;
            }
            e.preventDefault();
            const routerLink = node.getAttribute('routerlink');
            const alterNativeLink = node.getAttribute('href');
            if (!allowWikiPageLikings) {
                if (AdvancedLink.platformIsWeb) {
                    await this.askOpenInBrowserFunction(routerLink, () => {
                        let url = routerLink;
                        if (!/^https?:\/\//i.test(routerLink)) {
                            // Prepend "https://" if protocol is missing
                            url = 'https://' + routerLink;
                        }
                        window.open(url);
                    });
                    return;

                } else {
                    console.log('redirecting to ', routerLink, node, 'noweb');
                    await AdvancedLink.openLinkInAppBrowser(routerLink);
                }
                return;
            }

            if (routerLink && AdvancedLink.nav) {
                console.log('redirecting to ', routerLink, node);
                if (!isWikiDocRegex.test(routerLink)) {
                    if (AdvancedLink.platformIsWeb) {
                        await this.askOpenInBrowserFunction(routerLink, () => {
                            let url = routerLink;
                            if (!/^https?:\/\//i.test(routerLink)) {
                                // Prepend "https://" if protocol is missing
                                url = 'https://' + routerLink;
                            }
                            window.open(url);
                        });

                    } else {
                        console.log('redirecting to ', routerLink, node, 'noweb');
                        await AdvancedLink.openLinkInAppBrowser(routerLink);
                    }
                } else {
                    const urlParts = routerLink.split('/');
                    const wikiDocId = urlParts[urlParts.length - 1].replace(wikiDocPrefix,"");

                    await this.nav.navigateToWikiDocument(wikiDocId);
                }
            }
        });
        return node;
    }
}


const dataId = new Parchment.Attributor.Attribute('routerlink', {
    scope: Parchment.Scope.INLINE
});
Quill.register('formats/link', AdvancedLink, true);
Quill.register(dataId);

@Injectable({
    providedIn: 'root'
})
export class QuillEditorService {
    public readonly editorToolsLite = {

        clipboard: {
            matchVisual: false
        }, toolbar: [['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],

            [{header: 1}, {header: 2}], // custom button values
            [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}],
            [{script: 'sub'}, {script: 'super'}], // superscript/subscript
            [{indent: '-1'}, {indent: '+1'}], // outdent/indent
            [{direction: 'rtl'}], // text direction
            [{size: ['small', false, 'large', 'huge']}], // custom dropdown
            [{header: [1, 2, 3, 4, 5, 6, false]}], [{color: []}, {background: []}], // dropdown with defaults from theme
            [{font: []}], [{align: []}], ['clean'], // remove formatting button
            ['link', 'image'] // link and image, video
        ]
    };
    public readonly editorToolsMobile = {

        clipboard: {
            matchVisual: false
        }, toolbar: [['bold', 'italic', 'underline', 'strike'], // toggled buttons
            //['blockquote', 'code-block'],
             [{color: []},{background: []}],

            //[{header: 1}, {header: 2}], // custom button values
            [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}],
            //[{script: 'sub'}, {script: 'super'}], // superscript/subscript
            [{align: []}], ['link'], [{font: []}],
            //[{indent: '-1'}, {indent: '+1'}], // outdent/indent
            //[{direction: 'rtl'}], // text direction
            //[{size: ['small', false, 'large', 'huge']}], // custom dropdown
            [{header: [1, 2, 3, 4, 5, 6, false]}],  // dropdown with defaults from theme
            //, ['clean'], // remove formatting button
             // link and image, video
        ]
    };
    public readonly editorToolsMicro = {

        clipboard: {
            matchVisual: false
        }, toolbar: [['bold', 'underline', // toggled buttons
            {color: []}, {list: 'bullet'},
                //{background: []}],
            {align: []},
            {header: [1, 2, 3, 4, 5, 6, false]},  // dropdown with defaults from theme
            ]
        ]
    };
    public readonly editorToolsStrong = {

        clipboard: {
            matchVisual: false
        }, toolbar: [['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],

            [{header: 1}, {header: 2}], // custom button values
            [{list: 'ordered'}, {list: 'bullet'}, {list: 'check'}], [{script: 'sub'}, {script: 'super'}], // superscript/subscript
            [{indent: '-1'}, {indent: '+1'}], // outdent/indent
            [{direction: 'rtl'}], // text direction
            [{size: ['small', false, 'large', 'huge']}], // custom dropdown
            [{header: [1, 2, 3, 4, 5, 6, false]}], [{color: []}, {background: []}], // dropdown with defaults from theme
            [{font: []}], [{align: []}], ['clean'], // remove formatting button
            ['link', 'image', 'video', 'formula'] // link and image, video
        ]
    };
    private subscriptions = new Subscription();
    private quillInstanceMemory = {};
    private readonly quillModulesAny = {
        clipboard: {
            matchVisual: false
        }
    };
    tb_key_micro = "micro";
    tb_key_strong = "strong";

    tb_key_lite = "lite";

    public static isUrlWikiDoc = wikiDocPrefix;

    constructor(private platform: Platform,
                private router: Router,
                private httpWebClient: HttpClient,
                private fileService: FileService,
                public userService: UserService,
                private alertController: AlertController,
                protected navigationService: NavigationService,
                private loadingCtrl: LoadingController,
                private loggingService: LoggingService,
                ) {

        AdvancedLink.setNavigationService(this.navigationService);

        const platformIsWeb = (!this.platform.is('mobile'));

        AdvancedLink.setPlatformIsWeb(platformIsWeb);
        AdvancedLink.setBrowserAskOpenFunction(async (pagename, funcComplte) => {
            await this.openInWebBrowserQuestion(pagename, funcComplte);


            this.subscriptions.add(this.router.events.subscribe(event => {

                // @ts-ignore (issue with quil bindings not being available)
                this.listenRouterChange(event);
            }));

        });


    }

    async showLoadingSaveScreen() {
        const loading = await this.loadingCtrl.create({
            message: 'Speichern',
            duration: 30000,
        });

        await loading.present();
        return loading;
    }

    initializeQuillServ() {
        console.log("appComponent: Quill cnf loaded");
        // PLEASE KEEP IN COMPONENT, SO THAT QUILL GETS ALREADY INITIALIZED ON APP START
    }


    async openInWebBrowserQuestion(pageName, onSucess) {

        const alert = await this.alertController.create({
            header: 'Im Browser öffnen',
            message: 'Möchten Sie die Webseite "' + pageName + '" im Browser öffnen?',
            buttons: [{
                text: 'Nein'
            }, {
                text: 'Im Browser öffnen', handler: value => {
                    onSucess();
                }
            }]
        });
        await alert.present();
    }

    registerQuillInstance(quillInstance: any): number {
        let ngInstanceId = quillInstance.container.__ngContext__;
        this.quillInstanceMemory[ngInstanceId] = quillInstance;
        return ngInstanceId;
    }

    quillLinkActionOnEditorContentChanged($event, quillInstanceId: number) {
        let quillEditor = this.getQuillByInstanceId(quillInstanceId);
        if (!quillEditor) return;

        // Select all links in the editor
        const links = quillEditor.container.querySelectorAll('a');
        links.forEach((link: HTMLElement) => {
            link.setAttribute('contenteditable', 'false'); // Make the link non-editable
        });
    }
    getQuillByInstanceId(instanceId: number) {
        if (this.quillInstanceMemory[instanceId] === undefined) {
            console.error("Quill Instance doesn't exist, not registered", instanceId)
            return null;
        }
        return this.quillInstanceMemory[instanceId];
    }

    listenRouterChange(event: { type: EventType; route: any; url: string; }) {
        if (!((event.type === EventType.NavigationEnd))) {
            return;
        }
        let path: string = event.route;
        if (typeof path !== 'string') {
            if (event.url) {
                path = event.url.substring(1, event.url.length);
            } else if (event.route.path) {
                path = event.route.path;
            } else {
                return;
            }
        }
        for (const pageString of specialEditorAllowedPages) {
            if (path.startsWith(pageString)) {
                allowWikiPageLikings = true;
                return;
            }
        }
        allowWikiPageLikings = false;
    }

    downloadFile(fileUid: string) {
        let path = dbPaths.quillDocsPath + "/" + fileUid;
        return this.fileService.downloadFile(path);
    }
    async getQuillContentByUrl(url: string, uid: string): Promise<string> {

        if (url == null) {
            this.loggingService.log("Unable to get Url because its 'null'", "Document might be old format");
            return "";
        }

        let dataProt = await firstValueFrom(this.httpWebClient.get(url, {responseType: 'blob'}))

        let textBinary = await dataProt.arrayBuffer();

        let td = new TextDecoder();

        return td.decode(textBinary);
    }

    async uploadOrUpdateQuillDocument(quillContent: string, uuid?: string, title?) {

        const quillBlob = new Blob([quillContent], {
            type: 'text/plain'
        });
        await this.showLoadingSaveScreen();

        let prom=await this.fileService.uploadBlob(quillBlob, dbPaths.quillDocsPath, title ? title : "no title",undefined,undefined,uuid);
        await this.dismissLoadingScreen();
        return prom;
    }

    async deleteQuillDocument(url: string) {
        //await this.firestore.deleteDocument(dbPaths.documentsPath + '/' + parentDocId + '/' + Globals.messagesCollectionName, c.docId);
        //todo: remove firestore ref
        console.log("DELETING FILE",url);
        await this.fileService.deleteFile(url);
    }

    getQuillModulesAny() {
        return this.quillModulesAny;
    }

    selectLoadPriority(document: BuckedCompadibleDocument):'document'|'file'{
        // select where to load a document from
        if (document.content?.replace(" ","")!=""){
            return "document";
        }else{
            return "file";
        }
    }
    selectSavePriority(document:BuckedCompadibleDocument,content):string{
        // mechanism for deciding where to store wiki documents
        let FIREBASE_MAX_ABSOLUTE=1048487
        const MAX_BYTES_SOFT= 300000;
        if (content?.length>MAX_BYTES_SOFT){
            return "file";
        } else {
            return "document";
        }
    }
    private async dismissLoadingScreen() {
        await this.loadingCtrl.dismiss();
    }

    getQuillEditorToolBar(tb_key: string) {
        let isMobile = this.platform.is("mobile");

        if (tb_key==this.tb_key_micro){
            return this.editorToolsMicro;

        }else if (tb_key==this.tb_key_lite){
            return isMobile ? this.editorToolsMobile :  this.editorToolsLite;
        }else if (tb_key==this.tb_key_strong){
            return isMobile ? this.editorToolsMobile :  this.editorToolsStrong;
        }else{
            this.loggingService.logError(this,"tb_key is not a valid tb_key","");
        }


        return ;
    }

    setQuillUrlInputText(editor: QuillEditorInstanceType) {
        // set placeholder for Quill Editor link input
        editor.theme.tooltip.textbox.setAttribute('data-link', '');
    }
}
