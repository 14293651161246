// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forbidden-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.error-card {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: center;
  max-width: 500px;
}

h1 {
  color: #e74c3c;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 2rem;
  color: #555;
}

.back-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #2980b9;
}`, "",{"version":3,"sources":["webpack://./src/app/components/forbidden/forbidden.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,wCAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,iCAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".forbidden-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: #f5f5f5;\n}\n\n.error-card {\n  padding: 2rem;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  background-color: white;\n  text-align: center;\n  max-width: 500px;\n}\n\nh1 {\n  color: #e74c3c;\n  margin-bottom: 1rem;\n}\n\np {\n  margin-bottom: 2rem;\n  color: #555;\n}\n\n.back-button {\n  background-color: #3498db;\n  color: white;\n  border: none;\n  padding: 0.75rem 1.5rem;\n  border-radius: 4px;\n  cursor: pointer;\n  font-weight: bold;\n  transition: background-color 0.3s;\n}\n\n.back-button:hover {\n  background-color: #2980b9;\n} "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
