// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item:hover {
  color: var(--ion-color-secondary);
}
ion-item:hover .icons {
  color: var(--ion-color-secondary);
}

.wiki-link-header {
  background-color: var(--ion-color-primary) !important;
  --background: var(--ion-color-primary) !important;
  color: white !important;
  text-align: center !important;
  padding: 0px 0 !important;
  height: 56px;
}

.title-center {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/components/wiki-link-selector/wiki-link-selector.component.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;AACF;AACE;EACE,iCAAA;AACJ;;AAGA;EACE,qDAAA;EACA,iDAAA;EACA,uBAAA;EACA,6BAAA;EACA,yBAAA;EACA,YAAA;AAAF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;AACF","sourcesContent":["ion-item:hover{\n  color: var(--ion-color-secondary);\n\n  .icons{\n    color: var(--ion-color-secondary);\n  }\n\n}\n.wiki-link-header {\n  background-color: var(--ion-color-primary) !important;\n  --background: var(--ion-color-primary) !important;\n  color: white !important;\n  text-align: center !important;\n  padding: 0px 0 !important;\n  height: 56px;\n}\n.title-center {\n  text-align: center;\n  font-size: 18px;\n  font-weight: bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
