import {Injectable} from '@angular/core';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
import {InviteUserPopupComponent} from '../components/invite-user-popup/invite-user-popup.component';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {FirestoreProxyService} from './firestore-proxy.service';
import {ProfilFreigabe} from '../models/profilFreigabe';
import {dbPaths} from './globals';
import {getFunctions} from "@angular/fire/functions";
import {NavigationService} from "./navigation.service";
import {UserService} from "./user.service";
import {AuthService} from "./auth-service.service";
import {Subscription} from "rxjs";
import {FinishSignUpComponent} from "../components/finish-sign-up/finish-sign-up.component";
import {getApp} from "@angular/fire/app";
import {deleteField} from "@angular/fire/firestore";
import firebase from "firebase/compat/app";
import {TenantService} from "./tenant.service";

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  userCredentials: firebase.auth.UserCredential;
  subscription = new Subscription();
  private isFinishSignUpPopupOpen = false;

  constructor(
      private modalController: ModalController,
      private toast: ToastController,
      private angularFireAuth: AngularFireAuth,
      private firestore: FirestoreProxyService,
      private alertController: AlertController,
      private navigationService: NavigationService,
      private userService: UserService,
      private authService: AuthService,
      private functions: AngularFireFunctions,
      private tenantService: TenantService
  ) {}

  async openInvitePopup() {
    const modal = await this.modalController.create({
      component: InviteUserPopupComponent
    });
    return await modal.present();
  }

  async sendInvitation(email: string, vorname: string, nachname: string, anrede: string, firma?: string, titel?: string): Promise<void> {
    await this.userService.createUser({
      UserFreigaben: [],
      accountFreigegeben: false,
      adresse: "",
      freitext: "",
      hidden: false,
      markedForDeletion: false,
      mobil: "",
      position: "",
      profilFreigabe: ProfilFreigabe.Privat,
      roles: [],
      telefon: "",
      tenantId: this.tenantService.getCurrentTenantId(),
      uid: "",
      website: "",
      email,
      vorname,
      nachname,
      anrede,
      firma,
      titel,
      invited: true,
      sendInviteMail: true
    });
  }

  async shareLink(email: string, vorname: string, nachname: string, anrede: string, firma?: string, titel?: string): Promise<string> {
    const doc = await this.userService.createUser({
      UserFreigaben: [],
      accountFreigegeben: false,
      adresse: "",
      freitext: "",
      hidden: false,
      markedForDeletion: false,
      mobil: "",
      position: "",
      profilFreigabe: ProfilFreigabe.Privat,
      roles: [],
      telefon: "",
      tenantId: "",
      uid: "",
      website: "",
      email,
      vorname,
      nachname,
      anrede,
      firma,
      titel,
      invited: true,
      invitedWithLink: true
    });
    return doc.docId;
  }

  async verifyCustomToken(token: string): Promise<boolean> {
    try {
      const result = await this.angularFireAuth.signInWithCustomToken(token);
      this.userCredentials = result;
      return !!result.user;
    } catch (e) {
      const code = e.code;
      if (['auth/invalid-custom-token', 'auth/user-disabled', 'auth/user-not-found'].includes(code)) {
        console.log(code.replace('auth/', '').replace('-', ' '));
        return false;
      }
    }
  }

  async completeSignUp(
      docId: string, token: string, password: string,
      firma: string, anrede: string, titel: string,
      vorname: string, nachname: string): Promise<void> {
    if (this.userCredentials === undefined) {
      this.userCredentials = await this.angularFireAuth.signInWithCustomToken(token);
    }
    await this.userCredentials.user.updatePassword(password);
    await this.firestore.mergeDocument(docId, dbPaths.usersPath, {
      anrede,
      titel,
      vorname,
      nachname,
      firma,
      profilFreigabe: ProfilFreigabe.Privat,
      roles: [],
      benachrichtigungenPerEmail: true,
      token: deleteField(),
      invited: deleteField(),
    });
    await this.modalController.dismiss();
    await this.navigationService.navigateToStart();
    const toast = await this.toast.create({
      header: 'Registrierung',
      message: 'Registrierung erfolgreich abgeschlossen',
      color: 'success',
      duration: 2000,
      position: 'top',
      positionAnchor: 'header'
    });
    await toast.present();
  }

  async prepareRequest(token: string, docId: string, tenantId: string) {
    await this.authService.Init();
    this.subscription.add(this.userService.currentUserIsLoggedIn$.subscribe(async loggedIn => {
      if (loggedIn) {
        this.userService.currentUser$.subscribe(async currentUser => {
          if (currentUser?.invited) {
            await this.openFinishSignUpModal();
          }
        });
      } else {
        this.verifyCustomToken(token)
          .then(async (result) => {
            if (!result) {
              const alert = await this.alertController.create({
                header: 'Registrierung abschließen',
                message: `Ihr Link ist ungültig oder abgelaufen. Möchten Sie einen neuen Link erhalten?`,
                buttons: [
                  {
                    text: 'Nein',
                    handler: async () => {
                      await this.navigationService.navigateToStart();
                    }
                  },
                  {
                    text: 'Ja',
                    handler: async () => {
                      try {
                        const sendSignUpUrlCall = this.functions.httpsCallable('sendSignUpUrl', );
                          const result = await sendSignUpUrlCall({ docId, tenantId }).toPromise();
                          console.log(result.data);

                        //this.functions.httpsCallable('reSendSignUpUrl', { docId, tenantId });


                         const functions = getFunctions(getApp(), 'europe-west3');
                        // const callable = httpsCallable(functions, 'reSendSignUpUrl');
                        // const result = await callable({ docId, tenantId });
                        // console.log(result.data);
                        this.alertController.create({
                            header: 'Neuer Link gesendet',
                            message: 'Ein neuer Link wurde an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang. Der Link ist 24 Stunden gültig.',
                        }).then(toast => {
                            toast.present();
                        });
                      } catch (error) {
                        console.error('Error calling function:', error);
                        this.alertController.create({
                          header: 'Unerwarteter Fehler',
                          message: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
                          buttons: [
                            {
                              text: 'OK',
                              handler: async () => {
                                await this.navigationService.navigateToStart();
                              }
                            }
                          ]
                        }).then(toast => {
                          toast.present();
                        });
                      }
                    }
                  }
                ]
              });
              await alert.present();
            } else {
              await this.navigationService.navigateToStart();
            }
          });
      }
    }));
  }

  async openFinishSignUpModal() {
    if (this.isFinishSignUpPopupOpen) return;
    this.isFinishSignUpPopupOpen = true;
    const modal = await this.modalController.create({
      component: FinishSignUpComponent,
      backdropDismiss: false,
    });
    modal.onDidDismiss().then(() => {
      this.isFinishSignUpPopupOpen = false;
    });
    return await modal.present();
  }
}
