import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {WikiDocument} from '../../models/wikiDocument';
import {WikiService} from '../../services/wiki.service';
import {ModalController} from '@ionic/angular';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-wiki-link-selector',
  templateUrl: './wiki-link-selector.component.html',
  styleUrls: ['./wiki-link-selector.component.scss'],
})
export class WikiLinkSelectorComponent  implements OnInit {
    allWikiItems: Observable<WikiDocument[]>;
    currentItem: any;

  constructor(private wikiService: WikiService, private modalController: ModalController) { }
    ngOnInit() {
      this.allWikiItems = this.wikiService.getAllDocs().pipe(
          map(docs => docs.sort(
              (a, b) => b.time - a.time))
      );
    }

    selectAndClose(wikipage: any) {
      this.modalController.dismiss(wikipage);
    }

    dismiss() {
        this.modalController.dismiss({cancel: true});
    }
}
