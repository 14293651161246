import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoadingController, ModalController} from '@ionic/angular';
import {FirestoreProxyService} from 'src/app/services/firestore-proxy.service';
import {InvitationService} from '../../services/invitation.service';
import {dbPaths, Globals} from '../../services/globals';
import {User} from '../../models/user';

@Component({
  selector: 'app-invite-user-popup',
  templateUrl: './invite-user-popup.component.html',
  styleUrls: ['./invite-user-popup.component.scss']
})
export class InviteUserPopupComponent {
  inviteForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private invitationService: InvitationService,
    private firestore: FirestoreProxyService
  ) {
    this.inviteForm = this.fb.group({
      anrede: [''],
      titel: [''],
      vorname: ['', Validators.required],
      nachname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      firma: ['']
    });
  }

  async onSubmit() {
    if (this.inviteForm.valid) {
      const { anrede, email, vorname, nachname, firma, titel } = this.inviteForm.value;
      await this.invitationService.sendInvitation(email, vorname, nachname, anrede, firma, titel);
      await this.modalController.dismiss();
    }
  }

  async shareLink() {
    if (this.inviteForm.valid) {
      const { anrede, email, vorname, nachname, firma, titel } = this.inviteForm.value;
      const docId = await this.invitationService.shareLink(email, vorname, nachname, anrede, firma, titel);
      await this.modalController.dismiss();
      await this.showLoadingIndicator();
      const url = await this.getLink(docId);
      //TODO: Modal Url
      await this.hideLoadingIndicator();
    }
  }

  async showLoadingIndicator() {
    const loading = await this.loadingController.create({
    message: 'Loading...',
    });
    await loading.present();
  }

  async getLink(docId: string) {
    //TODO: Observe User & Url
    // for (let i = 0; i < 30; i++) {
    //   const user = await this.firestore.getDocument(dbPaths.usersPath, docId).toPromise() as User;
    //   if (user && user.url) {
    //     await this.hideLoadingIndicator();
    //     return;
    //   }
    //   await new Promise(resolve => setTimeout(resolve, 1000));
    // }
  }

  async hideLoadingIndicator() {
    await this.loadingController.dismiss();
  }

  async showQRCode() {
   //TODO:
    if (this.inviteForm.valid) {
      const { anrede, email, vorname, nachname, firma, titel } = this.inviteForm.value;
      // await this.invitationService.showQRCode(email, vorname, nachname, anrede, firma, titel);
      await this.modalController.dismiss();
    }
  }

  async close() {
    await this.modalController.dismiss();
  }
}
