import { Component, OnInit } from '@angular/core';
import {BrandService} from '../../services/brand.service';
import {AppInfoService} from "../../../app/services/app-info.service";

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent implements OnInit {

  appVersion: string;
  constructor(public brand: BrandService,
              public appInfoService: AppInfoService) {
    appInfoService.getAppInfoString().then(
        appInfo => this.appVersion = appInfo
    );
  }

  ngOnInit() {}

}
