import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateTime } from 'luxon';
import {v4 as uuidv4} from 'uuid';
import {timestamp} from "rxjs";
import {App} from "@capacitor/app";
import {Platform} from "@ionic/angular";
import { combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs';
import { Message } from '../models/message';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
      private platform: Platform,
  ) {
  }

  static getStartAndEndOfMonthAsSeconds(jsDate:Date){
    let date= DateTime.fromJSDate(jsDate)
    return {start:date.startOf("month").toSeconds(),end:date.endOf('month').toSeconds()}
  }


  // -- get current UTC time as unix timestamp --
  static getCurrentUtcUnixTimestampAsNumber(): number {

    return DateTime.utc().toSeconds();
  }
  static getCurrentUtcUnixTimestampAsIsoString(): string {
    return DateTime.utc().toISO();
  }

  // -- get current local time as unix timestamp --
  static getCurrentLocalUnixTimestampAsNumber() {
    const utcTimestamp = DateTime.utc().toSeconds();
    const offsetInSeconds = DateTime.now().toLocal().offset * 60;
    return utcTimestamp + offsetInSeconds;
  }
  static getCurrentLocaleUnixTimestampAsIsoString(addHours?: number): string {
    const localTime = DateTime.now().toLocal();
    const updatedTime = addHours ? localTime.plus({ hours: addHours }) : localTime;
    return updatedTime.toISO();
  }

  // -- convert UTC (as unix timestamp) to date and vice versa --
  static convertUtcUnixTimestampToDate(timestamp: number): Date {
    return DateTime.fromSeconds(timestamp, { zone: 'utc' }).toJSDate();
  }
  static convertDateToUtcUnixTimestamp(date: string): number {
    return DateTime.fromISO(date).toUTC().toSeconds();
  }

  static convertUtcUnixTimestampToLocalAsNumber(timestamp: number = 0): number {
    const utcTime = DateTime.fromSeconds(timestamp, { zone: 'utc' });
    return utcTime.toLocal().toSeconds();
  }
  static convertUtcUnixTimestampToLocalIsoString(timestamp: number = 0): string {
    const utcTime = DateTime.fromSeconds(timestamp, { zone: 'utc' });
    return utcTime.toLocal().toISO();
  }

  static convertLocaleUnixTimestampIsoStringToUtcUnixNumber(localDatetime: string): number {
    const localTime = DateTime.fromISO(localDatetime);
    return localTime.toUTC().toSeconds();
  }

  static convertUnixTimestampToCalendarFormatedDate(timestamp: number, format: string): string {
    return DateTime.fromSeconds(timestamp).setLocale('de').toFormat(format);
  }
  static convertStringToCalendarFormatedDate(date: string, format: string): string {
    return DateTime.fromISO(date).setLocale('de').toFormat(format);
  }

  static convertUtcUnixTimestampToLocalTimeString(date: Date) {
    return DateTime.fromJSDate(date).setLocale('de').toFormat('HH:mm');
  }

  static getCurrentLocaleYear(): string {
    return DateTime.now().setLocale('de').toFormat('yyyy');
  }

  // -- moment.js, update to luxon --
  static convertUtcUnixTimestampToLocalString(timestamp: number) {
    return moment.unix(timestamp).locale('de').format('L');
  }

  static convertLocalIsoDateStringToUtcUnixNumber(localDatetime: string): number {
    return moment.parseZone(localDatetime).local(true).utc().unix();
  }

  static addMonthToUnixTimestamp(timestamp: number, months: number = 1): number {
    // convert unix timestamp to moment object
    const initialDate = moment.unix(timestamp);

    // add one month
    const updatedDate = initialDate.add(months, 'months');

    // convert back to unix timestamp
    const updatedTimestamp = updatedDate.unix();

    return updatedTimestamp;
  }

  static getLocalEpochAsDate(): Date {
    return new Date(moment().locale('de').unix() * 1000);
  }

  static getEpochAsDate(): Date {
    return new Date(moment().unix() * 1000);
  }

  static getLocalEpochAsDateFutureOneHour(): Date {
    return new Date(moment().locale('de').add(1, 'hours').unix() * 1000);
  }

  static getEpochFutureOneMonth(): number {
    return moment().add(1, 'months').unix();
  }

  static getEpochFutureOneHour(): number {
    return moment().add(1, 'hours').unix();
  }

  static dateTimeToNumber(datetime: string){
    return moment(datetime).unix();
  }
  static numberToDate(epoch: number){
    return new Date(epoch * 1000);
  }

  static getSeconds(time: any): number {
    if ((time as any)?.seconds) {
      time = (time as any).seconds;
    }

    return time;
  }

  static getCalendarFormatedDay(epoch: number): string{
    if (!epoch) {
      return null;
    }
    return moment(epoch * 1000).locale('de').format('DD.MM.YY');

    //epoch = this.getSeconds(epoch);

    // const timeString = 'h:mm A';
    //const res = moment(epoch * 1000).locale('de').format('LL');
    // https://momentjs.com/docs/#/i18n/
    /*
        LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD/MM/YYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY HH:mm',
          LLLL : 'dddd D MMMM YYYY HH:mm'
     */
    //return res;
  }

  static getCalendarFormatedFullDate(epoch: string): string {
    if (!epoch) {
      return null;
    }

    return moment(epoch).locale('de').format('dddd, D. MMMM YYYY');
  }

  static getCalendarFormatedTimeAsUTC(epoch: string): string {
    if (!epoch) {
      return null;
    }

    const date = moment.utc(epoch);
    return date.locale('de').format('HH:mm');
  }

  static getCalendarFormatedTime(epoch: number): string{
    if (!epoch) {
      return null;
    }

    epoch = this.getSeconds(epoch);

    const res = moment(epoch * 1000).locale('de').format('LT');
    return res;
  }

  static getCalendarDay(epoch: any): string {
    if (!epoch) {
      return null;
    }

    epoch = this.getSeconds(epoch);

    // const timeString = 'h:mm A';
    return moment(epoch * 1000).locale('de').calendar();
  }

  static onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  static linkify(inputText) {

    // tslint:disable-next-line:max-line-length
    //  const emailReges = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    // tslint:disable-next-line:max-line-length
    //  const urlRegrx = /^(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel|[a-z]{2}))|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |\/.,*:;=]|%[a-f\d]{2})*)?$/i;

    // URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    let replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank" style="line-break: anywhere;">$1</a>');

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank" style="line-break: anywhere;">$2</a>');

    // Change email addresses to mailto:: links.
    const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" style="line-break: anywhere;">$1</a>');

    // Change carriage return to <br/>
    const replacePattern4 = /\n/gim;
    replacedText = replacedText.replace(replacePattern4, '<br/>');

    return replacedText;
  }

    static getUUID(): string {
        return uuidv4();
    }
    static getUniqueNumericId(): number {
      return moment().unix();
    }

  static calculateDuration(startDate: number, endDate: number) {
    const start = new Date(startDate * 1000);
    const end = new Date(endDate * 1000);
    const duration = end.getTime() - start.getTime();
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    return {hours, minutes};
  }

  validateEmail(email: string) {
    // tslint:disable-next-line:max-line-length
    const emailReges = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailReges.test(email);
  }

  async asyncFilter(array, asyncPredicate) {
    const results = await Promise.all(array.map(async item => ({
      item,
      isValid: await asyncPredicate(item)
    })));

    return results.filter(result => result.isValid).map(result => result.item);
  }

  static deepEqual(a: any, b: any): boolean {
    if(a === b) {
      return true;
    }
    else if(a === null || typeof a !== "object" ||
        b === null || typeof b !== "object") {
      return false;
    }
    else {
      let keysA = Object.keys(a), keysB = Object.keys(b);
      if (keysA.length != keysB.length) return false;

      for(let k of keysA) {
        if(!keysB.includes(k) || !this.deepEqual(a[k], b[k])) {
          return false;
        }
      }
      return true;
    }
  }

  static deepEqualExclude(a: any, b: any, excludeKeys: string[] = []): boolean {
    if (a === b) {
      return true;
    } else if (a === null || typeof a !== "object" || b === null || typeof b !== "object") {
      return false;
    } else {
      const keysA = Object.keys(a).filter(key => !excludeKeys.includes(key));
      const keysB = Object.keys(b).filter(key => !excludeKeys.includes(key));
      if (keysA.length !== keysB.length) return false;

      for (let k of keysA) {
        if (!keysB.includes(k) || !this.deepEqualExclude(a[k], b[k], excludeKeys)) {
          return false;
        }
      }
      return true;
    }
  }
  static hasMessageChanged(message: Message, originalMessage: Message): boolean {
    const fieldsToNotCompare = ['changed', 'time'];
    return !UtilService.deepEqualExclude(message, originalMessage, fieldsToNotCompare);
  }

  /**
   * Safely stringify an object, handling circular references
   * @param {Object} obj - The object to stringify
   * @param {number} [maxDepth=3] - Maximum depth to stringify
   * @returns {string} Stringified object
   */
  static safeStringify(obj, maxDepth = 3) {
    // Handle primitive values
    if (obj === null || typeof obj !== 'object') {
      return String(obj);
    }

    // Create a replacer function that limits depth
    const seen = new WeakSet();

    return JSON.stringify(obj, function(key, value) {
      // Skip __proto__ properties
      if (key === '__proto__') {
        return '[Prototype]';
      }

      // Get the parent value
      const parent = this;

      // Calculate the current depth by walking up the parent chain
      let currentDepth = 0;
      let currentParent = parent;

      while (currentParent && currentParent !== obj && currentDepth < maxDepth + 1) {
        currentDepth++;
        currentParent = Object.getPrototypeOf(currentParent);
      }

      // Handle circular references
      if (typeof value === 'object' && value !== null) {
        // Treat DOM nodes specially
        if (value instanceof Node) {
          return '[DOM Node]';
        }

        if (seen.has(value)) {
          return '[Circular Reference]';
        }

        // Track this object to catch circular references
        seen.add(value);

        // Limit the depth
        if (currentDepth >= maxDepth) {
          if (Array.isArray(value)) {
            return '[Array with ' + value.length + ' items]';
          } else {
            return '[Object]';
          }
        }
      }

      // For functions, just show that it's a function
      if (typeof value === 'function') {
        return '[Function]';
      }

      return value;
    }, 2);
  }

  async getAppVersion() {
    let version = 'local';
    if (this.platform.is('capacitor')) {
      try {
        const info = await App.getInfo();
        version = info.version;
      } catch (e) {
        console.log(`${e}`);
      }
    }
    return version;
  }

  /**
   * Safely combines multiple observables, handling the empty array case
   * @param observables Array of observables to combine
   * @returns Combined observable that emits an empty array if observables array is empty
   */
  combineLatestSafe<T>(observables: Observable<T>[]): Observable<T[]> {
    if (!observables || observables.length === 0) {
      return of([]);
    }
    
    return combineLatest(observables);
  }
}
