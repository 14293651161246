import {Injectable} from '@angular/core';
import {filter, firstValueFrom, Observable, of} from 'rxjs';
import {FirestoreProxyService} from './firestore-proxy.service';
import {ChatFeed} from '../models/chatFeed';
import {UserService} from './user.service';
import {map, mergeMap} from 'rxjs/operators';
import {UtilService} from './util.service';
import {dbPaths, Globals} from './globals';
import {ChatMessage} from '../models/chatMessage';
import {FeedService} from './feed.service';
import {NavigationService} from './navigation.service';
import {ArbeitsgruppenService} from './arbeitsgruppen.service';
import {VisitService} from './visit.service';
import {ToastController} from '@ionic/angular';
import {LoggingService} from "./logging.service";
import {FirestoreProxyCacheService} from "./firestore-proxy-cache.service";

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    constructor(
        private firestore: FirestoreProxyService,
        private userService: UserService,
        private feedService: FeedService,
        private nav: NavigationService,
        private toastController: ToastController,
        private visitService: VisitService,
        private arbeitsgruppenService: ArbeitsgruppenService,
        private loggingService: LoggingService,
        private firestoreProxyCacheService: FirestoreProxyCacheService
    ) { }

    getMyArbeitsgruppenChats(): Observable<ChatFeed[]> {
        return this.feedService.getCurrentUserArbeitsgruppenFeed(dbPaths.chatsPath);
    }

    getMyChats(supportChat: boolean = false): Observable<ChatFeed[]> {
        return this.firestoreProxyCacheService.query<ChatFeed>(dbPaths.chatsPath,
            [{
                fieldPath: 'archived',
                filterOperator: '!=',
                value: true
            },
                {
                    fieldPath: 'isSupportChat',
                    filterOperator: '==',
                    value: supportChat
                }
                , {
                fieldPath: 'owner',
                filterOperator: '==',
                value: this.userService.getCurrentUserDocId()
            }], [{
                fieldPath: 'changed',
                directionStr: 'desc'
            }]).pipe(mergeMap(x => {
                return this.firestoreProxyCacheService.query<ChatFeed>(dbPaths.chatsPath,
                    [{
                        fieldPath: 'archived',
                        filterOperator: '!=',
                        value: true
                    },
                        {
                            fieldPath: 'isSupportChat',
                            filterOperator: '==',
                            value: supportChat
                        }, {
                        fieldPath: 'participants',
                        filterOperator: 'array-contains',
                        value: this.userService.getCurrentUserDocId()
                    }
                    ], [{
                        fieldPath: 'changed',
                        directionStr: 'desc'
                    }]).pipe(map(y => {
                    const result = x.concat(y)
                        .filter((v, i, a) =>
                            a.findIndex(t => (t.docId === v.docId)) === i
                            && (supportChat || v.isSupportChat !== true)
                        );
                    result.forEach(feed => {
                        feed.viewLocalTimeStampHasUnreadMessages = this.visitService
                            .hasCurrentUserVisitSinceObservable(dbPaths.chatsPath, feed.docId, feed.changed)
                            .pipe(map(hasVisit => !hasVisit));
                    });
                    return result;
                }));
            })
        );
    }

    getArchivedChats(supportChats: boolean = false): Observable<ChatFeed[]> {
        const userId = this.userService.getCurrentUserDocId();

        if (supportChats) {
            return this.firestoreProxyCacheService.query<ChatFeed>(dbPaths.chatsPath, [
                {
                    fieldPath: 'archived',
                    filterOperator: '==',
                    value: true
                },
                {
                    fieldPath: 'isSupportChat',
                    filterOperator: '==',
                    value: true
                }
            ], [{
                fieldPath: 'changed',
                directionStr: 'desc'
            }]).pipe(mergeMap(supportChats => {
                return this.firestoreProxyCacheService.query<ChatFeed>(dbPaths.chatsPath, [
                    {
                        fieldPath: 'archived',
                        filterOperator: '==',
                        value: true
                    },
                    {
                        fieldPath: 'owner',
                        filterOperator: '==',
                        value: userId
                    }
                ], [{
                    fieldPath: 'changed',
                    directionStr: 'desc'
                }]).pipe(mergeMap(ownerChats => {
                    return this.firestoreProxyCacheService.query<ChatFeed>(dbPaths.chatsPath, [
                        {
                            fieldPath: 'archived',
                            filterOperator: '==',
                            value: true
                        },
                        {
                            fieldPath: 'participants',
                            filterOperator: 'array-contains',
                            value: userId
                        }
                    ], [{
                        fieldPath: 'changed',
                        directionStr: 'desc'
                    }]).pipe(map(participantChats => {
                        const result = supportChats.concat(ownerChats, participantChats)
                            .filter((v, i, a) => a.findIndex(t => (t.docId === v.docId)) === i);
                        result.forEach(feed => {
                            feed.viewLocalTimeStampHasUnreadMessages = this.visitService
                                .hasCurrentUserVisitSinceObservable(dbPaths.chatsPath, feed.docId, feed.changed)
                                .pipe(map(hasVisit => !hasVisit));
                        });
                        return result;
                    }));
                }));
            }));
        } else {
            return this.firestoreProxyCacheService.query<ChatFeed>(dbPaths.chatsPath, [
                {
                    fieldPath: 'archived',
                    filterOperator: '==',
                    value: true
                },
                {
                    fieldPath: 'owner',
                    filterOperator: '==',
                    value: userId
                }
            ], [{
                fieldPath: 'changed',
                directionStr: 'desc'
            }]).pipe(mergeMap(ownerChats => {
                return this.firestoreProxyCacheService.query<ChatFeed>(dbPaths.chatsPath, [
                    {
                        fieldPath: 'archived',
                        filterOperator: '==',
                        value: true
                    },
                    {
                        fieldPath: 'participants',
                        filterOperator: 'array-contains',
                        value: userId
                    }
                ], [{
                    fieldPath: 'changed',
                    directionStr: 'desc'
                }]).pipe(map(participantChats => {
                    const result = ownerChats.concat(participantChats)
                        .filter((v, i, a) => a.findIndex(t => (t.docId === v.docId)) === i);
                    result.forEach(feed => {
                        feed.viewLocalTimeStampHasUnreadMessages = this.visitService
                            .hasCurrentUserVisitSinceObservable(dbPaths.chatsPath, feed.docId, feed.changed)
                            .pipe(map(hasVisit => !hasVisit));
                    });
                    return result;
                }));
            }));
        }
    }

    getChat(chatId: string): Observable<ChatFeed> {
        return this.feedService.getFeed(dbPaths.chatsPath, chatId);
    }

    getMessages(chatId: string): Observable<ChatMessage[]> {
        return this.feedService.getMessagesStateChange(dbPaths.chatsPath, chatId, null, false, true);
    }

    getMessage(chatId: string, docId): Observable<ChatMessage> {
        return this.firestore.getDocument<ChatMessage>(dbPaths.chatsPath + '/' + chatId + '/' + Globals.messagesCollectionName, docId);
    }

    async sendMessage(chatId: string, messageText: string): Promise<string> {
        const chat = await firstValueFrom(this.getChat(chatId));
        if (chat?.archived) {
            await this.firestore.mergeDocument(chatId, dbPaths.chatsPath, {archived: false});
            const toast = await this.toastController.create({
                message: 'Chat erfolgreich wiederhergestellt',
                duration: 2000,
                color: 'success'
            });
            await toast.present();
        }

        const message: ChatMessage = {
            deleted: false,
            published: true,
            from: this.userService.getCurrentUserName(),
            content: messageText,
            picture: null,
            time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
            createdBy: this.userService.getCurrentUserUid(),
            changed: UtilService.getCurrentUtcUnixTimestampAsNumber(),
        };

        const createdMessage = await this.feedService.createOrUpdateMessage(dbPaths.chatsPath, chatId, message);
        return createdMessage.docId;
    }

    async sendPhoto(chatId: string, url: string, format: string = 'jpg'): Promise<string> {
        const chat = await firstValueFrom(this.getChat(chatId));
        if (chat?.archived) {
            await this.firestore.mergeDocument(chatId, dbPaths.chatsPath, {archived: false});
            const toast = await this.toastController.create({
                message: 'Chat erfolgreich wiederhergestellt',
                duration: 2000,
                color: 'success'
            });
            await toast.present();
        }

        const message: ChatMessage = {
            deleted: false,
            from: this.userService.getCurrentUserName(),
            content: '',
            picture: {
                url,
                format,
            },
            time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
            createdBy: this.userService.getCurrentUserUid(),
            changed: UtilService.getCurrentUtcUnixTimestampAsNumber(),
            published: true,
        };

        const createdMessage = await this.feedService.createOrUpdateMessage(dbPaths.chatsPath, chatId, message);
        return createdMessage.docId;
    }

    async createChat(contactDocIds: string[], navigateToChat: boolean = true) {

        const chat: ChatFeed = {
            arbeitsgruppe: null,
            deleted: false,
            hidden: false,
            time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
            isArbeitsgruppenFeed: false,
            name: null,
            participants: contactDocIds,
            isSupportChat: false,
            owner: this.userService.getCurrentUserDocId(),
            archived: false,
        };
        chat.defaultName = await this.getChatDefaultName(chat);

        const chatId = await this.feedService.createOrUpdateFeed(dbPaths.chatsPath, chat);
        if (navigateToChat) {
            await this.nav.navigateToChat(chatId);
        }
    }
    async leaveAndDeleteChat(chatDocId: string): Promise<void> {
        await this.firestore.removeValueFromArray(chatDocId, dbPaths.chatsPath, 'participants', this.userService.getCurrentUserDocId());
        return this.firestore.mergeDocument(chatDocId, dbPaths.chatsPath, {deleted: true});
    }

    async addParticipants(chat: ChatFeed, contactDocIds: string[]): Promise<void> {
        chat.participants = [...chat.participants, ...contactDocIds];
        chat.defaultName = await this.getChatDefaultName(chat);
        await this.firestore.updateDocument(dbPaths.chatsPath, chat.docId, chat);
    }

    async removeParticipants(chat: ChatFeed, userDocId: string): Promise<void> {
        chat.participants = chat.participants.filter(id => id !== userDocId);
        chat.defaultName = await this.getChatDefaultName(chat);
        await this.firestore.updateDocument(dbPaths.chatsPath, chat.docId, chat);
    }

    getAllSupportChats(): Observable<ChatFeed[]> {
        return this.firestore.query<ChatFeed>(dbPaths.chatsPath,
            [
                {
                    fieldPath: 'isSupportChat',
                    filterOperator: '==',
                    value: true
                },
                {
                    fieldPath: 'archived',
                    filterOperator: '!=',
                    value: true
                }
            ], [{
                fieldPath: 'changed',
                directionStr: 'desc'
            }]).pipe(map(result => {
            result.forEach(feed => {
                feed.viewLocalTimeStampHasUnreadMessages = this.visitService
                    .hasCurrentUserVisitSinceObservable(dbPaths.chatsPath, feed.docId, feed.changed)
                    .pipe(map(hasVisit => !hasVisit));
            });
            return result;
        }));
    }

    async getSupportChat(context: string): Promise<string | null> {
        if (context === '/') {
            context = window.location.pathname;
        }
        const existingSupportChat = await firstValueFrom(this.firestore.query<ChatFeed>(dbPaths.chatsPath,
            [
                {
                    fieldPath: 'isSupportChat',
                    filterOperator: '==',
                    value: true
                },
                {
                    fieldPath: 'context',
                    filterOperator: '==',
                    value: context
                },
                {
                    fieldPath: 'owner',
                    filterOperator: '==',
                    value: this.userService.getCurrentUserDocId()
                }
            ]));
        if (existingSupportChat.length > 0) {
            return existingSupportChat[0].docId;
        } else {
            return null;
        }
    }

    async createSupportChat(context: string, title: any): Promise<string> {
        const chat: ChatFeed = {
            hidden: false,
            arbeitsgruppe: null,
            deleted: false,
            time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
            isSupportChat: true,
            owner: this.userService.getCurrentUserDocId(),
            context,
            name: title,
            participants: [],
            isArbeitsgruppenFeed: false,
            archived: false
        };
        return this.feedService.createOrUpdateFeed(dbPaths.chatsPath, chat);
    }

    async archiveChat(chat: ChatFeed): Promise<void> {
        return this.firestore.mergeDocument(chat.docId, dbPaths.chatsPath, {archived: !chat.archived});
    }

    async deleteChat(chatId: string): Promise<void> {
        return this.firestore.deleteDocument(dbPaths.chatsPath, chatId);
    }

    async updateChat(chat: ChatFeed): Promise<void> {
        await this.firestore.updateDocument(dbPaths.chatsPath, chat.docId, chat);
    }

    async getChatDefaultName(chat: ChatFeed): Promise<string> {
        const contacts = [...chat.participants ?? [], chat.owner];

        if (!chat.participants || chat.participants.length === 0) {
            return this.userService.getCurrentUserName();
        }
        try {
            const names = await Promise.all(
                contacts.map(async (docId) => {
                    try {
                        const user = await this.userService.getUserByDocId(docId);
                        return user.vorname + ' ' + user.nachname;
                    }catch (error) {
                        this.loggingService.logError(this, error);
                        return '';
                    }
                }));

            return names.join(', ');
        } catch (error) {
            this.loggingService.logError(this, error);
            return '';
        }
    }

    async deleteMessage(chatId: string, messageId: string) {
        return this.firestore.deleteDocument(dbPaths.chatsPath + '/' + chatId + '/' + Globals.messagesCollectionName, messageId);
    }

    determineItemIsSupportChat(item: any): boolean {
        // TODO: QUICKHACK:Diese Methode ist nicht die beste und zuverlässigste Art diese Frage zu beantworten.
        if(item.isSupportChat !== null && item.isSupportChat !== undefined && item.isSupportChat === true) {
            return true;
        }

        if(item.itemType === 'chat' && item.arbeitsgruppe < 1 && item.participants?.length === 0) {
            return true;
        }

        return false;
    }
}
