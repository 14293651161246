import { Injectable } from '@angular/core';
import {Globals} from './globals';
import {arrayUnion} from '@angular/fire/firestore';
import {UtilService} from './util.service';
import {firstValueFrom, Observable, of, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {FirestoreProxyService} from './firestore-proxy.service';
import {UserService} from './user.service';
import {FirestoreProxyCacheService} from './firestore-proxy-cache.service';

@Injectable({
  providedIn: 'root'
})
export class VisitService {
  constructor(
      private firestore: FirestoreProxyService,
      private userService: UserService,
      private firestoreProxyCacheService: FirestoreProxyCacheService
  ) { }

  async setVisitForCurrentUser(path: string, docId: string) {
    // TODO : Hidden User Filtern. TODO: als Feature Toggle einbauen.
    if (this.userService.currentUser$.getValue()?.hidden) {return; }
    if (!path || !docId) { return; }
    const fullPath = path + '/' + docId + '/' + Globals.visitsCollectionName;
    const userId = this.userService.getCurrentUserUid();
    console.log('setVisitForCurrentUser', fullPath, docId);
    await this.firestore.mergeDocument(userId, fullPath, {
      time: arrayUnion(UtilService.getCurrentUtcUnixTimestampAsNumber())
    });
  }

  hasCurrentUserVisitSinceObservable(path: string, docId: string, timestamp: number): Observable<boolean> {
    const visitsCollectionPath = `${path}/${docId}/${Globals.visitsCollectionName}`;

    return this.userService.currentUser$.pipe(switchMap(user => {
      if (!user || !user.uid) {
        return of(false);
      }

      // Use the DocumentCacheService to get and cache the document
      return this.firestoreProxyCacheService.getDocumentObservableIfExistOtherwiseReturnNullAndStayObserved<{time: number[]}>(
        visitsCollectionPath, 
        user.uid,
        timestamp?.toString()
      ).pipe(
        map(x => x && x.time && x.time.some(t => t >= timestamp))
      );
    }));
  }

  getVisitCount(path: string): Observable<number> {
    const fullPath = path + '/' + Globals.visitsCollectionName;
    return this.firestore.count(fullPath);
  }
}
