// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-name {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.col-vertical-center {
  /*
  align-self: center;
  */
}

ion-title {
  padding: 0;
}

img {
  height: 29px;
}

ion-title.md img {
  margin: 8px 0 6px 0;
  height: 29px;
}

ion-title.ios img {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/custom-toolbar/custom-toolbar.component.scss"],"names":[],"mappings":"AAIA;EACE,8BAAA;EAAA,2BAAA;EAAA,sBAAA;AAHF;;AAMA;EACE;;GAAA;AADF;;AAMA;EACE,UAAA;AAHF;;AAMA;EACE,YAAA;AAHF;;AAMA;EACE,mBAAA;EACA,YAAA;AAHF;;AAMA;EACE,SAAA;AAHF","sourcesContent":[".verbandsapp-main-toolbar {\n\n}\n\n.user-name {\n  min-width: max-content;\n}\n\n.col-vertical-center {\n  /*\n  align-self: center;\n  */\n}\n\nion-title {\n  padding: 0;\n}\n\nimg {\n  height: 29px;\n}\n\nion-title.md img {\n  margin: 8px 0 6px 0;\n  height: 29px;\n}\n\nion-title.ios img {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
