import {Subject} from 'rxjs';

export class FileDownloadTask {

    urlReceived = new Subject<string>();
    error = new Subject<string>();

    constructor(private promise: Promise<any>) {

        promise.then((url) => {
            this.urlReceived.next(url);
        })
            .catch((error) => {
                if (error.code !== 'storage/canceled') { // User cancelled
                    this.error.next(error.message);
                }
            });
    }
}
