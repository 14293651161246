import {inject, NgModule} from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthzGuard} from './guards/authz.guard';
import { AuthnGuardService } from './guards/authn.guard';
import { InvitedGuard } from './guards/invited.guard';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    data: {
      name: 'Login',
    },
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    data: {
      name: 'Login',
    },
    path: 'login/:email',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    data: {
      name: 'Registrieren',
    },
    path: 'register/:email',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    data: {
      name: 'Registrieren',
    },
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'finish-signup/:token/:docId/:tenantId',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    data: {
      title: 'Start',
      url: '/start',
      icon: 'home'
    },
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, () =>  inject(InvitedGuard).canActivate()]
  },
  {
    path: 'start/:contentId',
    loadChildren: () => import('./pages/feed-content/feed-content.module').then( m => m.FeedContentPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    data: {
      title: 'Suche',
      url: '/search',
      icon: 'search',
      feature: 'search'
    },
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    data: {
      title: 'Favoriten',
      url: '/favorites',
      icon: 'star',
      feature: 'favorites'
    },
    path: 'favorites',
    loadChildren: () => import('./pages/favorites/favorites.module').then( m => m.FavoritesPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    data: {
      title: 'Rundschreiben',
      url: '/news',
      icon: 'newspaper',
      feature: 'news'
    },
    path: 'news',
    loadChildren: () => import('./pages/news-list/news-list.module').then(m => m.NewsListPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'news/:feedId',
    loadChildren: () => import('./pages/news-feed/news-feed.module').then( m => m.NewsFeedPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'news/:feedId/feed/:docId',
    loadChildren: () => import('./pages/news-feed-content/news-feed-content.module').then( m => m.NewsFeedContentPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    data: {
      name: 'Rundscheiben erstellen',
      role: ['redaktion'],
    },
    path: 'news/:feedId/feed',
    loadChildren: () => import('./pages/news-feed-content/news-feed-content.module').then( m => m.NewsFeedContentPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), InvitedGuard, AuthzGuard]
  },
  {
    data: {
      title: 'Rückfragen',
      url: '/chats',
      icon: 'chatbubble-ellipses',
      feature: 'chats'
    },
    path: 'chats',
    loadChildren: () => import('./pages/chats/chats.module').then( m => m.ChatsPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'chats/:id',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'chats/:id/details',
    loadChildren: () => import('./pages/chat-details/chat-details.module').then( m => m.ChatDetailsPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'documents/:feedId',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'documents/:feedId/folder/:folderId',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), InvitedGuard]
  },
  {
    path: 'documents/:feedId/:docId',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    data: {
        title: 'Dokumente',
        url: '/documents',
        icon: 'document',
        feature: 'documents'
    },
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard]
  },
  {
    data: {
        title: 'Bibliothek',
        url: '/wiki',
        icon: 'book',
        feature: 'wiki'
    },
    path: 'wiki',
    loadChildren: () => import('./pages/wiki/wiki.module').then( m => m.WikiPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'wiki/:feedId',
    loadChildren: () => import('./pages/wiki-document/wiki-document.module').then( m => m.WikiDocumentPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'wiki/:feedId/:docId',
    loadChildren: () => import('./pages/wiki-document/wiki-document.module').then( m => m.WikiDocumentPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    data: {
        title: 'Umfragen',
        url: '/surveys',
        icon: 'list',
        feature: 'surveys'
    },
    path: 'surveys',
    loadChildren: () => import('./pages/surveys/surveys.module').then( m => m.SurveysPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'surveys/:feedId',
    loadChildren: () => import('./pages/surveys/surveys.module').then( m => m.SurveysPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'surveys/:feedId/feed',
    loadChildren: () => import('./pages/survey/survey.module').then( m => m.SurveyPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'surveys/:feedId/feed/:surveyId',
    loadChildren: () => import('./pages/survey/survey.module').then( m => m.SurveyPageModule),
    // keine Guards, weil es die Option des öffentlichen Zugangs gibt. Siehe auch Rules und 'permission-denied' in der survey.page
  },
  {
    path: 'survey-analyse/:feedId/:surveyId',
    loadChildren: () => import('./pages/survey-analyse/survey-analyse.module').then( m => m.SurveyAnalysePageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    data: {
      title: 'Termine',
      url: '/calendars',
      icon: 'calendar',
      feature: 'calendar'
    },
    path: 'calendars',
    loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'calendar/:id',
    loadChildren: () => import('./pages/calendar-event/calendar-event.module').then(m => m.CalendarEventPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar-event/calendar-event.module').then(m => m.CalendarEventPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    data: {
      title: 'Kontakte',
      url: '/contacts',
      icon: 'people',
      feature: 'contacts'
    },
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then( m => m.ContactsPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    // data: {
    //   title: 'Profil',
    //   url: '/profile',
    //   icon: 'person',
    //   feature: 'profile'
    // },
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'contacts/:docId',
    loadChildren: () => import('./pages/contact-details/contact-details.module').then( m => m.ContactDetailsPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    data: {
      title: 'Admin - Gruppen',
      url: '/admin-working-groups',
      icon: 'apps',
      role: ['admin'],
      // platform: 'desktop',
      feature: 'adminWorkingGroups'
    },
    path: 'admin-working-groups',
    loadChildren: () => import('./pages/admin-working-groups/admin-working-groups.module').then( m => m.AdminWorkingGroupsPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard, AuthzGuard]
  },
  {
    data: {
      title: 'Admin - Profile',
      url: '/admin-profile',
      icon: 'people',
      role: ['admin', 'redaktion'],
      // platform: 'desktop',
      feature: 'adminProfile'
    },
    path: 'admin-profile',
    loadChildren: () => import('./pages/admin-profile/admin-profile.module').then( m => m.AdminProfilePageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard, AuthzGuard]
  },
  {
    data: {
      role: ['admin'],
      // platform: 'desktop'
    },
    path: 'profile/:uid',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard, AuthzGuard]
  },
  {
    data: {
        title: 'Admin - Benutzer Import',
        url: '/admin-user-import',
        icon: 'cloud-upload',
        role: ['admin'],
        platform: 'desktop',
        feature: 'adminUserImport'
    },
    path: 'admin-user-import',
    loadChildren: () => import('./pages/admin-user-import/admin-user-import.module').then( m => m.AdminUserImportPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), InvitedGuard, AuthzGuard]
  },
  {
    data: {
      title: 'root - Administration',
      url: '/root',
      icon: 'terminal',
      role: ['root'],
      // platform: 'desktop'
    },
    path: 'root',
    loadChildren: () => import('./pages/root/root.module').then( m => m.RootPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), InvitedGuard, AuthzGuard]
  },
  {
    path: 'root/:tenantId',
    loadChildren: () => import('./pages/root-tenant/root-tenant.module').then( m => m.RootTenantPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), InvitedGuard, AuthzGuard]
  },
  {
    path: 'contact-selector',
    loadChildren: () => import('./pages/contact-selector/contact-selector.module').then( m => m.ContactSelectorPageModule),
    canActivate: [() =>  inject(AuthnGuardService).canActivate(), AuthzGuard, InvitedGuard]
  },
  {
    path: 'calendar-day',
    loadChildren: () => import('./pages/calendar-day/calendar-day.module').then( m => m.CalendarDayPageModule)
  },
  { path: '403', component: ForbiddenComponent },
  { path: '404', component: ForbiddenComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
