import { Injectable } from '@angular/core';
import {Arbeitsgruppe} from '../models/arbeitsgruppe';
import {Observable, of} from 'rxjs';
import {dbPaths, Globals} from './globals';
import {map} from 'rxjs/operators';
import {FirestoreProxyService, Query} from './firestore-proxy.service';
import {ArbeitsgruppenService} from './arbeitsgruppen.service';
import {FeedService} from './feed.service';
import {NavigationService} from './navigation.service';
import {UserService} from './user.service';
import {WikiFeed} from '../models/wikiFeed';
import {WikiDocument} from '../models/wikiDocument';
import {AlertController, ModalController} from "@ionic/angular";
import {WikiLinkSelectorComponent} from "../components/wiki-link-selector/wiki-link-selector.component";
import {QuillEditorComponent} from "ngx-quill";
import {QuillEditorService} from "./quill-editor.service";

@Injectable({
  providedIn: 'root'
})
export class WikiService {

  constructor(
      private feedService: FeedService,
      private firestore: FirestoreProxyService,
      private arbeitsgruppenService: ArbeitsgruppenService,
      private nav: NavigationService,
      private userService: UserService,
      private alertController: AlertController,
      private modalController: ModalController,
      private quillEditorService:QuillEditorService
  ) { }

  getDoc(docId: string) {
    return this.firestore.getDocumentConnectedObservable<WikiDocument>(
        dbPaths.wikiPath , docId);
  }

  getDocs( documentStatusVisible= true): Observable<WikiDocument[]> {
    return this.firestore.query(dbPaths.wikiPath, [
        {
            fieldPath: 'listed',
            filterOperator: '==',
            value: documentStatusVisible
        }
    ]);
  }

  getAllDocs( ): Observable<WikiDocument[]> {
      return this.firestore.query(dbPaths.wikiPath, []);
  }

  async openWikiPage(docId: string) {
    await this.nav.navigateToWikiDocument(docId);
  }

  async createOrUpdateWikiDocument(item: WikiDocument, originalMessage: WikiDocument): Promise<WikiDocument> {
      const hasChanges = await this.feedService.checkIfMessageHasChangesAndAskUserToSaveIfNot(item, originalMessage);
      if (!hasChanges) {
          return item;
      }
    if (item.docId){
      await this.firestore.updateDocument(dbPaths.wikiPath, item.docId, item);
      return item;
    } else {
      const {docId, ...itemWithoutId} = item;
      return await this.firestore.addDocument(dbPaths.wikiPath, itemWithoutId);
    }
  }

  async delete(c: WikiDocument) {
    const alert = await this.alertController.create({
      header: 'Eintrag löschen',
      message: 'Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?',
      buttons: [
        {
          text: 'Nein',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Ja',
          handler: async () => {
              if (c.contentUrl){
                  await this.quillEditorService.deleteQuillDocument(c.contentUrl);
              }

            await this.firestore.deleteDocument(dbPaths.wikiPath, c.docId);
            await this.nav.navigateToWikiRoot();
          }
        }
      ]
    });
    await alert.present();
  }

  convertRouterLinkToHref(htmlString) {
    // Create a temporary DOM element to parse the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // Find all anchor (<a>) elements that have a 'routerlink' attribute
    const anchorTags = tempDiv.querySelectorAll('a[routerlink]');

    // Loop over each anchor tag and move 'routerlink' value to 'href'
    anchorTags.forEach(anchor => {
      const routerLinkValue = anchor.getAttribute('routerlink');
      if (routerLinkValue) {
        anchor.setAttribute('href', routerLinkValue);
        anchor.removeAttribute('routerlink'); // Optionally remove the 'routerlink' attribute
      }
    });

    // Return the modified HTML as a string, no need to worry about the div after this
    return tempDiv.innerHTML;
  }

  async openArticleList(editor: QuillEditorComponent) {
        const modal = await this.modalController.create({
            component: WikiLinkSelectorComponent
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (!data.cancel) {
            const { docId, title } = data;
            const index = editor.quillEditor.selection.savedRange.index;
            if (index !== undefined) {
                editor.quillEditor.insertText(index, title, 'user');
                editor.quillEditor.setSelection(index, title.length);
                editor.quillEditor.theme.tooltip.edit('link', QuillEditorService.isUrlWikiDoc + docId);
                editor.quillEditor.theme.tooltip.save();
            }
        }
    }
}
