import { Injectable } from '@angular/core';

import {
    ActionPerformed,
    PushNotifications, PushNotificationSchema,
    Token
} from '@capacitor/push-notifications';
import {LocalNotifications} from '@capacitor/local-notifications';

import {Platform} from '@ionic/angular';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {User} from "../models/user";
import {UserSettingsService} from "./user-settings.service";



@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private platform: Platform,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private router: Router,
        ) {
        if (this.isUsingPush()) {
            this.initPush();
        }

        this.userService.currentUser$.subscribe(async user => {
            if(user){
                await this.updateUserFcmToken(user);
            }
        });
    }

    fcmToken: string;

    private isUsingPush() {
        return this.platform.is('capacitor');
    }

    private initPush() {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        if (!this.isUsingPush()) {
            return;
        }

        PushNotifications.requestPermissions().then( async result => {
            if (result.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                await PushNotifications.register();
            } else {
                console.log('No permission for push granted. ' + result.receive);
            }
        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            async (token: Token) => {
                console.log('Push registration success, token: ' + token.value);
                this.fcmToken = token.value;

                // await this.authService.Init();
                if (this.userService.getCurrentUserUid()) {
                    await this.updateUserFcmToken(this.userService.currentUser$.getValue());
                }
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                console.log('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            async (notification: PushNotificationSchema) => {
                console.log('Push received: ' + JSON.stringify(notification));

                if (this.router.url.indexOf(notification.data.topic) === -1) {
                    await LocalNotifications.schedule(
                        {
                            notifications: [
                                {
                                    id: 1,
                                    title: notification.title,
                                    body: notification.body
                                }]
                        });
                } else {
                    console.log('Notification received while on page. Ignoring.');
                }
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            async (notification: ActionPerformed) => {

                try {
                    console.log('Push action performed: ' + JSON.stringify(notification));

                    const url = notification.notification.data.url;

                    this.router.navigateByUrl(url).catch(err => {
                        console.error('Navigation error:', err);
                    });
                } catch (e) {
                    console.error('Invalid URL:', e);
                }

            }
        );
    }


    resetBadgeCount() {
        try {
            if(this.isUsingPush()) {
                PushNotifications.removeAllDeliveredNotifications();
            }
            const user = this.userService.currentUser$.getValue();

            if (!user || !user.uid) {
                return;
            }
            this.userService.resetUnreadCount(user.docId);

        }finally {
        }
    }

    async updateUserFcmToken(user: User) {
        if(this.isUsingPush() && this.fcmToken) {
            await this.userSettingsService.updateFcmToken(user.docId, this.fcmToken);
        }
    }

    async removeCurrentFcmToken(user: User) {
        if(this.isUsingPush() && this.fcmToken) {
            // This should be called when the user logs out
            await this.userSettingsService.removeFcmToken(user.docId, this.fcmToken);
            this.fcmToken = null;
        }
    }
}
