import {Component, Input, OnInit} from '@angular/core';
import {Message} from "../../models/message";

@Component({
  selector: 'app-show-not-publisched-icon',
  templateUrl: './show-not-publisched-icon.component.html',
  styleUrls: ['./show-not-publisched-icon.component.scss'],
})
export class ShowNotPublischedIconComponent  implements OnInit {
  @Input() xp: number=0;
  @Input() yp: number=0;
  @Input() message!: Message;


  constructor() { }

  ngOnInit() {}

}
