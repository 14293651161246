// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.firstname {
  display: inline;
}

.lastname {
  display: inline;
  font-weight: bold;
}

.company {
  font-size: x-small;
}

.select {
  padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/contact-list/contact-list.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".firstname {\n  display: inline;\n}\n\n.lastname {\n  display: inline;\n  font-weight: bold;\n}\n\n.company {\n  font-size: x-small;\n}\n\n.select {\n  padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
