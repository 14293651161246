import {Injectable} from '@angular/core';
import {FileDownloadTask} from '../models/FileService/fileDownloadTask';
import {v4 as uuidv4} from 'uuid';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import 'firebase/compat/storage';
import {FilePicker, PickedFile, PickFilesResult} from '@capawesome/capacitor-file-picker';
import {AlertController, LoadingController, Platform, ToastController} from "@ionic/angular";
import {Document} from "../models/document";
import {UtilService} from "./util.service";
import {FeedService} from "./feed.service";
import {TenantService} from "./tenant.service";
import {UserService} from "./user.service";
import {LoggingService} from "./logging.service";
import {Capacitor} from '@capacitor/core';
import {Filesystem, ReadFileResult, Directory} from '@capacitor/filesystem';
import {dbPaths, Globals} from "./globals";
import {environment} from "../../brand/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(
        private storage: AngularFireStorage,
        private loadingCtrl: LoadingController,
        private alertController: AlertController,
        private tenantService: TenantService,
        private userService: UserService,
        private feedService: FeedService,
        private loggingService: LoggingService,
        private platform: Platform,
        private toast: ToastController
    ) {
    }

    async uploadBase64(data: any, format: string): Promise<string> {
        const uuid = uuidv4();

        const storageRef = this.storage.ref('/images');
        const fileRef = storageRef.child(uuid);
        const task = await fileRef.putString(data, 'base64', {contentType: 'image/' + format});
        const url = await task.ref.getDownloadURL();
        console.log('Uploaded a base64 string!');
        return url;
    }

    async uploadBlob(data: Blob, path: string, name: string, referenceItemPath?: string, referenceItemId?: string,fileUid?: string, prependTenant = true): Promise<{url: string,uuid: string}> {
        let uuid = uuidv4();
        if (fileUid){
            uuid = fileUid;
        }

        path = prependTenant ? this.prependTenant(path) : path;
        const storageRef = this.storage.ref(path);
        const fileRef = storageRef.child(uuid);
        const task = await fileRef.put(data);
        fileRef.updateMetadata({
            customMetadata: {
                FileName: name,
                referenceItemPath,
                referenceItemId
            }
        });
        const url = await task.ref.getDownloadURL();
        this.loggingService.log(this, 'Uploaded!', {
            url,
            uuid
        });
        return {url:url, uuid:uuid};
    }

    async pickFiles(existingResult?: PickFilesResult): Promise<PickFilesResult> {
        const result = await FilePicker.pickFiles({
            types: [
                'image/png',
                'image/jpeg',
                'image/jpg',
                'application/pdf',
                'application/msword',
                'application/vnd.ms-excel',
                'application/vnd.ms-powerpoint'
            ],
            readData: true,
        });

        if (!result.files || result.files.length === 0) {
            return existingResult || null;
        }

        if (!existingResult) {
            return result;
        }

        return {
            files: [...existingResult.files, ...result.files]
        };
    }

    /**
     * Upload a file to Firebase Storage
     * @param file File to upload
     * @param storagePath Firebase storage path
     * @param referenceItemPath Reference path
     * @param referenceItemId Reference ID
     * @param isBatchUpload Whether this is part of a batch upload
     * @returns Promise with upload result including url, metadata and file info
     */
    async uploadFile(
        file: PickedFile,
        storagePath: string,
        referenceItemPath: string = null,
        referenceItemId: string = null,
        isBatchUpload: boolean = false
    ): Promise<{
        url: string,
        uuid: string,
        fileSize?: number,
        fileType?: string,
        fileLastModified?: number
    }> {
        // Handle file upload based on what's available
        let uploadResult: {url: string, uuid: string};
        let fileSize;
        let fileType;
        let fileLastModified;
        
        // Check if we're on web or native platform
        const isNativePlatform = Capacitor.isNativePlatform();
        
        // For web platform - use blob directly
        if (!isNativePlatform && file.blob) {
            fileSize = file.blob.size;
            fileType = file.blob.type;
            
            // Try to access lastModified if it exists on the blob
            if ('lastModified' in file.blob) {
                fileLastModified = file.blob.lastModified;
            }
            
            uploadResult = await this.uploadBlob(
                file.blob,
                storagePath, 
                file.name, 
                referenceItemPath, 
                referenceItemId
            );
        } 
        // For native platforms (iOS/Android)
        else if (isNativePlatform && (file.data)) {
            // Get file stats if possible
            try {
                // We may not have direct access to file size/type on native platforms
                // but we can use the extension to estimate the type
                const ext = file.name.split('.').pop().toLowerCase();
                if (ext === 'pdf') fileType = 'application/pdf';
                else if (['jpg', 'jpeg'].includes(ext)) fileType = 'image/jpeg';
                else if (ext === 'png') fileType = 'image/png';
                else if (['doc', 'docx'].includes(ext)) fileType = 'application/msword';
                else if (['xls', 'xlsx'].includes(ext)) fileType = 'application/vnd.ms-excel';
                else if (['ppt', 'pptx'].includes(ext)) fileType = 'application/vnd.ms-powerpoint';
                else fileType = 'application/octet-stream';
                
                // For file modification date and size, we may need platform-specific plugins
                // Default to current time if not available
                fileLastModified = Date.now();
            } catch (e) {
                console.error('Error getting file stats:', e);
            }
            
            // Use the native file path for upload with the new uploadFromPath method
            uploadResult = await this.uploadFromData(
                file.data,
                storagePath, 
                file.name, 
                referenceItemPath, 
                referenceItemId
            );
        }
        // Fallback for web if file.blob is not available
        else if (file.data && !isNativePlatform) {
            // For web fallback, use the data as ArrayBuffer
            uploadResult = await this.uploadFromData(
                file.data,
                storagePath, 
                file.name, 
                referenceItemPath, 
                referenceItemId
            );
            
            // Set some default metadata
            fileType = 'application/octet-stream'; // Default MIME type
            fileLastModified = Date.now();
        }
        // If we can't handle this file type, throw an error
        else {
            throw new Error('Unable to process file: No valid file data or path available');
        }
        
        // If uploading failed, throw an error
        if (!uploadResult || !uploadResult.url) {
            throw new Error('Failed to upload file');
        }
        
        return {
            url: uploadResult.url,
            uuid: uploadResult.uuid,
            fileSize,
            fileType,
            fileLastModified
        };
    }

    async uploadAttachments(files: PickFilesResult, referenceId: string, referenceItemPath: string = null) {
        if (!files.files || files.files.length === 0) {
            return;
        }

        const storagePath = `${Globals.storeAttachmentsPath}${referenceId}`;

        const loading = await this.loadingCtrl.create({
            message: `0/${files.files.length} Dateien hochgeladen...`
        });
        await loading.present();

        const results: { success: Document[], failed: Array<{ name: string, error: string }> } = {
            success: [],
            failed: []
        };

        try {
            for (let i = 0; i < files.files.length; i++) {
                const file = files.files[i];
                loading.message = `${i + 1}/${files.files.length} Dateien werden hochgeladen...`;

                try {
                    // Use the new uploadFile method
                    const uploadResult = await this.uploadFile(
                        file,
                        storagePath,
                        referenceItemPath,
                        referenceId,
                        true
                    );

                    const document: Document = {
                        // Message properties
                        content: file.name,
                        createdBy: this.userService.getCurrentUserUid(),
                        deleted: false,
                        time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
                        changed: UtilService.getCurrentUtcUnixTimestampAsNumber(),
                        published: true,

                        // Document specific properties
                        name: file.name,
                        url: uploadResult.url,
                        archived: false,
                        owner: this.userService.getCurrentUserUid(),
                        referenceItemId: referenceId,
                        referenceItemPath: referenceItemPath,
                        orderIndex: UtilService.getCurrentUtcUnixTimestampAsNumber(),

                        // File metadata
                        fileSize: uploadResult.fileSize || file.size,
                        fileType: uploadResult.fileType || file.mimeType,
                        fileLastModified: typeof uploadResult.fileLastModified === 'number' ? uploadResult.fileLastModified : 
                            (uploadResult.fileLastModified ? Number(uploadResult.fileLastModified) : UtilService.getCurrentUtcUnixTimestampAsNumber()),
                    };

                    results.success.push(document);
                } catch (error) {
                    console.error(`Error uploading file #2 ${file.name}:`, error);
                    results.failed.push({
                        name: file.name,
                        error: error.message || 'Unbekannter Fehler'
                    });
                }
            }

            await this.showUploadResult(results);
            return results.success;
        } catch (error) {
            console.error('Fehler beim Upload-Prozess:', error);
            const errorAlert = await this.alertController.create({
                header: 'Upload fehlgeschlagen',
                message: 'Beim Hochladen der Dateien ist ein Fehler aufgetreten.',
                buttons: ['OK']
            });
            await errorAlert.present();
            throw error;
        } finally {
            await loading.dismiss();
        }
    }

    private async showUploadResult(results: { success: Document[], failed: Array<{ name: string, error: string }> }) {
        if (results.failed.length === 0) {
            const successAlert = await this.toast.create({
                header: 'Upload erfolgreich',
                message: `${results.success.length} Datei(en) wurden erfolgreich hochgeladen.`,
                buttons: ['OK'],
                duration: 3000,
                color: 'success',
                position: 'bottom',
                animated: true,
                translucent: true
            });
            await successAlert.present();
        } else {
            let message = `${results.success.length} von ${results.success.length + results.failed.length} Datei(en) wurden hochgeladen.`;
            if (results.failed.length <= 3) {
                message += '\n\nFehler bei: ' + results.failed.map(f => f.name).join(', ');
            } else {
                message += `\n\n${results.failed.length} Dateien konnten nicht hochgeladen werden.`;
            }

            const partialAlert = await this.alertController.create({
                header: 'Upload teilweise erfolgreich',
                message: message,
                buttons: ['OK']
            });
            await partialAlert.present();
        }
    }

    downloadFile(fileName: string) {
        const promise = this.storage.ref(fileName).getDownloadURL();
        return new FileDownloadTask(promise.toPromise());
    }
    downloadFileContents(fileName: string) {
        const promise = this.storage.storage.ref();
        //return new FileDownloadTask(promise.toPromise());

    }
    async deleteFile(url: string) {
        const storageRef = this.storage.refFromURL(url);
        storageRef.delete();
    }

    /**
     * Uploads a file using its path (for native platforms) or data
     * @param data File data buffer
     * @param storagePath Firebase storage path
     * @param name File name
     * @param referenceItemPath Reference path
     * @param referenceItemId Reference ID
     * @param fileUid Optional file UUID
     * @returns URL of the uploaded file
     */
    async uploadFromData(
        data: string,
        storagePath: string, 
        name: string, 
        referenceItemPath?: string, 
        referenceItemId?: string,
        fileUid?: string
    ): Promise<{url: string, uuid: string}> {
        this.loggingService.logMethodStart(this, 'uploadFromPath', {
            data,
            storagePath, 
            name, 
            referenceItemPath, 
            referenceItemId, 
            fileUid
        });
        
        let uuid = fileUid || uuidv4();
        let blob: Blob;

        try {
            const base64Data = data;
            const contentType = this.getContentTypeFromFileName(name);

            // Create blob from base64 string
            const byteCharacters = atob(String(base64Data));
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            blob = new Blob(byteArrays, { type: contentType });

            if (!blob) {
                throw new Error('Failed to create blob from input');
            }
            
            this.loggingService.log(this, 'Blob created successfully', { 
                size: blob.size, 
                type: blob.type 
            });

            return await this.uploadBlob(blob, storagePath, name, referenceItemPath, referenceItemId, uuid);
        } catch (error) {
            this.loggingService.logError(this, 'Error uploading file from data:#1', error);
            // throw error;
        }
    }
    
    /**
     * Determine content type based on file extension
     */
    private getContentTypeFromFileName(fileName: string): string {
        const extension = fileName.split('.').pop().toLowerCase();
        const mimeTypes = {
            'pdf': 'application/pdf',
            'doc': 'application/msword',
            'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'xls': 'application/vnd.ms-excel',
            'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'ppt': 'application/vnd.ms-powerpoint',
            'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'jpg': 'image/jpeg',
            'jpeg': 'image/jpeg',
            'png': 'image/png',
            'gif': 'image/gif',
            'txt': 'text/plain',
            'csv': 'text/csv',
            'json': 'application/json',
            'xml': 'application/xml',
        };
        
        return mimeTypes[extension] || 'application/octet-stream';
    }

    prependTenant(path: dbPaths | string): string {
        let tenantId = '';
        if(environment.useStaticTenant && environment.tenantId){
            tenantId = environment.tenantId;
        }

        if (!tenantId){
            tenantId = this.tenantService.tenant$.getValue().tenantId;
        }

        if(!tenantId) {
            throw new Error('No tenantId given');
        }

        if (path.startsWith('/')) {
            path = path.substring(1);
        }
        return `${dbPaths.tenantPath}/${tenantId}/${path}`;
    }

}
