import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {ToastController} from '@ionic/angular';
import {FirestoreProxyService} from '../../services/firestore-proxy.service';
import {InvitationService} from '../../services/invitation.service';
import {User} from '../../models/user';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth-service.service';
import {UserService} from "../../services/user.service";
import {TenantService} from "../../services/tenant.service";

@Component({
    selector: 'app-finish-sign-up',
    templateUrl: './finish-sign-up.component.html',
    styleUrls: ['./finish-sign-up.component.scss'],
})
export class FinishSignUpComponent implements OnInit {
    signUpForm: FormGroup;
    email: string;
    docId: string;
    tenantId: string;
    token: string;
    subscription: Subscription = new Subscription();

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private toastController: ToastController,
        private firestore: FirestoreProxyService,
        private invitationService: InvitationService,
        private authService: AuthService,
        private userService: UserService,
        private tenantService: TenantService,

    ) {}

    ngOnInit() {
        this.signUpForm = this.fb.group({
            firma: [''],
            anrede: [''],
            titel: [''],
            vorname: ['', Validators.required],
            nachname: ['', Validators.required],
            email: [{ value: '', disabled: true }, Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
        });

        this.userService.currentUserIsLoggedInAndValid.subscribe(async isLoggedIn => {
            if (isLoggedIn) {
                const uid = this.userService.currentUser$.value?.uid;
                this.userService.loadObservableUserByUid(uid, this.tenantService.getCurrentTenantId()).subscribe(async user => {
                    if (user) {
                        this.docId = user.docId;
                        this.tenantId = user.tenantId;
                        this.token = user.token;
                        if (!this.signUpForm.get('email').value) {
                            console.log('email not set');
                            await this.loadUserData();
                        }
                    }
                });
            } else {
                this.subscription.add(this.route.params.subscribe(async params => {
                    this.docId = this.route.snapshot.queryParams.docId;
                    this.tenantId = this.route.snapshot.queryParams.tenantId;
                    this.token = this.route.snapshot.queryParams.token;

                    await this.invitationService.verifyCustomToken(this.token)
                        .then(async (result) => {
                            if (result) {
                                await this.loadUserData();
                            }
                        });
                }));
            }
        });
    }

    async loadUserData() {
        console.log('tries to load user data');
        console.log('tenantId', this.tenantId);
        console.log('docId', this.docId);
        this.firestore.getDocumentConnectedObservableWithoutTenant('tenant/' + this.tenantId + '/users', this.docId).subscribe((user: User) => {
            this.email = user.email;
            this.signUpForm = this.fb.group({
                firma: [user.firma || ''],
                anrede: [user.anrede || ''],
                titel: [user.titel || ''],
                vorname: [user.vorname || '', Validators.required],
                nachname: [user.nachname || '', Validators.required],
                email: [{ value: this.email, disabled: true }, Validators.required],
                password: ['', [Validators.required, Validators.minLength(6)]],
            });
            this.signUpForm.patchValue(user);
        });
    }

    async onSubmit() {
        if (this.signUpForm.valid) {
            const { password, firma, anrede, titel, vorname, nachname } = this.signUpForm.value;
            try {
                console.log('tries to complete sign up');
                console.log('docId', this.docId);
                console.log('token', this.token);
                await this.invitationService.completeSignUp(this.docId, this.token, password, firma, anrede, titel, vorname, nachname);
            } catch (error) {
                const toast = await this.toastController.create({
                    message: error.message,
                    duration: 2000,
                    color: 'danger'
                });
                await toast.present();
            }
        }
    }
}
