export class Globals {

    public static chatParticipantsFieldName = 'participants';

    public static messagesCollectionName = 'feed';
    public static visitsCollectionName = 'visits';
	public static searchesCollectionPath = 'algoliaIndex';

    public static arbeitsgruppenId = 'arbeitsgruppen';
    public static calendarPath = 'calendar';

    public static storeAttachmentsPath = 'attachments';
}

export enum dbPaths {
    chatsPath = 'chats',
    newsPath = 'news',
    settingsPath = 'settings',
    calendarPath = 'calendar',
    tenantPath = 'tenant',
    documentsPath = 'documents',
    surveysPath = 'surveys',
    wikiPath = 'wiki',
    pagesPath = 'pages',
    usersPath = 'users',
    messagesCollection = "messages",
    quillDocsPath = 'quill-docs'
}
