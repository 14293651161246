import {Component, OnDestroy, OnInit} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Hit} from '@algolia/client-search';
import {SearchService} from '../../../app/services/search.service';
import {FirestoreProxyService, Query} from '../../../app/services/firestore-proxy.service';
import {Globals} from '../../../app/services/globals';
import {UserService} from "../../../app/services/user.service";
import {AuthService} from "../../../app/services/auth-service.service";
import {VisitService} from "../../../app/services/visit.service";
import {TenantService} from "../../../app/services/tenant.service";
import {NavigationService} from "../../../app/services/navigation.service";
import {SearchItem} from "../../../app/models/searchItem";
import {first, map, takeUntil} from "rxjs/operators";
import {ArbeitsgruppenService} from "../../../app/services/arbeitsgruppen.service";
import {combineLatest, filter, Subject} from "rxjs";
import {FirestoreProxyCacheService} from "../../../app/services/firestore-proxy-cache.service";
import {AppInitializationService} from "../../../app/services/app-initialization.service";
import {UtilService} from "../../../app/services/util.service";
import {ChatService} from "../../../app/services/chat.service";

@Component({
    selector: 'app-start-page',
    templateUrl: './start-page.component.html',
    styleUrls: ['./start-page.component.scss'],
})

export class StartPageComponent implements OnInit, OnDestroy {

    items: Array<Hit<any>> = null;
    private destroy$ = new Subject<void>();

    constructor(
        public platform: Platform,
        protected searchService: SearchService,
        private firestoreProxyCacheService: FirestoreProxyCacheService,
        protected userService: UserService,
        private visitService: VisitService,
        private tenantService: TenantService,
        private arbeitsgruppenService: ArbeitsgruppenService,
        private appInitializationService: AppInitializationService,
        protected chatService: ChatService
    ) {
    }

    async ngOnInit() {
        await this.appInitializationService.Initialize();
        this.appInitializationService.IsUserLoaded
            .pipe(
                filter(loaded => loaded === true),
                first()
            ).subscribe(() => {
                this.setupSubscriptions();
            });
    }

    private setupSubscriptions() {
        // Set up combined subscription to both isRedaktion$ and currentUserArbeitsgruppen
        // This will trigger a refresh whenever either of these values change
        combineLatest([
            this.userService.isRedaktion$,
            this.arbeitsgruppenService.currentUserArbeitsgruppen
        ])
        .pipe(
            takeUntil(this.destroy$)
        )
        .subscribe(() => {
            // Clear existing items before reloading to avoid stale data
            this.items = null;
            this.load();
        });
    }

    load() {
        const tenantId = this.tenantService.tenant$.getValue()?.tenantId;
        if (!tenantId || tenantId === '') {
            return;
        }

        const currentUserUid = this.userService.getCurrentUserUid();
        const arbeitsgruppen = this.arbeitsgruppenService.currentUserArbeitsgruppen.getValue();
        const isRedaktion = this.userService.isRedaktion$.getValue();
        
        // Query 1: Items from user's arbeitsgruppen
        const where: Query[] = [
            {
                fieldPath: 'tenantId',
                filterOperator: "==",
                value: this.tenantService.getCurrentTenantId()
            }
        ];

        where.push({
            fieldPath: 'published',
            filterOperator: "==",
            value: true
        });

        if(arbeitsgruppen && arbeitsgruppen.length > 0) {
            where.push({
                fieldPath: 'arbeitsgruppe',
                filterOperator: "in",
                value: arbeitsgruppen.map(ag => ag.id)
            });
        }

        // Query 2: Chat items where user is owner or participant
        const whereChats: Query[] = [
            {
                fieldPath: 'tenantId',
                filterOperator: "==",
                value: this.tenantService.getCurrentTenantId()
            },
            {
                fieldPath: 'published',
                filterOperator: "==",
                value: true
            },
            {
                fieldPath: 'arbeitsgruppe',
                filterOperator: "==",
                value: 0
            },
            {
                fieldPath: 'itemType',
                filterOperator: "==",
                value: 'chat'
            }
        ];

        // Execute both queries and combine results
        this.firestoreProxyCacheService.query<SearchItem>(
            Globals.searchesCollectionPath,
            where,
            [{fieldPath: 'modified', directionStr: 'desc'}],
            15, 
            false
        ).subscribe(async searches => {
            // Process standard items
            await this.processSearchResults(searches);
        });

        // Execute owner query
        this.firestoreProxyCacheService.query<SearchItem>(
            Globals.searchesCollectionPath,
            [...whereChats, { fieldPath: 'owner', filterOperator: "==", value: currentUserUid }],
            [{fieldPath: 'modified', directionStr: 'desc'}],
            15,
            false
        ).subscribe(ownerChats => {
            // Execute participants query
            this.firestoreProxyCacheService.query<SearchItem>(
                Globals.searchesCollectionPath,
                [...whereChats, { fieldPath: 'participants', filterOperator: "array-contains", value: currentUserUid }],
                [{fieldPath: 'modified', directionStr: 'desc'}],
                15,
                false
            ).subscribe(async participantChats => {
                // Combine unique results from all queries
                const allChats = [...ownerChats, ...participantChats];
                const uniqueChats = this.getUniqueItems(allChats);
                
                // Process chat items
                await this.processSearchResults(uniqueChats);
                
                // For users with redaktion role, fetch additional chats with no participants
                if (isRedaktion) {
                    // Query for redaktions-Chats
                    this.firestoreProxyCacheService.query<SearchItem>(
                        Globals.searchesCollectionPath,
                        [
                            ...whereChats,
                            {
                                fieldPath: 'arbeitsgruppe',
                                filterOperator: "==",
                                value: 0
                            },
                            {
                                fieldPath: 'itemType',
                                filterOperator: "==",
                                value: 'chat'
                            },
                            {
                                fieldPath: 'participants',
                                filterOperator: "==",
                                value: []
                            }
                        ],
                        [{fieldPath: 'modified', directionStr: 'desc'}],
                        15,
                        false
                    ).subscribe(async redaktionChats => {
                        await this.processSearchResults(redaktionChats);
                    });
                }
            });
        });
    }

    // Helper method to process search results
    private async processSearchResults(searches: SearchItem[]) {
        for (const search of searches) {
            const path = search.itemPath.replace(/^\/tenant\/[^\/]+/, '').replace(/\/$/, '');
            search.viewLocalTimeStampHasUnreadMessages = this.visitService
                .hasCurrentUserVisitSinceObservable(path, search.itemDocId, search.modified)
                .pipe(map(hasVisit => !hasVisit));

            if(this.userService.isRedaktion$.getValue() && search.itemType === 'chat' && this.chatService.determineItemIsSupportChat(search)) {
                const ownerName = await this.userService.getUserNameByUid(search.owner);
                if (search.title.startsWith(ownerName) === false) {
                    search.title = ownerName + ' - ' + search.title;
                }
            }
        }
        
        if (!this.items) {
            this.items = searches;
        } else {
            // Combine with existing items and ensure uniqueness
            this.items = this.getUniqueItems([...this.items, ...searches])
                .sort((a, b) => b.modified - a.modified)
                .slice(0, 15);
        }
    }

    // Helper method to ensure unique items by itemDocId
    private getUniqueItems(items: SearchItem[]): SearchItem[] {
        const uniqueMap = new Map();
        items.forEach(item => {
            if (!uniqueMap.has(item.itemDocId)) {
                uniqueMap.set(item.itemDocId, item);
            }
        });
        return Array.from(uniqueMap.values());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openItem(item: any) {
        this.searchService.openItem(item);
    }

    protected readonly UtilService = UtilService;
}
