// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
  font-size: 30px;
  float: right;
}

.icon-valid {
  color: green;
}

.icon-invalid {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/components/client-code-input/tenant-id-input.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".icon {\n  font-size: 30px;\n  float: right;\n}\n\n.icon-valid {\n  color: green;\n}\n\n.icon-invalid {\n  color: red;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
