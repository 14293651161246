import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { FirestoreProxyService } from './firestore-proxy.service';
import { Query, Order } from './firestore-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreProxyCacheService {
  // Cache for document observables
  private documentCache = new Map<string, Observable<any>>();
  
  // Cache for collection observables
  private collectionCache = new Map<string, Observable<any[]>>();
  
  // Cache for query observables with additional options
  private queryCache = new Map<string, Observable<any[]>>();

  constructor(
    private firestore: FirestoreProxyService
  ) { }

  /**
   * Gets a cached document observable or creates one if it doesn't exist
   * @param path Path to the collection
   * @param docId Document ID
   * @param keyValue
   * @param prependTenant Whether to prepend tenant to path (default: true)
   * @param replayBufferSize Number of values to replay to new subscribers (default: 1)
   * @returns Observable of the document data
   */
  public getDocumentObservable<T>(path: string, docId: string, keyValue: string = null, prependTenant = true, replayBufferSize = 1): Observable<T> {
    // Create a cache key that includes both path and docId
    let cacheKey = `${path}/${docId}`;
    if(keyValue) {
      cacheKey += `${keyValue}`;
    }
    
    if (!this.documentCache.has(cacheKey)) {
      // Create and cache the observable with shareReplay
      const documentObservable = this.firestore.getDocumentConnectedObservable<T>(path, docId, prependTenant)
        .pipe(shareReplay(replayBufferSize));

      if(keyValue){
        const cacheKeyBase = `${path}/${docId}`;
        this.clearCollectionsByPrefix(cacheKeyBase);
      }

      this.documentCache.set(cacheKey, documentObservable);
    }
    
    // Return the cached observable
    return this.documentCache.get(cacheKey) as Observable<T>;
  }

  /**
   * Gets a cached document observable that returns null if document doesn't exist
   * instead of redirecting to 404 page. The observable stays active.
   * 
   * Specifically designed for use with visits documents that may not exist.
   * 
   * @param path Path to the collection
   * @param docId Document ID
   * @param keyValue Optional cache key suffix
   * @param prependTenant Whether to prepend tenant to path (default: true)
   * @param replayBufferSize Number of values to replay to new subscribers (default: 1)
   * @returns Observable of the document data or null if document doesn't exist
   */
  public getDocumentObservableIfExistOtherwiseReturnNullAndStayObserved<T>(
    path: string, 
    docId: string, 
    keyValue: string = null, 
    prependTenant = true, 
    replayBufferSize = 1
  ): Observable<T | null> {
    // Create a cache key that includes both path and docId
    let cacheKey = `null_${path}/${docId}`;
    if(keyValue) {
      cacheKey += `${keyValue}`;
    }
    
    if (!this.documentCache.has(cacheKey)) {
      // Create and cache the observable with shareReplay
      const documentObservable = this.firestore
        .getDocumentConnectedObservableIfExistOtherwiseReturnNullAndStayObserved<T>(path, docId, prependTenant)
        .pipe(shareReplay(replayBufferSize));

      if(keyValue){
        const cacheKeyBase = `null_${path}/${docId}`;
        this.clearCollectionsByPrefix(cacheKeyBase);
      }

      this.documentCache.set(cacheKey, documentObservable);
    }
    
    // Return the cached observable
    return this.documentCache.get(cacheKey) as Observable<T | null>;
  }

  /**
   * Gets a nested document with user ID or any other ID as part of the path
   * @param path Base collection path 
   * @param docId Main document ID
   * @param subCollectionPath Sub-collection path
   * @param subDocId Sub-document ID
   * @param prependTenant Whether to prepend tenant to path (default: true)
   * @param replayBufferSize Number of values to replay to new subscribers (default: 1)
   * @returns Observable of the document data
   */
  public getNestedDocumentObservable<T>(
    path: string, 
    docId: string, 
    subCollectionPath: string, 
    subDocId: string, 
    prependTenant = true, 
    replayBufferSize = 1
  ): Observable<T> {
    const fullPath = `${path}/${docId}/${subCollectionPath}`;
    return this.getDocumentObservable<T>(fullPath, subDocId, null, prependTenant, replayBufferSize);
  }

  /**
   * Gets a cached collection observable or creates one if it doesn't exist
   * @param path Path to the collection
   * @param where Optional query filters
   * @param order Optional ordering
   * @param prependTenant Whether to prepend tenant to path (default: true)
   * @param replayBufferSize Number of values to replay to new subscribers (default: 1)
   * @returns Observable of the collection data
   */
  public getCollectionObservable<T>(
    path: string, 
    where?: Query[], 
    order?: Order[],
    prependTenant = true, 
    replayBufferSize = 1
  ): Observable<T[]> {
    // Create a cache key based on path and query parameters
    const whereKey = where ? JSON.stringify(where) : '';
    const orderKey = order ? JSON.stringify(order) : '';
    const cacheKey = `${path}_${whereKey}_${orderKey}_${prependTenant}`;

    if (!this.collectionCache.has(cacheKey)) {
      // Create and cache the observable with shareReplay
      const collectionObservable = this.firestore.query<T>(path, where, order, null, prependTenant)
        .pipe(shareReplay(replayBufferSize));
      
      this.collectionCache.set(cacheKey, collectionObservable);
    }
    
    // Return the cached observable
    return this.collectionCache.get(cacheKey) as Observable<T[]>;
  }

  /**
   * Gets a cached query observable with support for limit and advanced query options
   * This handles more complex queries including large 'in' queries
   * @param path Path to the collection
   * @param where Optional query filters
   * @param order Optional ordering
   * @param limit Optional limit on result count
   * @param prependTenant Whether to prepend tenant to path (default: true)
   * @param replayBufferSize Number of values to replay to new subscribers (default: 1)
   * @returns Observable of the query results
   */
  public query<T>(
    path: string,
    where?: Query[],
    order?: Order[],
    limit?: number,
    prependTenant = true,
    replayBufferSize = 1
  ): Observable<T[]> {
    // Create a cache key based on all query parameters
    const whereKey = where ? JSON.stringify(where) : '';
    const orderKey = order ? JSON.stringify(order) : '';
    const limitKey = limit ? limit.toString() : '';
    const cacheKey = `query_${path}_${whereKey}_${orderKey}_${limitKey}_${prependTenant}`;

    if (!this.queryCache.has(cacheKey)) {
      // Create and cache the query observable with shareReplay
      const queryObservable = this.firestore.query<T>(path, where, order, limit, prependTenant)
        .pipe(shareReplay(replayBufferSize));
      
      this.queryCache.set(cacheKey, queryObservable);
    }
    
    // Return the cached observable
    return this.queryCache.get(cacheKey) as Observable<T[]>;
  }

  /**
   * Gets a nested collection with path components
   * @param basePath Base collection path
   * @param docId Document ID
   * @param subCollectionPath Sub-collection path
   * @param where Optional query filters
   * @param order Optional ordering
   * @param prependTenant Whether to prepend tenant to path (default: true)
   * @param replayBufferSize Number of values to replay to new subscribers (default: 1)
   * @returns Observable of the collection data
   */
  public getNestedCollectionObservable<T>(
    basePath: string,
    docId: string,
    subCollectionPath: string,
    where?: Query[],
    order?: Order[],
    prependTenant = true,
    replayBufferSize = 1
  ): Observable<T[]> {
    const fullPath = `${basePath}/${docId}/${subCollectionPath}`;
    return this.getCollectionObservable<T>(fullPath, where, order, prependTenant, replayBufferSize);
  }

  /**
   * Gets a nested collection with full query support including limits
   * @param basePath Base collection path
   * @param docId Document ID
   * @param subCollectionPath Sub-collection path
   * @param where Optional query filters
   * @param order Optional ordering
   * @param limit Optional limit on result count
   * @param prependTenant Whether to prepend tenant to path (default: true)
   * @param replayBufferSize Number of values to replay to new subscribers (default: 1)
   * @returns Observable of the query results
   */
  public getNestedQueryObservable<T>(
    basePath: string,
    docId: string,
    subCollectionPath: string,
    where?: Query[],
    order?: Order[],
    limit?: number,
    prependTenant = true,
    replayBufferSize = 1
  ): Observable<T[]> {
    const fullPath = `${basePath}/${docId}/${subCollectionPath}`;
    return this.query<T>(fullPath, where, order, limit, prependTenant, replayBufferSize);
  }

  /**
   * Gets a count of documents in a collection with caching
   * @param path Path to the collection
   * @param where Optional query filters
   * @param prependTenant Whether to prepend tenant to path (default: true)
   * @param replayBufferSize Number of values to replay to new subscribers (default: 1)
   * @returns Observable of the count
   */
  public count<T>(
    path: string,
    where?: Query[],
    prependTenant = true,
    replayBufferSize = 1
  ): Observable<number> {
    // Create a cache key based on path and query parameters
    const whereKey = where ? JSON.stringify(where) : '';
    const cacheKey = `count_${path}_${whereKey}_${prependTenant}`;

    if (!this.documentCache.has(cacheKey)) {
      // Create and cache the count observable with shareReplay
      const countObservable = this.firestore.count<T>(path, where)
        .pipe(shareReplay(replayBufferSize));
      
      this.documentCache.set(cacheKey, countObservable);
    }
    
    // Return the cached observable
    return this.documentCache.get(cacheKey) as Observable<number>;
  }

  /**
   * Clears a specific document from the cache
   * @param path Path to the collection
   * @param docId Document ID
   */
  public clearFromCache(path: string, docId: string): void {
    const cacheKey = `${path}/${docId}`;
    this.documentCache.delete(cacheKey);
  }

  /**
   * Clears a specific collection query from the cache
   * @param path Path to the collection
   * @param where Optional query filters
   * @param order Optional ordering
   * @param prependTenant Whether to prepend tenant to path
   */
  public clearCollectionFromCache(
    path: string, 
    where?: Query[], 
    order?: Order[],
    prependTenant = true
  ): void {
    const whereKey = where ? JSON.stringify(where) : '';
    const orderKey = order ? JSON.stringify(order) : '';
    const cacheKey = `${path}_${whereKey}_${orderKey}_${prependTenant}`;
    
    this.collectionCache.delete(cacheKey);
  }

  /**
   * Clears a specific query from the cache
   * @param path Path to the collection
   * @param where Optional query filters
   * @param order Optional ordering
   * @param limit Optional limit on result count
   * @param prependTenant Whether to prepend tenant to path
   */
  public clearQueryFromCache(
    path: string,
    where?: Query[],
    order?: Order[],
    limit?: number,
    prependTenant = true
  ): void {
    const whereKey = where ? JSON.stringify(where) : '';
    const orderKey = order ? JSON.stringify(order) : '';
    const limitKey = limit ? limit.toString() : '';
    const cacheKey = `query_${path}_${whereKey}_${orderKey}_${limitKey}_${prependTenant}`;

    this.queryCache.delete(cacheKey);
  }

  /**
   * Clears all collection cache entries that start with a specific path
   * Useful when you want to clear all queries related to a specific collection
   * @param pathPrefix The path prefix to match
   */
  public clearCollectionsByPrefix(pathPrefix: string): void {
    // Find all entries that start with the path prefix in all caches
    for (const key of this.collectionCache.keys()) {
      if (key.startsWith(pathPrefix)) {
        this.collectionCache.delete(key);
      }
    }
    
    for (const key of this.queryCache.keys()) {
      if (key.startsWith(`query_${pathPrefix}`)) {
        this.queryCache.delete(key);
      }
    }

    // Also clear count caches with this prefix
    for (const key of this.documentCache.keys()) {
      if (key.startsWith(`count_${pathPrefix}`)) {
        this.documentCache.delete(key);
      }
    }
  }

  /**
   * Clears the entire document cache
   */
  public clearCache(): void {
    this.documentCache.clear();
    this.collectionCache.clear();
    this.queryCache.clear();
  }

  /**
   * Checks if a document is cached
   * @param path Path to the collection
   * @param docId Document ID
   */
  public isDocumentCached(path: string, docId: string): boolean {
    const cacheKey = `${path}/${docId}`;
    return this.documentCache.has(cacheKey);
  }

  /**
   * Checks if a query is cached
   * @param path Path to the collection
   * @param where Optional query filters
   * @param order Optional ordering
   * @param limit Optional limit on result count
   * @param prependTenant Whether to prepend tenant to path
   */
  public isQueryCached(
    path: string,
    where?: Query[],
    order?: Order[],
    limit?: number,
    prependTenant = true
  ): boolean {
    const whereKey = where ? JSON.stringify(where) : '';
    const orderKey = order ? JSON.stringify(order) : '';
    const limitKey = limit ? limit.toString() : '';
    const cacheKey = `query_${path}_${whereKey}_${orderKey}_${limitKey}_${prependTenant}`;

    return this.queryCache.has(cacheKey);
  }
} 
