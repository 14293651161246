// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
Stuckture of Publisched/not publisched item in progress

| <app-show-not-publisched-icon >
|     |-- div
|       |-- .is-not-published   (icon)
*/
div > .is-not-published {
  width: 18px;
  height: 18px !important;
  top: -11px;
  position: relative;
  z-index: 999;
  left: -44px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/show-not-publisched-icon/show-not-publisched-icon.component.scss"],"names":[],"mappings":"AAAA;;;;;;CAAA;AAUA;EACE,WAAA;EACA,uBAAA;EACA,UAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AAFF","sourcesContent":["/*\nStuckture of Publisched/not publisched item in progress\n\n| <app-show-not-publisched-icon >\n|     |-- div\n|       |-- .is-not-published   (icon)\n*/\n\n\n\ndiv  > .is-not-published {\n  width: 18px;\n  height: 18px !important;\n  top: -11px;\n  position: relative;\n  z-index: 999;\n  left: -44px;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
