import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    Observable,
    of,
    switchMap,
    map,
    defaultIfEmpty,
    Subscription, filter
} from 'rxjs';
import {Arbeitsgruppe} from '../models/arbeitsgruppe';
import {FirestoreProxyService, Query} from './firestore-proxy.service';
import {Arbeitsgruppen} from '../models/arbeitsgruppen';
import {dbPaths, Globals} from './globals';
import {first, mergeMap, tap} from 'rxjs/operators';
import {Feed} from '../models/feed';
import {UtilService} from './util.service';
import {ToastController} from '@ionic/angular';
import {UserService} from './user.service';
import {User, UserArbeitsgruppenSettings} from '../models/user';
import {Contact} from '../models/contact';
import {TenantService} from "./tenant.service";
import {LoggingService} from "./logging.service";
import {UserSettingsService} from "./user-settings.service";

@Injectable({
    providedIn: 'root'
})

export class ArbeitsgruppenService {
    public arbeitsgruppen: BehaviorSubject<Arbeitsgruppen> = new BehaviorSubject<Arbeitsgruppen>({list: []});
    public nonArchivedArbeitsgruppen: BehaviorSubject<Arbeitsgruppen> = new BehaviorSubject<Arbeitsgruppen>({list: []});
    public currentUserArbeitsgruppen: BehaviorSubject<Arbeitsgruppe[]> = new BehaviorSubject<Arbeitsgruppe[]>([]);
    public currentUserAssignAssignableArbeitsgruppen: BehaviorSubject<Arbeitsgruppe[]> = new BehaviorSubject<Arbeitsgruppe[]>([]);
    public currentUserNonAssignableArbeitsgruppen: BehaviorSubject<Arbeitsgruppe[]> = new BehaviorSubject<Arbeitsgruppe[]>([]);
    public assignableArbeitsgruppen: BehaviorSubject<Arbeitsgruppe[]> = new BehaviorSubject<Arbeitsgruppe[]>([]);
    public nonAssignableArbeitsgruppen: BehaviorSubject<Arbeitsgruppe[]> = new BehaviorSubject<Arbeitsgruppe[]>([]);
    public isUserLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private chatsArbeitsgruppenEnabled = false;
    private newsEnabled = false;
    private documentsEnabled = false;
    private surveysEnabled = false;
    private subscription: Subscription;


    constructor(
        private firestoreProxyService: FirestoreProxyService,
        private settings: SettingsService,
        private toast: ToastController,
        private userService: UserService,
        private tenantService: TenantService,
        private loggingService: LoggingService,
        private userSettingsService: UserSettingsService,
    ) {
    }

    public async Init(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {

        // Load Arbeitsgruppen
                this.subscription = combineLatest([
                    this.userService.currentUserIsLoggedIn$,
                    this.userService.currentUser$,
                    this.tenantService.tenant$,
                    this.userSettingsService.userArbeitsgruppenSettings
                ]).pipe(
                    switchMap(([isLoggedIn, user, tenant, userArbeitsgruppenSettings]) => {
                        // Only fetch Arbeitsgruppen when auth state, user or tenant changes
                        if (isLoggedIn && user && user?.uid && tenant && userArbeitsgruppenSettings) {
                            return this.getArbeitsgruppenAsObservable().pipe(
                                map(arbeitsgruppen => ({ isLoggedIn, user, tenant, arbeitsgruppen, userArbeitsgruppenSettings }))
                            );
                        } else {
                            return of({ isLoggedIn, user, tenant, arbeitsgruppen: null, userArbeitsgruppenSettings: null });
                        }
                    })
                ).subscribe(async ({ isLoggedIn, user, tenant, arbeitsgruppen, userArbeitsgruppenSettings }) => {
                    if (isLoggedIn && user && user?.uid && tenant && arbeitsgruppen && userArbeitsgruppenSettings) {
                        this.loggingService.logMethodStart(this, 'Changing Arbeitsgruppen');
                        this.arbeitsgruppen.next(arbeitsgruppen);
                        const nonArchivedArbeitsgruppen = arbeitsgruppen.list.filter(a => !a.archived);
                        this.nonArchivedArbeitsgruppen.next({list: nonArchivedArbeitsgruppen});

                        // filter users arbeitsgruppen
                        const currentUserArbeitsgruppen = this.getArbeitsgruppenByUser(userArbeitsgruppenSettings);
                        this.currentUserArbeitsgruppen.next(currentUserArbeitsgruppen);
                        this.currentUserAssignAssignableArbeitsgruppen.next(currentUserArbeitsgruppen.filter(a => a.isUserAssignable));
                        this.currentUserNonAssignableArbeitsgruppen.next(currentUserArbeitsgruppen.filter(a => !a.isUserAssignable));
                        this.assignableArbeitsgruppen.next(nonArchivedArbeitsgruppen.filter(a => a.isUserAssignable));
                        this.nonAssignableArbeitsgruppen.next(nonArchivedArbeitsgruppen.filter(a => !a.isUserAssignable));

                        // set tenant feature
                        this.chatsArbeitsgruppenEnabled = tenant?.features?.chats_arbeitsgruppen?.enabled;
                        this.newsEnabled = tenant?.features?.news?.enabled || tenant.features?.news_NonUserAssignable?.enabled || tenant.features?.news_UserAssignable?.enabled;
                        this.documentsEnabled = tenant?.features?.documents.enabled;
                        this.surveysEnabled = tenant?.features?.surveys.enabled;

                        if(this.isUserLoaded.getValue() === false) {
                            this.isUserLoaded.next(true);
                        }
                    } else {
                        if(this.isUserLoaded.getValue() === true) {
                            this.isUserLoaded.next(false);
                        }
                        if(this.arbeitsgruppen.getValue() != null || !(this.arbeitsgruppen.getValue()?.list.length > 0)) {
                            this.arbeitsgruppen.next({list: []});
                        }
                        if(this.nonArchivedArbeitsgruppen.getValue() != null || !(this.nonArchivedArbeitsgruppen.getValue()?.list.length > 0)) {
                            this.nonArchivedArbeitsgruppen.next({list: []});
                        }
                    }
                    resolve();
                });
            }
            catch (error) {
                this.loggingService.logError(this, 'Error during AppInitializationService initialization', error);
                reject(error);
            }});
    }

    public async adminInit() {
        await this.createArbeitsgruppenFeeds(this.arbeitsgruppen.getValue());
    }

    public getArbeitsgruppenByUser(userArbeitsgruppenSettings: UserArbeitsgruppenSettings): Arbeitsgruppe[] {
        this.loggingService.logMethodStart(this, 'getArbeitsgruppenByUser', userArbeitsgruppenSettings);
        return this.nonArchivedArbeitsgruppen.getValue()?.list.filter(a =>
            (userArbeitsgruppenSettings?.arbeitsgruppenExplicitIncluded ?? [])?.some(ag => ag === a.id)
            || (a.isImplicitAssigned && !(userArbeitsgruppenSettings?.arbeitsgruppenImplicitExcluded ?? [])?.some(ag => ag == a.id))
        )
    }

    /// keep private; use public arbeitsgruppenService.arbeitsgruppen
    private getArbeitsgruppenAsObservable(): Observable<Arbeitsgruppen> {
        return this.settings.getObservable<Arbeitsgruppen>(Globals.arbeitsgruppenId)
            .pipe(map(x => {
                if (x?.list && x.list.length > 0) {
                    x.list = x.list.sort((a, b) => {
                        return a?.order - b?.order;
                    });
                }
                return x;
            }));
    }

    public async updateArbeitsgruppe(arbeitsgruppe: Arbeitsgruppe) {
        let index = 0;
        const arbeitsgruppen = this.arbeitsgruppen.getValue();
        for (const a of arbeitsgruppen?.list) {
            if (a.id === arbeitsgruppe.id) {
                arbeitsgruppen.list[index] = arbeitsgruppe;
                break;
            }
            index++;
        }

        await this.settings.update<Arbeitsgruppen>(Globals.arbeitsgruppenId, arbeitsgruppen);
    }

    public async updateArbeitsgruppenFeeds(arbeitsgruppe: Arbeitsgruppe) {
        let index = 0;
        const arbeitsgruppen = this.arbeitsgruppen.getValue();
        for (const a of arbeitsgruppen?.list) {
            if (a.id === arbeitsgruppe.id) {
                arbeitsgruppen.list[index].feeds = arbeitsgruppe.feeds;
                break;
            }
            index++;
        }

        await this.settings.update<Arbeitsgruppen>(Globals.arbeitsgruppenId, arbeitsgruppen);
    }

    async updateArbeitsgruppen(arbeitsgruppen: Arbeitsgruppe[]) {
        await this.settings.update<Arbeitsgruppen>(Globals.arbeitsgruppenId, {list: arbeitsgruppen});
    }

    public getFilteredArbeitsgruppenAsObservable(): Observable<Arbeitsgruppe[]> {
        return this.nonArchivedArbeitsgruppen.asObservable().pipe(map(a => a?.list ?? []));
    }

    public getCurrentUsersNonUserAssignableArbeitsgruppenIds(): Observable<number[]> {
        return this.currentUserNonAssignableArbeitsgruppen.asObservable().pipe(map(a => a.map(x => x.id)));
    }

    public getCurrentUsersUserAssignableArbeitsgruppenIds(): Observable<number[]> {
        return this.currentUserAssignAssignableArbeitsgruppen.asObservable().pipe(map(a => a.map(x => x.id)));
    }

    public getCurrentUsersArbeitsgruppenIds(): Observable<number[]> {
        return this.currentUserArbeitsgruppen.asObservable().pipe(map(a => a.map(x => x.id)));
    }

    getAllContactsThatAllowMeToSeeDetails(): Observable<Contact[]> {
        if (this.userService.isAdmin$.getValue() || this.userService.isRedaktion$.getValue()) {
            return this.userService.getAllContacts();
        }
        const adminsAndRedaktion = this.userService.getAllAdminsAndRedaktion();
        const userMitFreigabeAlle = this.userService.allUsersMitFreigabeAlle();
        const userInDerselbenArbeitsgruppe =
            this.userService.getUsersProfileWhereAccessAllowed(this.currentUserArbeitsgruppen.getValue()?.map(x => x.id));
        return adminsAndRedaktion
            .pipe(mergeMap(x => {
                return userMitFreigabeAlle
                    .pipe(mergeMap(y => {
                        return userInDerselbenArbeitsgruppe
                            .pipe(map(z => {
                                return x.concat(y).concat(z);
                            }))
                            .pipe(map(a => {
                                const result = [];
                                const userMap = new Map();
                                for (const item of a) {
                                    if (item != null && !userMap.has(item.uid)) {
                                        userMap.set(item.uid, true);
                                        result.push(item);
                                    }
                                }
                                return result;
                            }));
                    }));
            }))
            .pipe(map(x => x.map(y => {
                if (y) {
                    return {
                        docId: y.docId,
                        uid: y.uid,
                        vorname: `${y.vorname}`,
                        name: `${y.nachname}`,
                        firma: `${y.firma}`,
                        hidden: y.hidden
                    };
                } else {
                    return {
                        docId: '',
                        uid: '',
                        vorname: '',
                        name: '',
                        firma: '',
                        hidden: true
                    };
                }
            })))
            .pipe(tap(results => {
                results.sort((x, y) => `${x.name}${x.vorname}`.toLocaleLowerCase() > `${y.name}${y.vorname}`.toLocaleLowerCase() ? 1 : -1);
            }));
    }

    private getArbeitsgruppenFeeds<T extends Feed>(path: string): Observable<T[]> {
        const ags = this.arbeitsgruppen.getValue();

        return this.firestoreProxyService.query<T>(
            path,
            [
                {fieldPath: 'isArbeitsgruppenFeed', filterOperator: '==', value: true}
            ]).pipe(map((feeds, index) => {
            for (const feed of feeds) {
                if (feed.arbeitsgruppe != null) {
                    const ag = ags?.list.find(x => x.id === feed.arbeitsgruppe);
                    if (ag) {
                        feed.name = ag.name;
                    }
                }
            }
            return feeds ?? [];
        }));

    }

    getMembersCount(arbeitsgruppeId: number): Observable<number> {
        return this.arbeitsgruppen
            .pipe(filter(x => x != null), map(x => x.list.find(y => y.id === arbeitsgruppeId)))
            .pipe(switchMap((ag) => {
            if (ag) {
                if(ag.isImplicitAssigned){
                    return this.userService.getUsersWichAreNotImplicitExcludesArbeitsgruppeCount(arbeitsgruppeId);
                }else{
                    return this.userService.getUsersWichAreExplicitIncludedArbeitsgruppeCount(arbeitsgruppeId);
                }
            }
            else {
                return of(0);
            }
        }));
    }

    getMembers(arbeitsgruppeId: number): Observable<User[]> {
        return this.arbeitsgruppen
            .pipe(filter(x => x != null), map(x => x.list.find(y => y.id === arbeitsgruppeId)))
            .pipe(switchMap((ag) => {
            if (ag) {
                if(ag.isImplicitAssigned){
                    return this.userService.getUsersWichAreNotImplicitExcludesArbeitsgruppe(arbeitsgruppeId);
                }else{
                    return this.userService.getUsersWichAreExplicitIncludedArbeitsgruppe(arbeitsgruppeId);
                }
            }
            else {
                alert('Arbeitsgruppe nicht gefunden');
                return of([]);
            }
            }));
    }

    private async createMissingArbeitsgruppenFeeds<A>(
        arbeitsgruppen: Arbeitsgruppen,
        path: dbPaths) {
        for (const ag of arbeitsgruppen?.list) {

            const exist = await this.firestoreProxyService.documentExistsWithQuery(path, [
                {fieldPath: 'arbeitsgruppe', filterOperator: '==', value: ag.id}
            ]);

            if (!exist) {
                await this.firestoreProxyService.addDocument(path, {
                    arbeitsgruppe: ag.id,
                    isArbeitsgruppenFeed: true,
                    name: ag.name,
                    time: UtilService.getCurrentUtcUnixTimestampAsNumber(),
                    deleted: false,
                    hidden: false
                });
            }
        }
    }

    private async createArbeitsgruppenFeeds(arbeitsgruppen: Arbeitsgruppen) {
        this.loggingService.logMethodStart(this, 'createArbeitsgruppenFeeds', arbeitsgruppen);

        if (this.newsEnabled) {
            await this.createMissingArbeitsgruppenFeeds(arbeitsgruppen, dbPaths.newsPath);
        }

        if (this.chatsArbeitsgruppenEnabled) {
            await this.createMissingArbeitsgruppenFeeds(arbeitsgruppen, dbPaths.chatsPath);
        }

        if (this.documentsEnabled) {
            await this.createMissingArbeitsgruppenFeeds(arbeitsgruppen, dbPaths.documentsPath);
        }

        if (this.surveysEnabled) {
            await this.createMissingArbeitsgruppenFeeds(arbeitsgruppen, dbPaths.surveysPath);
        }
    }

    private async deleteArbeitsgruppenFeeds(arbeitsgruppen: Arbeitsgruppen) {
        const newsFeeds = this.getArbeitsgruppenFeeds(dbPaths.newsPath);
        const chatFeeds = this.getArbeitsgruppenFeeds(dbPaths.chatsPath);
        const documentsFeeds = this.getArbeitsgruppenFeeds(dbPaths.documentsPath);
        const surveyFeeds = this.getArbeitsgruppenFeeds(dbPaths.surveysPath);

        console.log('Arbeitsgruppen', arbeitsgruppen);

        for (const ag of arbeitsgruppen?.list) {

            newsFeeds.pipe(map(async x => {
                if (x.some(y => y.arbeitsgruppe === ag.id)) {
                    const newsFeed = x.find(y => y.arbeitsgruppe === ag.id);
                    newsFeed.deleted = true;
                    await this.firestoreProxyService.updateDocument(dbPaths.newsPath, newsFeed.docId, newsFeed);
                }
            }));

            chatFeeds.pipe(map(async x => {
                if (x.some(y => y.arbeitsgruppe === ag.id)) {
                    const chatFeed = x.find(y => y.arbeitsgruppe === ag.id);
                    chatFeed.deleted = true;
                    await this.firestoreProxyService.updateDocument(dbPaths.chatsPath, chatFeed.docId, chatFeed);
                }
            }));

            documentsFeeds.pipe(map(async x => {
                if (x.some(y => y.arbeitsgruppe === ag.id)) {
                    const newsFeed = x.find(y => y.arbeitsgruppe === ag.id);
                    newsFeed.deleted = true;
                    await this.firestoreProxyService.updateDocument(dbPaths.documentsPath, newsFeed.docId, newsFeed);
                }
            }));

            surveyFeeds.pipe(map(async x => {
                if (x.some(y => y.arbeitsgruppe === ag.id)) {
                    const newsFeed = x.find(y => y.arbeitsgruppe === ag.id);
                    newsFeed.deleted = true;
                    await this.firestoreProxyService.updateDocument(dbPaths.surveysPath, newsFeed.docId, newsFeed);
                }
            }));
        }
    }

    private async undeleteArbeitsgruppenFeeds(arbeitsgruppen: Arbeitsgruppen) {
        const newsFeeds = this.getArbeitsgruppenFeeds(dbPaths.newsPath);
        const chatFeeds = this.getArbeitsgruppenFeeds(dbPaths.chatsPath);
        const documentsFeeds = this.getArbeitsgruppenFeeds(dbPaths.documentsPath);
        const surveyFeeds = this.getArbeitsgruppenFeeds(dbPaths.surveysPath);

        console.log('Arbeitsgruppen', arbeitsgruppen);

        for (const ag of arbeitsgruppen?.list) {

            newsFeeds.pipe(map(async x => {
                if (x.some(y => y.arbeitsgruppe === ag.id)) {
                    const newsFeed = x.find(y => y.arbeitsgruppe === ag.id);
                    newsFeed.deleted = false;
                    await this.firestoreProxyService.updateDocument(dbPaths.newsPath, newsFeed.docId, newsFeed);
                }
            }));

            chatFeeds.pipe(map(async x => {
                if (x.some(y => y.arbeitsgruppe === ag.id)) {
                    const chatFeed = x.find(y => y.arbeitsgruppe === ag.id);
                    chatFeed.deleted = false;
                    await this.firestoreProxyService.updateDocument(dbPaths.chatsPath, chatFeed.docId, chatFeed);
                }
            }));

            documentsFeeds.pipe(map(async x => {
                if (x.some(y => y.arbeitsgruppe === ag.id)) {
                    const newsFeed = x.find(y => y.arbeitsgruppe === ag.id);
                    newsFeed.deleted = false;
                    await this.firestoreProxyService.updateDocument(dbPaths.documentsPath, newsFeed.docId, newsFeed);
                }
            }));

            surveyFeeds.pipe(map(async x => {
                if (x.some(y => y.arbeitsgruppe === ag.id)) {
                    const newsFeed = x.find(y => y.arbeitsgruppe === ag.id);
                    newsFeed.deleted = false;
                    await this.firestoreProxyService.updateDocument(dbPaths.surveysPath, newsFeed.docId, newsFeed);
                }
            }));
        }
    }

    public async getArbeitsgruppenNextId(): Promise<number> {
        return UtilService.getUniqueNumericId();
    }

    async createArbeitsgruppe(ag: Arbeitsgruppe) {
        const nextId = await this.getArbeitsgruppenNextId();

        const neueArbeitsgruppe: Arbeitsgruppe = {
            id: nextId,
            order: nextId,
            archived: false,
            name: ag.name,
            feeds: ag.feeds,
            isUserAssignable: ag.isUserAssignable,
            isImplicitAssigned: ag.isImplicitAssigned
        };

        const arbeitsgruppen = await this.getArbeitsgruppenArrayWithAddedAg(neueArbeitsgruppe);

        await this.settings.update<Arbeitsgruppen>(Globals.arbeitsgruppenId, arbeitsgruppen);
        await this.createArbeitsgruppenFeeds(arbeitsgruppen);
    }

    public async getArbeitsgruppenArrayWithAddedAg(neueArbeitsgruppe: Arbeitsgruppe): Promise<Arbeitsgruppen> {
        const ags = this.arbeitsgruppen.getValue();
        if (!ags?.list) {
            ags.list = [];
        }
        if (neueArbeitsgruppe) {
            ags.list.push(neueArbeitsgruppe);
        }
        return ags;
    }

    getArbeitsgruppenIconName(arbeitsgruppe: Arbeitsgruppe): string {
        if (arbeitsgruppe.archived) {
            return 'repeat-outline';
        }
        return 'trash-outline';
    }

    async toggleArchiveArbeitsgruppe(arbeitsgruppe: Arbeitsgruppe) {

        arbeitsgruppe.archived = !arbeitsgruppe.archived;
        const toastText = arbeitsgruppe.archived ? 'Arbeitsgruppe erfolgreich archiviert!' : 'Arbeitsgruppe erfolgreich wiederhergestellt!';

        if (arbeitsgruppe.archived) {
            await this.deleteArbeitsgruppenFeeds({list: [arbeitsgruppe]});
        } else {
            await this.undeleteArbeitsgruppenFeeds({list: [arbeitsgruppe]});
        }
        const ags = await firstValueFrom(this.settings.getObservable<Arbeitsgruppen>(Globals.arbeitsgruppenId));
        const mergedAgs = ags.list.map(a => a.id == arbeitsgruppe.id ? arbeitsgruppe : a);
        await this.settings.update<Arbeitsgruppen>(Globals.arbeitsgruppenId, {list: mergedAgs});

        const toast = await this.toast.create({
            message: toastText,
            duration: 5000,
            color: 'success',
            position: 'top',
            positionAnchor: 'header'
        });
        await toast.present();
    }

    getArbeitsgruppenNameById(id: number): string | undefined {
        const arbeitsgruppe = this.arbeitsgruppen.getValue()?.list.find(ag => ag.id === id);
        return arbeitsgruppe?.name;
    }
}
