import {Injectable} from '@angular/core';
import {customAlphabet} from 'nanoid';
import {FirestoreProxyService} from './firestore-proxy.service';
import {dbPaths, Globals} from './globals';
import {UtilService} from './util.service';
import {environment} from "../../brand/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TenantIdGeneratorService {

    constructor(private firestore: FirestoreProxyService, private utilService: UtilService) {
    }

    public async generateTenantId(): Promise<string> {

        if (environment.useStaticTenant && environment.tenantId){
            return environment.tenantId;
        }

        try {
            const nanoid = customAlphabet('0123456789');
            let id: string;

            do {
                id = nanoid(6);
                console.log('nanoid:', id);
            } while (await this.tenantExists(id));

            return id;
        } catch (e) {
            console.log('fehler: ' + e);
            return UtilService.getCurrentUtcUnixTimestampAsNumber().toString();
        }
    }

    private async tenantExists(id: string): Promise<boolean> {
        return await this.firestore.documentExistsWithoutTenant(id, dbPaths.tenantPath);
    }
}
