import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TenantService} from '../../services/tenant.service';

@Component({
    selector: 'app-tenant-id-input',
    templateUrl: './tenant-id-input.component.html',
    styleUrls: ['./tenant-id-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TenantIdInputComponent
        }
    ]
})
export class TenantIdInputComponent implements OnInit, ControlValueAccessor {

    public tenantId = '';
    public touched = false;
    public tenantIdExisting = false;
    @Input() isDisabled = false;

    constructor(private tenantService: TenantService) {
    }

    onChange = (tenantId) => {
    };

    onTouched = () => {
    };

    ngOnInit() {
    }

    public onChangeTenantId(event: any) {
        const value = event.target.value;
        this.markAsTouched();
        this.onChange(value);

        this.tenantService.isTenantExisting(value)
            .then(exists => this.tenantIdExisting = exists);
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    writeValue(tenantId: string): void {
        this.tenantId = tenantId;
    }

    public markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
}
