import {Injectable} from '@angular/core';
import {Tenant} from '../models/tenant';
import {StorageService} from './storage.service';
import {TenantService} from "./tenant.service";

@Injectable({
    providedIn: 'root'
})
export class LocalCacheService {

    constructor(
        private localStorageService: StorageService)
    {
    }

    storeKeys = {
        tenant: 'vb-tenant',
        startPage: 'vb-start-page'
    };

    storeData<T>(tenant: Tenant, key: string, data: T) {
        this.localStorageService.set<T>(tenant?.tenantId + key, data);
    }

    async getData<T>(tenant: Tenant, key: string) {
        return await this.localStorageService.get<T>(tenant?.tenantId + key);
    }

    async onLogoutClearData() {
        const keysToClear = [this.storeKeys.tenant];
        for (const key of keysToClear) {
            await this.localStorageService.remove(key);
        }
    }


}
