import { Injectable } from '@angular/core';
import { AuthService } from './auth-service.service';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';
import { LoggingService } from './logging.service';
import {ArbeitsgruppenService} from "./arbeitsgruppen.service";
import {combineLatestWith, Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AppInitializationService {
  private isInitialized = false;
  private isInitInProgress = false;
  private initPromise: Promise<void> | null = null;

  constructor(
    private authService: AuthService,
    private tenantService: TenantService,
    private userService: UserService,
    private loggingService: LoggingService,
    private arbeitsgruppenService: ArbeitsgruppenService
  ) {}

  public get IsUserLoaded(): Observable<boolean> {
    this.loggingService.logMethodStart(this, 'IsUserLoaded');
    return this.authService.isLoggedIn$
        .pipe(combineLatestWith(this.userService.currentUserIsLoggedInAndValid))
        .pipe(combineLatestWith(this.arbeitsgruppenService.isUserLoaded))
        .pipe(map(([[isLoggedIn, IsLoggedInAndValid], agLoaded]) => {
            this.loggingService.logMethodStart(this, 'isLoggedIn or agLoaded changed', {isLoggedIn, agLoaded})
            return isLoggedIn && IsLoggedInAndValid && agLoaded;
        }));
  }

  public async Initialize(): Promise<void> {
    this.loggingService.logMethodStart(this, 'Initialize AppInitializationService');

    if (this.isInitialized) {
      this.loggingService.log(this, 'Already initialized, returning');
      return Promise.resolve();
    }

    if (this.isInitInProgress) {
      this.loggingService.log(this, 'Initialization already in progress, returning existing promise');
      return this.initPromise;
    }

    this.isInitInProgress = true;

    this.initPromise = new Promise<void>(async (resolve, reject) => {
      try {

        await this.authService.Init();
        await this.tenantService.Init();
        await this.userService.Init();
        await this.arbeitsgruppenService.Init();

        this.isInitialized = true;
        this.loggingService.logVerbose(this, 'AppInitializationService.Init finish');
        if (this.initPromise) {
          resolve();
        }

      } catch (error) {
        this.loggingService.logError(this, 'Error during AppInitializationService initialization', error);
        reject(error);
      } finally {
        this.isInitInProgress = false;
        this.initPromise = null;
      }
    });

    return this.initPromise;
  }
} 
