import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

  constructor(private storage: Storage) {
    this.innitStorage().then(r =>
        {
          console.log('Local storage Loaded');
        }
    );
  }
  async innitStorage(){
    await this.storage.create();
  }

    set<T>(key: string, t: T) {
        return this.storage.set(key, t);
    }

    get<T>(key: string): Promise<T> {
        return this.storage.get(key);
    }

    remove(key: string): Promise<any> {
        return this.storage.remove(key);
    }
}
