// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-title {
  text-align: center;
}

ion-header:not(.ios) {
  margin-top: 56px !important;
}

ion-content.ios .place {
  padding-top: 59px !important;
}

ion-header {
  margin-top: 59px !important;
}

.footer-md::before {
  background: none;
}

ion-list {
  margin-top: 0;
  padding-top: 0;
}

ion-menu.ios ion-list {
  margin-top: 0;
  padding-top: 0;
}

.alert-button-danger {
  color: #2dd36f;
}

.version {
  color: var(--ion-color-light-shade);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: x-small;
  line-height: 1.5;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  ion-menu.ios {
    margin-top: 9px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAIA;EACE,4BAAA;AADF;;AAKA;EACE,2BAAA;AAFF;;AAMA;EACE,gBAAA;AAHF;;AAMA;EACE,aAAA;EACA,cAAA;AAHF;;AAMA;EACE,aAAA;EACA,cAAA;AAHF;;AAMA;EACE,cAAA;AAHF;;AAMA;EACE,mCAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AAHF;;AAMA;EACE;IACE,0BAAA;EAHF;AACF","sourcesContent":[".app-title {\n  text-align: center;\n}\n\nion-header:not(.ios){\n  margin-top: 56px !important;\n}\n\n\n\nion-content.ios .place{\n  padding-top: 59px !important;\n}\n\n\nion-header{\n  margin-top: 59px !important;\n}\n\n\n.footer-md::before{\n  background: none;\n}\n\nion-list{\n  margin-top: 0;\n  padding-top: 0;\n}\n\nion-menu.ios ion-list {\n  margin-top: 0;\n  padding-top: 0;\n}\n\n.alert-button-danger{\n  color: #2dd36f;\n}\n\n.version{\n  color: var(--ion-color-light-shade);\n  text-align: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  font-size: x-small;\n  line-height: 1.5;\n}\n\n@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {\n  ion-menu.ios {\n    margin-top: 9px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
