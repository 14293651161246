// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cnf-feature-spfiled-name {
  margin-right: 40px;
  margin-left: 40px;
  margin-top: 10px;
  width: calc(100% - 80px);
}`, "",{"version":3,"sources":["webpack://./src/app/components/change-feature-properties-popup/change-feature-properties-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,wBAAA;AACF","sourcesContent":[".cnf-feature-spfiled-name{\n  margin-right: 40px;\n  margin-left: 40px;\n  margin-top: 10px;\n  width: calc(100% - 80px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
