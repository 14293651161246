import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserService} from '../../services/user.service';
import {Contact} from '../../models/contact';
import {ArbeitsgruppenService} from '../../services/arbeitsgruppen.service';
import { of } from 'rxjs';


@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit, OnDestroy {

  @Input() isContactModus: boolean;
  @Input() isChatAddModus: boolean;
  @Input() excludedDocIds: string[];
  @Output() checkboxChecked = new EventEmitter<string>();
  @Output() checkboxUnchecked = new EventEmitter<string>();

  constructor(
    private arbeitsgruppenService: ArbeitsgruppenService,
    private userService: UserService) { }

    contacts: Contact[];
    allContacts: Observable<Contact[]>;
    subs: Subscription;
    currentUserDocId = this.userService.getCurrentUserDocId();


    ngOnInit() {
      this.setAllContacts();
      this.filterList(null);
    }


    ngOnDestroy() {
      this.subs?.unsubscribe();
    }


    setAllContacts() {
      if (this.isContactModus) {
        this.allContacts = this.arbeitsgruppenService.getAllContactsThatAllowMeToSeeDetails().pipe(
          map(users => users.filter(x => !x.hidden))
        );
      } else if (this.isChatAddModus) {
        // Alle erlaubten Kontakte für einen Chat minus der bereits ausgewählten Kontakte
        this.allContacts = this.arbeitsgruppenService.getAllContactsThatAllowMeToSeeDetails()
        .pipe(map(c => {
          let users = c.filter(x => x && x.docId !== this.userService.getCurrentUserDocId() && x.hidden !== true);

          if (this.excludedDocIds && this.excludedDocIds.length > 0) {
            users = users.filter(x => !this.excludedDocIds.some(id => id === x.docId));
          }
          return users;
        }));
      }
    }

    async filterList(evt) {

      let searchTerm: string = null;

      if (evt != null && evt.srcElement != null) {
        searchTerm = (evt.srcElement.value as string);
      }

      // Liste Filtern
      if (searchTerm) {
        this.subs = this.allContacts
          .subscribe(c => {
            this.contacts = c.filter(x => (x.docId !== this.currentUserDocId)  &&
                                          (('' + x.vorname + x.name + x.firma).toLowerCase()
                                            .indexOf(searchTerm.toLowerCase()) > -1)).sort((a, b) =>
                                            a.name.localeCompare(b.name));
          });
      }
      else {
        this.subs = this.allContacts
          .subscribe(c => {
            this.contacts = c.filter(x => x.docId !== this.currentUserDocId).sort((a, b) =>
            a.name.localeCompare(b.name));
          }, (e) => {console.log(e); });
        }
    }


    checkboxClicked($event: any, contactDocId: string) {
      if ($event.detail.checked) {
        this.checkboxChecked.emit(contactDocId);
      } else {
        this.checkboxUnchecked.emit(contactDocId);
      }
    }
}
