// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useEmulators: false,
  appVersion: '1.0.11 (1431)',
  firebaseConfig : {
    apiKey: 'AIzaSyC992j9zr7XOOfTqpFDBVLFmT1J2Vps7WE',
    authDomain: 'verbandsapp-dev.firebaseapp.com',
    projectId: 'verbandsapp-dev',
    storageBucket: 'verbandsapp-dev.appspot.com',
    messagingSenderId: '379922986028',
    appId: '1:379922986028:web:2b50f7e6a2f2e2c5b30d89',
    measurementId: 'G-8LDSBH06Z7',
    functionsUrl: 'https://europe-west3-verbandsapp-dev.cloudfunctions.net',
  },
  defaultBrandFeatures: {
    search: {
      enabled: true,
      displayName: "Suche"
    },
    favorites: {
      enabled: true,
      displayName: "Favoriten"},
    news: {
      enabled: true,
      displayName: "News"
    },
    news_non_arbeitsgruppen: {
      enabled: true,
      displayName: null
    },
    chat_fotos: {
      enabled: false,
    },
    news_aktenzeichen: {
      enabled: false,
      displayName: "Aktenzeichen"
    },
    chats: {
      enabled: true,
      displayName: "Chats"
    },
    chats_peerToPeer: {
      enabled: true,
      displayName: null
    },
    chats_arbeitsgruppen: {
      enabled: false,
      displayName: null}
    ,
    chats_redaktion: {
      enabled: true,
      displayName: null
    },
    documents: {
      enabled: false,
      displayName: "Dokumente"
    },
    wiki: {
      enabled: true,
      displayName: "Bibliothek"
    },
    surveys: {
      enabled: true,
      displayName: "Umfragen"
    },
    calendar: {
      enabled: true,
      displayName: "Termine"
    },
    calendarSharing: {
      enabled: false,
      displayName: "Calendar Sharing"
    },
    contacts: {
      enabled: true,
      displayName: "Kontakte"
    },
    profile: {
      enabled: true,
      displayName: "Profile"
    },
    adminWorkingGroups: {
      enabled: true,
      displayName: "Einstellungen - Gruppen"
    },
    adminProfile: {
      enabled: true,
      displayName: "Einstellungen - Benutzer"
    },
    adminUserImport: {
      enabled: true,
      displayName: "Einstellungen - User Import"
    },
    userInvite: {
      enabled: false,
      displayName: "Benutzer Einladungen"
    }
  },
  metaData: {
    appName: 'Verbands App',
    appUrl: 'https://verbandsapp-dev.web.app/',
    eMailAdresseRedaktion: 'app@verbands.app',
    eMailAdresseInfo: 'app@verbands.app',
    urlHomepage: 'https://verbands.app/',
    urlHomepageShort: 'verbands.app',
    emailAbsender: 'Ihre Verbands App',
    impressum: {
      footerColumn1: '',
      footerColumn2: '',
      footerColumn3: '',
    }
  },
    defaultMaxUsers: 50,
    useStaticTenant: false,
    tenantId:'',
    algolia: {
      appId: 'PCUGH0IKTK',
      apiKey: '90df85a6c44546a0e81b0e48999c180b',
      indexName: 'search_staging',
    },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
