import {ProfilFreigabe} from './profilFreigabe';

export enum SettingsPaths {
    UserAuditSettings= "userAuditSettings",
    UserAccountSettings= "userAccountSettings",
    UserSystemWorkflowSettings= "userSystemWorkflowSettings",
    UserNotificationSettings= "userNotificationSettings",
    UserArbeitsgruppenSettings= "userArbeitsgruppenSettings",
};



export interface UserBaseSettings{
    userDocId: string;
}
export interface UserAuditSettings extends UserBaseSettings{
    lastLogin?: number;
    appVersion?: string;
}

export interface UserAccountSettings extends UserBaseSettings{
    favorites: Favorit[];
}

export interface UserSystemWorkflowSettings extends UserBaseSettings{
    needToUpdateProfile?: boolean;
}

export interface UserNotificationSettings extends UserBaseSettings {
    excludedNotificationsFeedIds: string[];
    benachrichtigungenPerEmail: boolean;
    fcmTokens?: string[];
}

export interface UserArbeitsgruppenSettings extends UserBaseSettings {
    arbeitsgruppenExplicitIncluded: number[];
    arbeitsgruppenImplicitExcluded: number[];
}

export interface User {
    docId?: string;
    markedForDeletion: boolean;
    uid: string;
    email: string;
    firma: string;
    anrede: string;
    titel: string;
    vorname: string;
    nachname: string;
    position: string;
    adresse: string;
    telefon: string;
    mobil: string;
    website: string;
    freitext: string;
    profilFreigabe: ProfilFreigabe;
    UserFreigaben: string[];
    roles: string[];
    accountFreigegeben: boolean;
    tenantId: string;
    hidden: boolean;
    invited?: boolean;
    sendInviteMail?: boolean;
    invitedWithLink?: boolean;
    token?: string;
    url?: string;
    tenantSwitchTimestamp?: number;
}

export interface Favorit {
    title: string;
    path: string;
    itemType: string;
}

export function hasFreigabeUser(user: User, uid: string) {
    if (user.UserFreigaben) {
        return user.UserFreigaben.toString().indexOf(uid) > -1;
    }
    return false;
}
