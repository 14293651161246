import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Platform} from '@ionic/angular';
import {combineLatest, combineLatestWith, merge, mergeAll, Observable, Subscription} from 'rxjs';
import {User} from './models/user';
import {UserService} from './services/user.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import {NotificationService} from './services/notification.service';
import {AuthService} from './services/auth-service.service';
import {App, URLOpenListenerEvent} from '@capacitor/app';
import {environment} from '../brand/environments/environment';
import {Storage} from '@ionic/storage-angular';
import 'firebase/compat/storage';
import {NavigationService} from './services/navigation.service';
import {Router} from '@angular/router';
import {TenantService} from './services/tenant.service';
import {register} from 'swiper/element/bundle';
import {Browser} from '@capacitor/browser';
import {AppInfoService} from "./services/app-info.service";
import {Title} from "@angular/platform-browser"
import {QuillEditorService} from "./services/quill-editor.service";
import {Tenant} from "./models/tenant";
import {LoggingService} from "./services/logging.service";
import {AppInitializationService} from './services/app-initialization.service';
import {UtilService} from "./services/util.service";

register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    appVersion: string;

    constructor(
        private platform: Platform,
        private router: Router,
        private zone: NgZone,
        public userService: UserService,
        private notification: NotificationService,
        private angularFireAuth: AngularFireAuth,
        private authService: AuthService,
        private storage: Storage,
        protected navigationService: NavigationService,
        protected tenantService: TenantService,
        public appInfoService: AppInfoService,
        private quillService: QuillEditorService,
        private title: Title,
        private loggingService: LoggingService,
        private appInitializationService: AppInitializationService,
    ) {
    }

    get userLoggedIn(): Observable<boolean> {
        return this.userService.currentUserIsLoggedInAndValid;
    }

    subscriptions = new Subscription();


    async ngOnInit() {
        this.loggingService.log(this, 'App-Initialisierung gestartet');
        
        try {

            await this.initializeApp();

            // Storage nach der Initialisierung erstellen
            await this.storage.create();

            // Alle erforderlichen Dienste in der richtigen Reihenfolge initialisieren
            // und auf Abschluss warten, bevor fortgefahren wird
            await this.appInitializationService.Initialize();

            this.quillService.initializeQuillServ();

            this.appInfoService.getAppInfoString().then(
                appInfo => this.appVersion = appInfo
            );

            this.subscribeToEvents();

            this.subscribeToChanges();

            this.loggingService.log(this, 'App-Initialisierung erfolgreich abgeschlossen');

        } catch (error) {
            this.loggingService.logError(this, 'Fehler während der App-Initialisierung', error);
        }
    }

    async initializeApp() {
        await this.platform.ready();

        firebase.initializeApp(environment.firebaseConfig);
        const storage = firebase.storage();
        this.notification.resetBadgeCount();
    }

    subscribeToEvents(){
        // Event-Listener für App-Ereignisse hinzufügen
        App.addListener('resume', () => {
            this.notification.resetBadgeCount();
        });

        // URL-Handler für Deep-Links
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            console.log('appUrlOpen', event);
            this.zone.run(() => {
                const url = new URL(event.url);
                const path = url.pathname;

                if (path.includes('__/auth/action')) {
                    Browser.open({url: event.url, presentationStyle: 'popover'});
                    return;
                }

                // Angular-Router verwenden, um zur extrahierten URL zu navigieren
                this.router.navigateByUrl(path).catch(err => {
                    console.error('Navigation error:', err);
                });
            });
        });
    }

    private lastIsUserValid: boolean = false;
    private lastTenant: Tenant;
    private lastUser: User;
    subscribeToChanges(){
        combineLatest([this.userService.currentUser$, this.tenantService.tenant$])
            .pipe(combineLatestWith(this.userService.currentUserIsLoggedInAndValid))
            .subscribe(async ([[user, tenant], isUserValid]) => {
                this.loggingService.logMethodStart(this, 'user or tenant changed', {user, tenant});

                if (user == undefined && this.lastUser != undefined) {
                    if (!this.authService.isInitialized) {
                        await this.authService.Init();
                    }
                    if(tenant && tenant.useStaticTenant == true && tenant.metaData?.appName?.length > 0){
                        this.title.setTitle(tenant.clientName);
                    }else if(environment.useStaticTenant == true && environment.metaData?.appName?.length > 0){
                        this.title.setTitle(environment.metaData?.appName);
                    }else {
                        this.title.setTitle("RS-VerbandsApp");
                    }
                    this.navigationService.destroyNavigation();
                    return;
                }

                if(tenant == undefined && this.lastTenant != undefined){
                    this.navigationService.destroyNavigation();
                    await this.navigationService.navigateToLogin();
                    return;
                }

                if (isUserValid != this.lastIsUserValid) {
                    this.lastIsUserValid = isUserValid;
                    if (!isUserValid){
                        this.navigationService.destroyNavigation();
                        await this.navigationService.navigateToLogin();
                        return;
                    }
                }

                if( user && user.uid && tenant &&
                    ((!UtilService.deepEqual(user, this.lastUser) || !UtilService.deepEqual(tenant, this.lastTenant)))
                ){
                    await this.navigationService.initNavigation(user, tenant);
                    const appTitle = tenant.metaData?.appName ? tenant.metaData?.appName : "RS-VerbandsApp";
                    if (this.title.getTitle() != appTitle) {
                        this.title.setTitle(appTitle);
                    }
                }
            });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }


}
