// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  float: right;
}

.nodisplay {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/test-input-control/test-input/color-input.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".dot {\n  height: 25px;\n  width: 25px;\n  border-radius: 50%;\n  display: inline-block;\n  float: right;\n}\n\n.nodisplay {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
